import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// ----------------------------------------------------------------------

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
  const theme = useTheme();
  const PRIMARY_LIGHT = theme.palette.primary.light;
  const PRIMARY_MAIN = theme.palette.primary.main;
  const PRIMARY_DARK = theme.palette.primary.dark;

  const logo = (
    <Box sx={{ width: 40, height: 40, ...sx }}>
      <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 500 500">
        <defs>
          <linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
            <stop offset="0%" stopColor={PRIMARY_DARK} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
        </defs>
        <g
          fill={PRIMARY_MAIN} stroke="none" strokeWidth="1" fillRule="evenodd" transform="">
  

  <path fill="#FFFFFF" opacity="1.000000" stroke="none" 
	d="
M284.000000,501.000000 
	C189.333359,501.000000 95.166710,501.000000 1.000051,501.000000 
	C1.000034,334.333405 1.000034,167.666794 1.000017,1.000140 
	C167.666565,1.000093 334.333130,1.000093 500.999756,1.000047 
	C500.999847,167.666519 500.999847,334.333038 500.999939,500.999786 
	C428.833344,501.000000 356.666656,501.000000 284.000000,501.000000 
M302.002594,292.997131 
	C302.002594,292.997131 301.954681,292.978149 302.444183,292.782837 
	C302.603210,292.481079 302.762238,292.179321 302.943817,291.986237 
	C302.943817,291.986237 302.871094,291.902374 303.586761,291.923462 
	C304.755402,291.053436 306.186920,290.389252 307.053558,289.282684 
	C321.734467,270.537445 326.885345,249.257462 323.716095,225.813797 
	C323.677155,225.525650 323.355560,225.275742 323.028412,224.265228 
	C323.069061,223.513199 323.109741,222.761185 323.557983,221.680969 
	C323.659210,221.187210 323.760437,220.693466 323.874847,220.063889 
	C323.874847,220.063889 323.911896,219.932541 324.855896,220.060440 
	C331.863770,219.866760 338.871643,219.673080 346.801300,219.741226 
	C351.485535,219.693222 356.169769,219.645203 361.778961,219.844238 
	C366.753052,219.864746 371.730377,219.984818 376.698456,219.821777 
	C377.692047,219.789139 378.650208,218.676910 379.624725,218.062500 
	C378.686798,217.384125 377.760925,216.140579 376.809021,216.120331 
	C371.181244,216.000565 365.547363,216.164886 359.001465,216.132034 
	C354.312744,216.171082 349.624054,216.210129 344.017181,216.102798 
	C337.107727,216.064606 330.198303,216.026413 323.043243,215.246384 
	C323.096588,213.675110 323.149902,212.103821 324.064819,210.559113 
	C325.806366,210.513992 327.547913,210.468857 330.085938,210.768967 
	C337.804382,210.594208 345.522827,210.419449 354.023926,210.313705 
	C355.075073,210.283737 356.126221,210.253769 357.895020,210.563965 
	C361.592896,210.649017 365.290771,210.734070 369.914032,210.919403 
	C377.235718,209.518143 385.080780,213.461884 392.165375,208.715286 
	C384.614716,205.523911 376.805695,206.641357 368.176422,206.571671 
	C353.120575,206.450790 338.064728,206.329910 322.939117,205.260101 
	C322.585968,202.074463 323.911102,201.009216 327.174469,201.056976 
	C338.727875,201.226074 350.285583,201.101105 362.719574,201.025482 
	C364.423340,198.420135 364.301666,197.763367 361.683868,197.671616 
	C358.411560,197.556915 355.124420,197.866043 351.143799,197.730621 
	C341.825928,197.664642 332.508057,197.598663 323.090485,197.231873 
	C323.090485,197.231873 322.837463,197.041077 322.900513,196.489243 
	C322.861694,196.308212 322.822876,196.127182 322.956604,195.321442 
	C322.908752,194.895081 322.860901,194.468719 322.995209,193.526672 
	C323.165161,193.017441 323.335114,192.508224 324.504639,192.041046 
	C326.181274,192.047653 327.857941,192.054260 330.193176,192.323074 
	C345.395355,192.261810 360.597534,192.200546 376.557343,192.424744 
	C384.362244,192.711288 392.165955,193.079361 399.973389,193.193695 
	C401.103607,193.210251 402.251617,192.009506 403.391418,191.372070 
	C402.601013,190.129868 401.819489,187.822250 401.018738,187.815582 
	C395.100769,187.766098 389.178955,188.175064 382.307648,188.202087 
	C377.875092,188.262436 373.442535,188.322800 368.090515,188.188965 
	C363.051971,188.266586 358.013458,188.344223 352.087585,188.263412 
	C349.696289,188.358383 347.305023,188.453354 344.090942,188.331375 
	C337.148804,188.259048 330.206665,188.186722 323.052917,187.379425 
	C323.175903,185.874283 323.298889,184.369156 324.449280,182.887360 
	C332.343933,182.791290 340.238586,182.695221 348.879639,182.820618 
	C352.888672,182.812607 356.897705,182.804596 361.796173,182.978622 
	C364.200287,182.956131 366.604431,182.933640 369.938141,183.028320 
	C375.059784,183.045532 380.185822,183.179840 385.299591,182.987534 
	C386.408936,182.945831 387.474213,181.732178 388.559814,181.058334 
	C387.491730,180.367828 386.438171,179.110245 385.353119,179.082428 
	C379.909821,178.942886 374.458252,179.125107 368.117523,179.059006 
	C365.705292,179.134827 363.293091,179.210648 359.974304,179.180634 
	C355.943878,179.257095 351.913483,179.333542 346.975922,179.245056 
	C339.124725,179.211914 331.273560,179.178757 323.172638,178.450592 
	C323.155487,176.973480 323.138367,175.496368 323.751923,173.987823 
	C324.135498,173.835007 324.519073,173.682190 325.837280,173.626816 
	C330.213013,173.502869 334.588745,173.378922 339.839355,173.604630 
	C346.495972,173.543945 353.152618,173.483261 360.714447,173.763123 
	C363.667206,173.803696 366.636780,174.038467 369.564056,173.784836 
	C370.663452,173.689575 371.664551,172.460037 372.710449,171.747604 
	C371.733063,171.141144 370.803619,170.139160 369.767975,170.013733 
	C367.840881,169.780304 365.851959,170.057465 362.967010,170.066681 
	C354.318024,170.087280 345.669067,170.107864 336.097015,169.993622 
	C331.808502,169.990768 327.519989,169.987915 323.036316,169.212479 
	C322.943207,168.163803 322.850067,167.115112 322.712769,165.347321 
	C322.607544,165.131424 322.502319,164.915543 323.437805,164.643997 
	C331.510498,164.627350 339.584778,164.683502 347.653931,164.505905 
	C348.714355,164.482574 349.748474,163.263275 350.794769,162.597885 
	C349.830841,162.018951 348.885223,160.982468 347.899658,160.943008 
	C344.288818,160.798370 340.664673,160.985382 336.499390,160.861435 
	C336.320404,160.791122 336.141388,160.720795 335.268372,160.233032 
	C332.455902,160.155228 329.609711,160.301071 326.848297,159.884628 
	C325.808716,159.727875 324.230896,158.323441 324.160675,157.392548 
	C323.861969,153.434006 323.929718,149.434540 324.124939,145.462845 
	C324.165833,144.630676 325.252960,143.221008 325.959869,143.154099 
	C330.084320,142.763641 328.963837,140.152145 328.668823,137.598358 
	C327.212891,138.043228 325.951599,138.428619 325.087402,138.692688 
	C324.265320,136.256470 323.558472,134.161774 322.440338,131.846664 
	C322.174408,131.652313 322.015320,131.392548 321.997345,130.315216 
	C321.001434,129.551529 320.005524,128.787827 318.727417,127.439499 
	C317.814911,126.959969 316.902374,126.480446 315.978607,125.258919 
	C314.549988,122.740990 313.121368,120.223061 311.692749,117.705132 
	C311.000580,117.862061 310.308441,118.018982 309.616272,118.175911 
	C309.080902,120.617889 308.085663,123.058632 308.079102,125.502029 
	C307.980133,162.406067 307.979309,199.310532 308.061462,236.214645 
	C308.069519,239.828735 308.775513,243.441284 309.234314,247.703613 
	C309.132812,248.123978 309.031342,248.544327 308.352539,249.020416 
	C307.907776,249.347672 307.463013,249.674927 306.346741,250.000778 
	C305.899048,250.000320 305.451355,249.999863 305.008179,249.289734 
	C304.967377,247.849182 304.926544,246.408615 304.883423,244.312485 
	C304.951050,243.881317 305.018707,243.450150 305.644196,242.398117 
	C305.744507,225.823395 305.854034,209.248718 305.905518,192.673843 
	C305.907257,192.113358 305.324402,191.551071 304.859131,190.064056 
	C304.717285,180.290863 304.575409,170.517685 304.631744,159.866409 
	C304.739502,144.587646 304.847260,129.308884 305.470276,113.649948 
	C303.944519,112.175438 302.418732,110.700920 300.892944,109.226418 
	C300.552795,110.886787 299.983246,112.541077 299.939026,114.209297 
	C299.897308,115.782669 300.408600,117.370697 300.780640,119.875557 
	C300.743500,123.599136 300.706329,127.322716 300.268250,131.563339 
	C300.399048,133.026062 300.529846,134.488770 300.765411,136.699081 
	C300.729919,137.450836 300.694427,138.202591 300.255066,139.592667 
	C300.038116,141.581039 299.821167,143.569412 299.590820,145.680786 
	C297.975220,145.402847 296.744019,145.191025 295.377106,144.056503 
	C295.483307,139.072235 295.589478,134.087967 296.410706,128.967255 
	C296.644714,123.741936 296.888062,118.516914 297.052917,113.289413 
	C297.056610,113.172180 296.167999,113.026810 295.414764,112.037727 
	C295.302643,109.589630 295.190521,107.141525 294.966461,104.422981 
	C294.869232,104.250069 294.734650,104.114120 294.207855,103.570152 
	C294.132812,103.395912 294.057739,103.221664 293.702911,102.360321 
	C289.006958,98.932602 290.057648,103.207169 290.054871,105.271072 
	C289.996552,149.170166 290.005219,193.069351 290.067535,236.968445 
	C290.069458,238.336441 290.781616,239.703415 291.227692,241.857697 
	C291.125641,242.902435 291.023590,243.947174 290.391937,245.524902 
	C290.313629,246.778519 290.235321,248.032150 290.171204,249.659576 
	C290.171204,249.659576 290.025879,250.004272 289.420319,250.276535 
	C288.776031,250.661682 288.131744,251.046814 286.717010,251.892532 
	C287.195312,247.119095 287.919922,243.216339 287.926727,239.312317 
	C288.007904,192.707260 287.992615,146.102005 287.945587,99.496857 
	C287.943787,97.722534 287.679321,95.804024 286.951263,94.222466 
	C286.408600,93.043648 284.893066,91.548485 283.857727,91.583473 
	C282.893311,91.616074 281.521790,93.346039 281.161682,94.573021 
	C280.471924,96.923225 280.114502,99.442566 280.075439,101.898972 
	C279.948517,109.886421 280.045441,117.877213 280.014648,125.866554 
	C280.007324,127.764404 280.621674,130.183075 277.202698,129.019257 
	C277.252380,117.703743 277.302063,106.388237 277.957642,94.870384 
	C277.758453,93.555588 277.559296,92.240784 277.205750,90.136925 
	C277.112762,88.798729 277.019775,87.460541 276.999390,85.348320 
	C276.653046,84.329094 276.306671,83.309868 275.960327,82.290634 
	C275.310608,82.811691 274.660889,83.332748 273.418884,84.475334 
	C273.279877,86.604218 273.036743,88.732239 273.019043,90.862137 
	C272.913269,103.573807 272.859528,116.285919 272.087646,128.998398 
	C271.384674,128.999542 270.681671,129.000687 269.973633,128.064301 
	C269.974060,112.578674 270.035492,97.092560 269.911407,81.607933 
	C269.891998,79.184624 269.506409,76.526642 268.435852,74.409019 
	C267.073059,71.713303 265.022827,71.060417 263.133636,75.171120 
	C262.762970,77.024490 262.075043,78.876923 262.069611,80.731361 
	C261.997009,105.488655 262.063141,130.246460 261.932861,155.003265 
	C261.922913,156.888763 260.689240,158.767822 259.371582,160.748718 
	C258.935028,160.707993 258.498474,160.667252 257.827332,159.960541 
	C250.883499,160.266022 243.939651,160.571503 236.362778,161.050735 
	C235.927032,161.065613 235.491302,161.080475 234.591721,160.526306 
	C233.062119,161.013245 231.532532,161.500183 229.547867,162.218719 
	C229.213852,162.244705 228.879852,162.270706 227.628601,162.124786 
	C223.475052,161.701050 219.748489,162.473755 216.649490,166.130997 
	C216.649490,166.130997 216.262207,166.141953 215.589813,166.059402 
	C215.084106,166.425217 214.578400,166.791031 214.024109,167.042923 
	C214.024109,167.042923 214.117874,167.123840 213.356720,167.023087 
	C212.228027,167.669449 211.099335,168.315811 209.682434,169.147751 
	C209.682434,169.147751 209.340195,169.167297 208.565308,169.071869 
	C207.747253,169.761703 206.929199,170.451538 205.994339,171.097702 
	C205.994339,171.097702 206.113480,171.120621 205.357803,171.027313 
	C203.948212,172.072433 202.538620,173.117569 201.025818,174.098190 
	C201.025818,174.098190 201.147263,174.106323 200.607544,174.226959 
	C200.401917,174.521500 200.196289,174.816025 199.700668,175.460983 
	C199.480240,175.673187 199.232544,175.846436 198.539856,176.104034 
	C198.271729,176.249023 198.085083,176.464569 197.670380,177.010834 
	C197.670380,177.010834 197.279663,177.114853 196.710983,177.217102 
	C196.501663,177.523834 196.292358,177.830551 196.045929,178.043396 
	C196.045929,178.043396 196.136353,178.088287 195.614288,178.231964 
	C195.439346,178.533981 195.264404,178.835999 195.042007,179.042267 
	C195.042007,179.042267 195.137405,179.090363 194.618942,179.223862 
	C194.431839,179.514435 194.244736,179.805008 194.015366,180.014435 
	C194.015366,180.014435 194.094681,180.060074 193.591293,180.201950 
	C193.404037,180.484528 193.216782,180.767105 193.000610,181.000610 
	C193.000610,181.000610 193.049576,181.029709 192.562012,181.200806 
	C192.385971,181.486404 192.209930,181.772003 192.004395,181.999557 
	C192.004395,181.999557 192.058441,182.035904 191.564484,182.176743 
	C191.378799,182.453323 191.193130,182.729904 191.000000,183.000000 
	C191.000000,183.000000 191.006546,183.007431 190.525558,183.189865 
	C190.363174,183.480988 190.200790,183.772095 190.000336,184.005951 
	C190.000336,184.005951 190.062531,184.035263 189.568619,184.210770 
	C189.396896,184.504120 189.225174,184.797485 189.013031,185.015427 
	C189.013031,185.015427 189.090729,185.051239 188.591248,185.222839 
	C188.414230,185.515976 188.237213,185.809128 188.022018,186.018433 
	C188.022018,186.018433 188.102737,186.062851 187.594604,186.206940 
	C187.410889,186.495087 187.227188,186.783234 187.005310,187.004715 
	C187.005310,187.004715 187.071457,187.043793 186.573608,187.206711 
	C186.399536,187.497604 186.225464,187.788483 186.006897,188.007599 
	C186.006897,188.007599 186.079834,188.050186 185.581451,188.214539 
	C185.407028,188.506195 185.232620,188.797852 185.034103,188.996475 
	C185.034103,188.996475 185.091705,189.073456 184.358032,189.082581 
	C183.588593,190.409409 182.819138,191.736237 182.007828,192.997086 
	C182.007828,192.997086 182.068481,193.046692 181.342728,193.073029 
	C180.266083,195.087402 179.189438,197.101776 178.051865,198.985214 
	C178.051865,198.985214 178.136719,199.102600 177.428040,199.125824 
	C172.669479,203.009857 170.822861,208.077286 171.252914,214.754623 
	C171.110336,215.166382 170.967773,215.578156 170.409348,216.305389 
	C170.326569,216.634186 170.243774,216.962997 170.181488,217.871674 
	C170.096298,218.246902 170.011108,218.622147 169.415283,219.416153 
	C169.333664,220.054642 169.252029,220.693130 169.154266,222.005142 
	C169.033295,222.666977 168.912323,223.328812 168.283127,224.306900 
	C168.234940,225.224380 168.186752,226.141876 168.193375,227.715179 
	C168.101929,228.142502 168.010468,228.569839 167.339096,229.496063 
	C166.991898,235.658859 166.619995,241.820618 166.366592,247.987274 
	C166.353149,248.313995 167.341721,248.681870 168.087280,249.646423 
	C168.114349,250.080597 168.141403,250.514771 167.603043,251.312042 
	C168.040741,252.880539 168.478439,254.449020 169.076294,256.389069 
	C169.076294,256.389069 169.097122,256.793121 169.015579,257.571869 
	C169.405746,258.690491 169.795914,259.809143 170.078400,260.963684 
	C170.078400,260.963684 170.141815,260.869537 170.022736,261.715271 
	C171.020645,265.692505 172.376358,269.621979 172.915588,273.660431 
	C173.452316,277.680176 175.365540,279.946838 179.098907,281.013367 
	C179.098907,281.013367 179.126160,280.875275 179.038483,281.615326 
	C179.734238,282.391571 180.430008,283.167847 181.660767,284.300171 
	C182.769653,285.872864 183.878555,287.445526 184.986389,289.761749 
	C186.296890,290.858063 187.607407,291.954346 188.991562,293.009491 
	C188.991562,293.009491 188.949860,293.082855 188.972885,293.811005 
	C190.255493,294.902618 191.538116,295.994232 193.372894,297.316101 
	C193.907593,297.891602 194.442307,298.467072 194.977371,299.746338 
	C195.532166,301.144867 195.077103,304.039337 198.641891,301.942291 
	C199.096863,302.290253 199.551819,302.638184 200.212845,303.460876 
	C200.506027,303.626343 200.799225,303.791809 201.607956,304.163086 
	C202.053650,304.491791 202.499344,304.820526 202.982727,305.878845 
	C206.099091,311.725769 212.167953,311.349823 217.948456,312.744385 
	C218.287750,312.864502 218.627045,312.984589 219.113098,313.807587 
	C223.398376,316.406952 227.800110,318.492859 233.757156,316.951385 
	C234.486755,317.029938 235.216354,317.108490 236.076752,317.887268 
	C237.358582,318.861511 238.729370,320.767883 239.907486,320.655457 
	C246.463730,320.029724 253.063354,319.310486 259.472717,317.852142 
	C265.567932,316.465302 271.466217,314.185150 277.396667,312.139801 
	C278.081696,311.903534 278.418091,310.656586 279.012634,309.952026 
	C279.012634,309.952026 278.900330,309.906158 279.702240,309.998596 
	C283.155304,309.482758 286.948334,309.537231 288.303711,304.847931 
	C288.303711,304.847931 288.639008,304.826569 289.402374,304.883453 
	C291.226440,303.165375 293.050507,301.447327 295.039703,299.333069 
	C295.212799,299.080017 295.451660,298.935760 296.317566,298.802948 
	C296.526367,298.502106 296.735168,298.201233 296.983826,297.978851 
	C296.983826,297.978851 296.902588,297.944916 297.402130,297.778076 
	C297.583099,297.488312 297.764038,297.198547 297.986755,296.987183 
	C297.986755,296.987183 297.908478,296.945190 298.408203,296.772858 
	C298.583893,296.478577 298.759583,296.184296 298.973663,295.978973 
	C298.973663,295.978973 298.890045,295.930023 299.404846,295.803497 
	C299.593597,295.517761 299.782349,295.232025 300.001068,294.996735 
	C300.001068,294.996735 299.947571,294.972687 300.434570,294.794250 
	C300.608612,294.506042 300.782654,294.217865 300.993347,293.998169 
	C300.993347,293.998169 300.929474,293.953918 301.433960,293.813202 
	C301.614532,293.526489 301.795105,293.239777 302.002594,292.997131 
M322.016327,376.942291 
	C322.016327,376.942291 322.087311,376.878357 322.116394,377.591919 
	C322.853729,377.927917 323.591095,378.263947 324.374481,378.648804 
	C324.374481,378.648804 324.436218,378.622528 324.081085,379.395691 
	C322.566742,380.288239 321.052399,381.180817 319.538055,382.073395 
	C323.726166,382.661224 327.783325,381.793579 331.738739,382.128265 
	C336.901489,382.565186 341.033295,380.817017 345.396088,378.546417 
	C348.275299,381.167725 351.160004,383.501007 355.428680,383.764740 
	C358.428314,383.950073 361.335785,385.315430 364.339630,385.833710 
	C367.270508,386.339417 370.209717,386.348480 370.584412,381.701904 
	C371.718231,381.118439 372.852051,380.535004 373.994934,380.722412 
	C374.808472,382.514679 375.622040,384.306915 376.647064,386.565033 
	C379.189514,383.706451 381.517609,382.116547 382.307953,379.962463 
	C383.532623,376.624451 384.510223,372.808411 384.110138,369.367432 
	C383.684814,365.709808 380.949036,362.281433 384.431519,358.722015 
	C384.843384,358.301056 384.824615,357.086945 384.549042,356.432800 
	C383.609344,354.202271 382.491638,352.044464 381.394562,349.882935 
	C378.590240,344.357513 373.591675,342.738831 368.339966,345.593872 
	C368.156555,345.693573 367.762726,345.406219 366.785614,345.052887 
	C367.780579,342.594635 368.762878,340.167694 370.015869,337.071991 
	C364.056702,337.313843 361.492523,341.554688 358.641632,344.533142 
	C356.188507,343.854614 354.090485,343.274292 351.992462,342.693970 
	C352.312103,344.971802 352.632111,347.249573 352.950592,349.527588 
	C352.973572,349.691956 352.985443,349.857880 352.524841,350.025848 
	C352.524841,350.025848 352.047211,350.026520 351.474518,349.618591 
	C348.318695,348.080200 345.179169,346.507050 342.000793,345.016724 
	C340.164032,344.155457 338.264587,343.427795 336.393372,342.639984 
	C338.296997,342.379700 340.206940,342.157990 342.102264,341.847137 
	C343.655151,341.592377 345.190613,341.231415 346.733765,340.917572 
	C346.714935,340.253906 346.696106,339.590240 346.677307,338.926575 
	C344.066162,338.638855 340.922546,337.280609 338.944031,338.264679 
	C333.071808,341.185364 328.314148,338.898773 323.104431,336.758209 
	C321.021912,335.902618 318.232483,336.261963 315.881561,336.697723 
	C313.163361,337.201538 310.573822,338.399200 307.506104,339.438690 
	C307.562408,340.370453 307.647522,341.778992 307.710846,342.826813 
	C306.045227,344.266937 304.370605,345.374268 303.229218,346.884552 
	C302.823975,347.420776 303.715668,348.937164 303.938446,350.702606 
	C302.441742,352.487610 300.945007,354.272644 299.448303,356.057648 
	C298.943512,355.856689 298.438721,355.655762 297.933929,355.454803 
	C298.134766,351.608032 298.650635,347.741241 298.408112,343.922638 
	C298.280334,341.910858 296.828491,339.983185 295.999237,337.999176 
	C295.999237,337.999176 295.983826,338.024170 295.860565,337.273499 
	C293.567322,335.375977 291.492615,334.747864 290.087646,338.646759 
	C290.087646,338.646759 289.923065,338.999542 289.165985,339.171539 
	C288.775696,341.780792 288.385406,344.390076 287.257812,346.920685 
	C285.493927,346.263641 283.730011,345.606567 281.490997,344.772552 
	C281.284912,346.544861 281.142456,347.769989 280.320587,349.007416 
	C279.537415,349.596252 278.754211,350.185089 277.400879,351.194946 
	C277.015533,351.785126 276.630188,352.375305 275.648071,353.329712 
	C275.816956,356.572723 275.985870,359.815704 276.275757,363.902802 
	C276.167969,365.597412 276.060150,367.292023 275.152832,368.989319 
	C271.158997,368.292328 270.534119,371.090393 269.270782,374.131683 
	C268.171143,373.774017 267.071503,373.416382 266.002960,372.233063 
	C263.753693,365.834717 263.297211,359.315094 264.493195,352.635620 
	C264.561523,352.254028 263.328308,351.639435 262.329224,351.099854 
	C262.329224,351.099854 262.012756,350.902130 261.846497,350.176727 
	C259.002686,350.403442 255.880249,351.466034 253.388824,350.635559 
	C250.565933,349.694580 248.308640,347.056824 245.953857,345.037903 
	C245.953857,345.037903 245.843475,345.192749 245.897018,344.450043 
	C244.247025,343.004028 242.765060,340.604553 240.917603,340.278961 
	C233.369400,338.948669 225.715775,338.186615 218.077988,337.455719 
	C217.513351,337.401672 216.766571,339.251373 216.002853,340.639008 
	C216.002853,340.639008 215.735962,340.982513 214.993484,341.213776 
	C214.663147,345.244171 214.464661,349.291870 213.939850,353.296783 
	C213.719284,354.979980 212.808624,356.572754 212.211700,358.206635 
	C211.728775,358.141968 211.245850,358.077332 210.762939,358.012695 
	C207.296768,346.891113 196.163940,349.545044 190.510376,345.634399 
	C187.539734,348.348450 184.788193,349.904633 183.484772,352.261658 
	C181.108994,356.557892 178.448196,355.984131 174.959503,354.038239 
	C174.959503,354.038239 174.748734,354.107056 174.924301,353.468201 
	C174.922577,353.027557 174.920853,352.586914 174.985428,351.353943 
	C173.678329,349.214783 172.371231,347.075623 170.947098,344.242584 
	C168.408936,340.919464 165.828964,337.698914 160.287262,340.139435 
	C159.864777,340.060089 159.442291,339.980743 158.814682,339.128418 
	C152.804077,338.904724 146.793854,338.664551 140.781235,338.531616 
	C140.588348,338.527344 140.371170,339.622101 140.082428,340.058228 
	C140.082428,340.058228 140.211609,340.165833 139.383682,340.194031 
	C137.483231,343.900940 136.546143,347.561890 140.039291,351.753052 
	C140.002228,352.497437 139.965164,353.241852 139.150375,354.185425 
	C138.793930,354.701843 138.134079,355.214172 138.127045,355.735291 
	C138.037476,362.373535 137.974655,369.014404 138.109650,375.650421 
	C138.125870,376.447235 139.344696,377.219574 140.165802,378.291656 
	C140.165802,378.291656 140.172409,378.621246 140.161407,379.428284 
	C140.555862,379.894714 140.989273,380.794037 141.338943,380.762573 
	C146.559525,380.292816 151.769028,379.700165 157.664536,378.941864 
	C158.124817,378.834412 158.585098,378.726990 159.650925,378.859406 
	C160.085190,378.867950 160.519470,378.876465 161.396484,379.461884 
	C169.683533,380.146973 172.691071,373.363220 176.458527,368.513916 
	C178.573578,370.456421 180.432861,372.164032 182.686890,374.088348 
	C182.922119,374.305756 183.042130,374.574646 183.008347,375.686066 
	C183.113708,379.225677 185.610336,379.069397 188.699249,379.022278 
	C189.460236,379.365112 190.221222,379.707947 191.155685,380.802795 
	C193.950256,382.373322 196.703461,383.515320 199.536926,379.868500 
	C199.902664,379.834991 200.268417,379.801483 201.413895,379.894592 
	C206.534973,379.083801 209.730621,376.602478 210.140854,370.965393 
	C210.283188,369.009521 211.736710,367.149078 212.591354,365.245087 
	C213.119919,365.417725 213.648483,365.590363 214.177063,365.763000 
	C213.856995,368.785278 213.580475,371.813019 213.202850,374.828064 
	C212.910400,377.163239 212.677597,379.346741 216.031937,378.970795 
	C216.031937,378.970795 216.125671,378.832214 216.177933,379.648010 
	C221.534409,382.838776 226.714447,379.725647 232.708405,379.035736 
	C233.465408,379.013763 234.222412,378.991821 235.441467,379.534363 
	C242.689789,379.916260 249.115021,375.325104 251.667755,367.120941 
	C252.537384,364.326080 253.760727,363.751495 256.253418,364.840454 
	C256.247498,366.200439 256.241577,367.560394 255.669098,369.250153 
	C254.096313,372.023407 252.523514,374.796661 251.361145,376.846252 
	C253.486038,376.846252 256.335144,376.846252 259.041412,376.956207 
	C259.041412,376.956207 259.150299,376.812531 259.105804,377.567261 
	C260.996674,382.550659 265.302185,381.920685 269.335754,382.018585 
	C271.555695,382.072449 273.309692,381.769440 273.012268,378.961395 
	C273.012268,378.961395 272.918945,378.926422 273.731354,378.988770 
	C274.814178,378.994415 275.897034,379.000061 277.001129,378.997986 
	C277.001129,378.997986 276.994629,379.019379 276.994476,379.715637 
	C277.946869,383.412903 280.915894,380.584900 282.731628,381.488678 
	C282.751831,380.623810 282.772034,379.758972 282.868683,378.253387 
	C283.006531,377.846466 283.144379,377.439514 283.962189,376.830048 
	C283.988861,373.887634 284.015533,370.945190 284.724854,368.068115 
	C285.813995,368.378784 286.903168,368.689423 287.997437,369.900909 
	C287.051483,374.852478 286.105560,379.804047 285.171509,384.693542 
	C289.851715,380.897552 289.851715,380.897552 296.614655,381.301025 
	C297.722931,382.300018 298.718231,383.477661 299.961151,384.263824 
	C303.422821,386.453491 307.083313,384.900055 308.802307,380.989777 
	C310.422089,382.136658 312.041901,383.283539 314.038940,384.697510 
	C316.359558,382.479218 316.973419,379.475006 316.827423,375.883240 
	C317.869934,375.450775 318.912445,375.018311 320.269958,374.829742 
	C320.511169,375.155029 320.752380,375.480316 321.180725,376.343323 
	C321.491028,376.540863 321.801331,376.738403 322.016327,376.942291 
M274.687958,396.356049 
	C273.468323,395.227020 272.248688,394.097992 269.982452,392.000092 
	C270.162109,395.246613 270.282867,397.428619 270.403656,399.610596 
	C270.595276,399.188324 270.786926,398.766083 270.978577,398.343811 
	C272.747375,399.228943 274.516174,400.114044 276.562042,401.137817 
	C276.562042,397.754791 276.562042,394.818756 276.562042,391.882721 
	C276.149323,391.859772 275.736603,391.836792 275.323914,391.813812 
	C275.192657,393.108978 275.061432,394.404175 274.687958,396.356049 
M332.031311,395.733002 
	C331.835785,395.026062 331.640289,394.319153 331.539856,393.955994 
	C329.805359,394.821350 328.015442,395.714417 326.338043,396.551300 
	C328.229034,397.609833 330.125549,398.671448 332.649078,400.084076 
	C332.340820,398.239288 332.186340,397.314880 332.031311,395.733002 
M287.822845,398.248444 
	C286.184052,398.621674 284.545288,398.994904 282.906494,399.368164 
	C283.068848,399.826660 283.231232,400.285156 283.393585,400.743652 
	C285.039398,400.027618 286.685181,399.311584 287.822845,398.248444 
M268.182922,348.496735 
	C268.865784,348.864746 269.548645,349.232788 270.231476,349.600830 
	C270.380615,349.038330 270.529785,348.475830 270.678925,347.913330 
	C269.898773,347.883667 269.118591,347.854004 268.182922,348.496735 
M288.226013,395.188568 
	C288.894287,395.639709 289.562561,396.090851 290.230804,396.541992 
	C290.454041,396.251343 290.677277,395.960663 290.900513,395.669983 
	C289.777313,394.701965 288.654114,393.733917 287.530884,392.765900 
	C287.343567,392.922028 287.156219,393.078156 286.968903,393.234283 
	C287.215698,393.766266 287.462494,394.298279 288.226013,395.188568 
M261.910980,344.637421 
	C261.339142,344.885529 260.767303,345.133636 260.195465,345.381744 
	C260.672760,345.857330 261.150055,346.332947 261.627350,346.808533 
	C261.876190,346.238098 262.125031,345.667664 261.910980,344.637421 
M264.688110,339.518890 
	C265.024139,338.975800 265.360168,338.432709 265.696198,337.889587 
	C265.424286,337.755493 265.152344,337.621399 264.880432,337.487305 
	C264.677094,338.019165 264.473724,338.551056 264.688110,339.518890 
M255.282196,381.885803 
	C255.472610,382.425720 255.663040,382.965607 255.853455,383.505524 
	C256.129852,383.378937 256.406250,383.252380 256.682648,383.125824 
	C256.359497,382.569977 256.036346,382.014160 255.282196,381.885803 
z"/>
<path fill="#FEF9E0" opacity="1.000000" stroke="none" 
	d="
M295.512848,144.979218 
	C296.744019,145.191025 297.975220,145.402847 299.590820,145.680786 
	C299.821167,143.569412 300.038116,141.581039 300.546722,139.710770 
	C300.920044,141.905945 301.001709,143.983017 301.044312,146.522064 
	C301.045898,170.331009 301.086517,193.677994 301.066162,217.488495 
	C300.988617,225.093903 301.408417,232.271484 300.827545,239.367142 
	C300.292603,245.902237 299.822266,252.826233 297.343781,258.750702 
	C287.738617,281.710388 270.664856,294.746155 244.682922,295.229126 
	C236.792801,293.258148 229.136856,291.955780 221.975388,289.313354 
	C216.997025,287.476379 212.698227,283.797699 207.970413,280.624695 
	C204.690475,276.837738 201.535950,273.367920 198.266083,269.544434 
	C196.630020,265.587036 194.926071,262.048492 193.617432,258.369354 
	C182.445358,226.959671 199.754425,192.622040 233.444717,184.454575 
	C243.154144,182.100739 253.749130,183.399750 263.984863,183.432373 
	C263.996887,185.206604 263.962189,186.572449 263.465759,187.990356 
	C257.683014,188.081299 252.362000,188.116898 247.041046,188.159714 
	C230.428085,188.293365 216.793350,194.520905 206.210861,207.542435 
	C194.108414,222.434280 191.592209,246.281860 200.871552,261.616577 
	C203.491470,265.694427 206.097565,269.416443 208.810745,273.466370 
	C218.542542,283.814362 230.119247,289.498444 244.608826,289.777863 
	C247.681366,289.780853 250.392090,290.019440 252.955154,289.512146 
	C274.177551,285.311249 287.451935,272.330750 294.217163,251.997803 
	C294.652130,251.999252 294.826080,251.999344 295.319489,252.135712 
	C296.449921,251.692932 297.859222,251.214081 297.975677,250.518723 
	C298.791656,245.645950 299.786224,240.735794 299.866974,235.823532 
	C299.978241,229.053558 299.147614,222.273682 299.056763,215.493561 
	C298.904083,204.103119 299.016815,192.709137 299.016876,181.316635 
	C299.016907,175.663849 298.908600,170.008224 299.059418,164.359497 
	C299.131592,161.656097 297.999512,160.744110 295.372955,160.594086 
	C295.141663,157.783859 295.045776,155.400421 295.038330,152.575714 
	C295.255463,149.749374 295.384155,147.364304 295.512848,144.979218 
z"/>
<path fill="#FDFFFD" opacity="1.000000" stroke="none" 
	d="
M315.989868,126.000916 
	C316.902374,126.480446 317.814911,126.959969 318.859711,128.070663 
	C319.003632,129.154037 319.015259,129.606247 319.044403,130.523193 
	C318.991760,141.325211 318.921600,151.662460 318.702576,162.093246 
	C318.653076,162.457993 318.752502,162.729218 318.916992,163.426697 
	C319.078674,165.570465 319.175262,167.287979 319.152557,169.251022 
	C318.971497,169.667709 318.909729,169.838898 318.608795,170.255234 
	C318.529938,171.996918 318.690247,173.493469 318.913147,175.455475 
	C319.082825,182.957718 319.189911,189.994537 319.202026,197.020996 
	C319.107025,197.010635 318.918243,196.981125 318.673767,197.202332 
	C318.590942,198.616135 318.752563,199.808762 318.986511,201.446686 
	C319.010742,204.594757 318.962677,207.297516 318.635590,210.307785 
	C318.237732,212.423203 318.072357,214.229431 318.007812,216.039261 
	C317.665894,225.622513 317.293976,235.205276 317.053528,244.791245 
	C316.967377,248.226379 317.219818,251.669998 317.258789,255.435944 
	C317.050629,256.178070 316.901428,256.593964 316.569153,257.147491 
	C316.414032,257.829529 316.442017,258.373993 316.513306,259.192322 
	C316.571381,259.650513 316.586151,259.834778 316.445038,260.137817 
	C316.111938,260.461334 316.010468,260.698547 315.990967,260.986511 
	C315.997131,261.004852 315.962982,260.986389 315.715851,261.148865 
	C315.364258,261.682922 315.259766,262.054504 315.139771,262.590210 
	C315.124237,262.754333 314.896576,262.992920 314.595276,263.169006 
	C313.169098,266.510986 312.044220,269.676910 310.937408,272.894928 
	C310.955475,272.946991 310.846405,272.962708 310.626495,273.126678 
	C310.243042,273.803101 310.079468,274.315521 309.932739,274.887512 
	C309.949615,274.947052 309.826660,274.961212 309.607727,275.128571 
	C309.240570,275.815979 309.092285,276.335999 308.931580,276.924408 
	C308.919189,276.992767 308.785065,276.956543 308.599792,277.045593 
	C308.185272,277.305481 308.033905,277.529114 307.972260,277.877136 
	C307.984100,277.948822 307.841431,277.976532 307.640015,278.092163 
	C307.202332,278.426392 307.051422,278.693329 306.988617,279.254791 
	C306.991333,279.501038 306.996094,279.993530 306.998047,279.996765 
	C307.000000,280.000000 306.991730,279.996613 306.767273,280.091858 
	C306.263123,280.380920 306.078705,280.642029 305.996185,280.985168 
	C306.002808,280.999878 305.973145,280.987671 305.640167,281.036774 
	C304.852112,282.006317 304.397034,282.926758 303.840912,284.089539 
	C303.468018,284.544739 303.196228,284.757538 302.582642,285.014771 
	C301.465729,286.305939 300.690613,287.552673 299.913757,288.868530 
	C299.912048,288.937714 299.776184,288.911560 299.427338,288.937073 
	C298.370270,289.935150 297.662048,290.907745 296.960571,291.927917 
	C296.967316,291.975494 296.871216,291.974823 296.672882,292.065308 
	C296.226837,292.329987 296.058624,292.561890 295.850098,293.093994 
	C295.438477,293.539429 295.146698,293.742310 294.497162,293.958618 
	C293.116760,295.014923 292.094116,296.057800 291.039124,297.065063 
	C291.006775,297.029449 291.098633,297.058014 290.848724,297.127533 
	C290.393890,297.466278 290.188965,297.735474 289.831116,298.207336 
	C289.396454,298.594574 289.114716,298.779205 288.463043,298.966125 
	C287.050201,299.923676 286.007294,300.878937 284.848694,301.971008 
	C284.732941,302.107849 284.377808,302.156128 283.988342,302.107971 
	C282.751648,302.733185 281.904449,303.406555 281.024384,304.048645 
	C280.991516,304.017334 281.068634,304.063110 280.759705,304.051025 
	C279.975067,304.371155 279.499359,304.703430 279.011841,305.017822 
	C279.000000,305.000000 279.026978,305.033600 278.719116,305.029144 
	C277.951447,305.373230 277.491669,305.721771 277.018372,306.035645 
	C277.004852,306.000946 277.056854,306.054230 276.678406,306.025696 
	C275.201691,306.656158 274.103424,307.315125 273.002899,307.993103 
	C273.000641,308.012054 272.963715,308.002136 272.778137,308.039490 
	C272.347931,308.176025 272.163269,308.341919 271.732300,308.737488 
	C270.973389,308.981964 270.520752,309.063538 269.864380,308.865662 
	C264.106598,310.044189 258.552643,311.502136 252.688721,313.066406 
	C251.945023,313.168304 251.511261,313.163910 250.814529,312.844574 
	C247.681564,312.733032 244.811584,312.936462 241.514069,313.144775 
	C239.384247,313.013855 237.681946,312.878052 235.822571,312.494781 
	C234.758072,312.218628 233.850662,312.189911 232.615250,312.144653 
	C231.869064,312.004333 231.450882,311.880524 230.982147,311.478516 
	C230.269638,311.176422 229.607697,311.152557 228.567276,311.124969 
	C227.448853,310.974792 226.708908,310.828369 225.817413,310.507507 
	C225.404587,310.136536 225.111420,310.056335 224.581421,310.090332 
	C224.376495,310.088196 223.996307,309.935089 223.817444,309.699646 
	C223.260300,309.378937 222.882004,309.293701 222.245407,309.235962 
	C221.662750,309.148743 221.338394,309.033936 220.936798,308.557190 
	C219.497406,307.836212 218.135239,307.477234 216.570923,307.115479 
	C216.368744,307.112701 215.999893,306.946960 215.838303,306.711792 
	C215.318207,306.372620 214.959702,306.268585 214.326263,306.144043 
	C213.722458,305.964661 213.393570,305.805725 213.068848,305.281403 
	C214.464081,304.228607 215.855133,303.541199 217.546814,302.883728 
	C218.561295,303.282013 219.275162,303.650360 220.002655,304.285187 
	C220.697433,304.686249 221.378540,304.820801 222.395050,304.979187 
	C223.161148,305.123566 223.591873,305.244141 224.166763,305.529877 
	C224.556854,305.883636 224.834061,305.962799 225.366760,305.946960 
	C225.590973,305.961334 226.010635,306.121887 226.242340,306.377014 
	C227.385406,306.716217 228.296783,306.800323 229.565857,306.879089 
	C230.621582,307.018921 231.319611,307.164062 232.186493,307.490234 
	C232.355347,307.671265 232.816406,307.862366 232.816406,307.862366 
	C232.816406,307.862366 233.315369,307.846893 233.704834,307.820862 
	C235.382874,307.947479 236.671463,308.100128 238.068405,308.604919 
	C240.503342,308.891205 242.829926,308.825348 245.471939,308.704163 
	C246.421783,308.702759 247.056198,308.756653 248.038757,308.984741 
	C251.234238,308.706299 254.081558,308.253632 256.946960,307.848267 
	C256.964996,307.895569 256.869812,307.861023 257.145386,307.922974 
	C257.608307,307.994598 257.795624,308.004303 258.327332,308.015320 
	C259.436737,307.649902 260.201813,307.283173 260.990173,306.946655 
	C261.013489,306.976868 260.946442,306.940796 261.227203,306.969788 
	C261.993042,306.764252 262.478088,306.529694 263.260986,306.166809 
	C263.988281,306.002075 264.417755,305.965698 265.129822,305.965637 
	C265.602356,306.005951 265.792267,306.009857 266.198853,305.927155 
	C266.694336,305.675903 266.893646,305.443207 267.205017,305.040802 
	C267.396454,304.939117 267.829803,304.925659 268.152161,304.949127 
	C268.987732,304.646118 269.500946,304.319611 270.280029,303.795715 
	C271.967896,303.037994 273.389893,302.477722 275.209473,301.958374 
	C276.699890,301.281952 277.792816,300.564606 278.932129,299.880432 
	C278.978516,299.913605 278.864899,299.903656 279.116272,299.856079 
	C279.584229,299.555145 279.800812,299.301788 280.136963,298.942657 
	C280.256531,298.836914 280.575714,298.841949 280.876099,298.812561 
	C281.437714,298.496399 281.698914,298.209625 281.972504,297.960815 
	C281.984894,297.998840 281.925262,297.945587 282.148438,297.910858 
	C282.665344,297.754517 282.875610,297.547913 283.167419,297.124603 
	C283.332458,296.992859 283.745789,296.905945 284.021606,296.852539 
	C284.519562,296.511719 284.741638,296.224243 284.978760,295.968536 
	C284.993744,296.000275 284.937683,295.957947 285.176453,295.895691 
	C285.720367,295.666595 285.923889,295.416931 286.023315,295.047607 
	C286.020813,295.010712 286.086060,295.045441 286.386322,295.023376 
	C287.098114,294.620087 287.509644,294.238831 287.945984,293.903015 
	C287.970795,293.948425 287.875549,293.907837 288.128235,293.850159 
	C288.579773,293.521637 288.778656,293.250824 288.988403,292.989990 
	C288.999268,293.000000 288.982086,292.976807 289.214722,292.905579 
	C289.742493,292.659332 289.933807,292.405884 290.015198,292.040405 
	C290.009155,292.006744 290.075684,292.022522 290.291931,291.927551 
	C290.778717,291.640411 290.947601,291.381805 291.007446,291.028381 
	C291.000092,291.000000 291.056824,291.015045 291.273804,290.930634 
	C291.763458,290.668579 291.945282,290.423523 292.035522,290.069580 
	C292.034790,290.028046 292.116882,290.040558 292.308746,289.963196 
	C292.744019,289.732300 292.911835,289.519196 293.175354,289.011902 
	C293.636078,288.521423 293.925446,288.265656 294.402802,287.916107 
	C294.822174,287.642609 294.970886,287.410004 295.121063,286.876831 
	C295.472198,286.420105 295.739105,286.211029 296.234985,285.919220 
	C296.753174,285.652283 296.937592,285.393402 297.009338,285.030579 
	C297.001404,285.001282 297.060028,285.016907 297.283844,284.912842 
	C297.786926,284.595764 297.942352,284.314362 297.987854,283.981323 
	C298.001801,283.998169 297.963989,283.977356 298.217194,283.849976 
	C298.609192,283.381683 298.748016,283.040802 298.967712,282.422791 
	C299.693970,281.437866 300.339325,280.730072 301.221069,279.903809 
	C301.604279,279.475922 301.751160,279.166504 301.912476,278.902832 
	C301.926880,278.948608 301.836487,278.916351 302.124695,278.804016 
	C302.607971,278.131622 302.803009,277.571594 302.997589,277.005310 
	C302.997162,276.999054 303.007904,276.994110 303.257355,276.844849 
	C303.623993,276.335785 303.741180,275.975983 303.889709,275.434143 
	C303.921082,275.252136 304.195038,275.004333 304.395081,274.884216 
	C304.834839,274.533875 304.951355,274.252594 304.967773,273.953613 
	C304.990784,273.986938 304.913727,273.961914 305.185059,273.824219 
	C305.631897,273.128510 305.807404,272.570496 305.985657,272.002899 
	C305.988403,271.993317 306.001190,271.977997 306.257324,271.813782 
	C306.612152,271.264526 306.710876,270.879486 306.807434,270.207397 
	C307.146393,269.261292 307.487488,268.602295 308.087585,267.871582 
	C308.567871,267.526947 308.789154,267.253998 309.000916,266.676483 
	C308.958374,266.171509 308.925415,265.971069 308.893127,265.565979 
	C308.893799,265.361328 309.129456,265.026642 309.494263,264.937439 
	C310.186768,263.441559 310.514465,262.034851 310.818542,260.462616 
	C310.794952,260.297089 310.983887,260.021149 311.299286,259.858582 
	C311.999542,257.431671 312.384369,255.167374 312.768372,252.581573 
	C312.879944,251.848114 312.992340,251.436172 313.430115,250.813232 
	C314.500031,245.451385 315.863190,240.303741 315.889709,235.149200 
	C316.076965,198.767227 315.989563,162.383850 315.989868,126.000916 
z"/>
<path fill="#FDF7D8" opacity="1.000000" stroke="none" 
	d="
M277.314667,129.942459 
	C280.621674,130.183075 280.007324,127.764404 280.014648,125.866554 
	C280.045441,117.877213 279.948517,109.886421 280.075439,101.898972 
	C280.114502,99.442566 280.471924,96.923225 281.161682,94.573021 
	C281.521790,93.346039 282.893311,91.616074 283.857727,91.583473 
	C284.893066,91.548485 286.408600,93.043648 286.951263,94.222466 
	C287.679321,95.804024 287.943787,97.722534 287.945587,99.496857 
	C287.992615,146.102005 288.007904,192.707260 287.926727,239.312317 
	C287.919922,243.216339 287.195312,247.119095 286.717010,251.892532 
	C288.131744,251.046814 288.776031,250.661682 289.785217,250.519211 
	C289.160126,253.279831 288.170135,255.797760 287.118591,257.902283 
	C286.916962,256.144501 286.776855,254.800171 286.603912,253.140518 
	C285.138397,254.356964 284.124176,255.198807 283.092834,255.660736 
	C284.070648,250.450348 285.821075,245.635559 285.921875,240.786484 
	C286.252716,224.872055 285.984467,208.945175 286.031433,192.558289 
	C286.104950,161.433746 286.120575,130.773911 286.017639,100.114464 
	C286.011169,98.186287 285.078278,96.261230 284.576385,94.334717 
	C283.994873,94.456276 283.413391,94.577835 282.831879,94.699387 
	C282.831879,99.487473 282.848206,104.275635 282.829193,109.063644 
	C282.719238,136.719070 282.600952,164.374466 282.132446,191.882767 
	C281.522888,189.981567 281.052277,188.228500 281.043915,186.473221 
	C280.982086,173.499786 281.052643,160.525620 280.966461,147.552475 
	C280.954742,145.784622 280.304901,144.021027 279.950500,142.255463 
	C279.420105,142.363403 278.889709,142.471329 278.359314,142.579269 
	C278.245880,143.845428 278.034393,145.111526 278.033325,146.377777 
	C278.007843,176.483505 277.997406,206.589233 278.020721,236.694962 
	C278.021851,238.167572 278.638611,239.671707 278.477997,241.106232 
	C278.051178,244.919022 278.469513,249.089249 274.829041,251.410492 
	C275.562500,246.639481 276.810547,242.283051 276.889282,237.905594 
	C277.115967,225.300705 276.870544,212.687332 276.882751,199.607117 
	C276.931122,185.450531 276.907318,171.764084 276.963531,157.610016 
	C277.133942,148.075745 277.224304,139.009094 277.314667,129.942459 
z"/>
<path fill="#E1B530" opacity="1.000000" stroke="none" 
	d="
M215.735962,340.982513 
	C215.735962,340.982513 216.002853,340.639008 216.512024,340.383545 
	C226.979065,339.822021 237.042786,338.913483 245.843475,345.192749 
	C245.843475,345.192749 245.953857,345.037903 245.940140,345.438538 
	C251.427795,351.969757 252.237289,359.070648 249.894485,366.626312 
	C247.641983,373.890656 241.479843,376.697571 234.979416,378.969849 
	C234.222412,378.991821 233.465408,379.013763 231.881622,379.029785 
	C226.078445,378.959961 221.102066,378.896088 216.125671,378.832214 
	C216.125671,378.832214 216.031937,378.970795 216.063034,378.462585 
	C215.974731,365.630432 215.855347,353.306458 215.735962,340.982513 
M240.700226,369.108032 
	C244.801147,363.412445 245.319046,357.327362 241.700699,351.386871 
	C237.388535,344.307373 230.049789,345.986938 222.778854,345.480469 
	C222.778854,354.124084 222.708069,362.392365 222.883377,370.655426 
	C222.901825,371.524750 224.341492,373.067810 225.170349,373.101440 
	C230.434448,373.315063 235.926422,374.086212 240.700226,369.108032 
z"/>
<path fill="#C89031" opacity="1.000000" stroke="none" 
	d="
M169.097122,256.793121 
	C169.097122,256.793121 169.076294,256.389069 169.063904,255.826279 
	C168.757172,253.825302 168.462830,252.387131 168.168488,250.948944 
	C168.141403,250.514771 168.114349,250.080597 168.047180,248.872070 
	C167.977722,241.730850 167.948364,235.364014 167.919022,228.997162 
	C168.010468,228.569839 168.101929,228.142502 168.381363,227.142365 
	C168.643356,225.709930 168.717361,224.850281 168.791367,223.990646 
	C168.912323,223.328812 169.033295,222.666977 169.402832,221.444153 
	C169.742905,220.254562 169.834412,219.625961 169.925934,218.997375 
	C170.011108,218.622147 170.096298,218.246902 170.393860,217.432816 
	C170.679230,216.659271 170.752213,216.324600 170.825195,215.989914 
	C170.967773,215.578156 171.110336,215.166382 171.543701,214.190598 
	C173.935226,208.785248 176.035980,203.943924 178.136719,199.102600 
	C178.136719,199.102600 178.051865,198.985214 178.373413,198.888306 
	C179.819473,196.876495 180.943970,194.961594 182.068481,193.046692 
	C182.068481,193.046692 182.007828,192.997086 182.336197,192.918701 
	C183.473602,191.584702 184.282639,190.329071 185.091705,189.073456 
	C185.091705,189.073456 185.034103,188.996475 185.304565,188.973328 
	C185.743301,188.650192 185.911575,188.350189 186.079834,188.050186 
	C186.079834,188.050186 186.006897,188.007599 186.282898,187.968964 
	C186.729752,187.634811 186.900604,187.339310 187.071457,187.043793 
	C187.071457,187.043793 187.005310,187.004715 187.279831,186.968719 
	C187.737152,186.642761 187.919937,186.352798 188.102737,186.062851 
	C188.102737,186.062851 188.022018,186.018433 188.300781,185.989624 
	C188.749939,185.657608 188.920334,185.354431 189.090729,185.051239 
	C189.090729,185.051239 189.013031,185.015427 189.286377,184.969696 
	C189.727325,184.627716 189.894928,184.331482 190.062531,184.035263 
	C190.062531,184.035263 190.000336,184.005951 190.265228,183.950195 
	C190.688919,183.598770 190.847733,183.303101 191.006546,183.007431 
	C191.006546,183.007431 191.000000,183.000000 191.255981,182.940796 
	C191.694122,182.599701 191.876282,182.317810 192.058441,182.035904 
	C192.058441,182.035904 192.004395,181.999557 192.277023,181.965881 
	C192.716293,181.631378 192.882919,181.330536 193.049561,181.029694 
	C193.049576,181.029709 193.000610,181.000610 193.270538,180.957733 
	C193.725220,180.629913 193.909943,180.345001 194.094681,180.060074 
	C194.094681,180.060074 194.015366,180.014435 194.298126,179.991852 
	C194.766403,179.676300 194.951904,179.383331 195.137421,179.090363 
	C195.137405,179.090363 195.042007,179.042267 195.323090,179.012985 
	C195.781570,178.685242 195.958969,178.386765 196.136353,178.088287 
	C196.136353,178.088287 196.045929,178.043396 196.334595,178.018127 
	C196.842072,177.700180 197.060867,177.407516 197.279663,177.114853 
	C197.279663,177.114853 197.670380,177.010834 198.064865,176.868774 
	C198.813690,176.603836 198.979782,176.355164 198.957611,175.980728 
	C199.232544,175.846436 199.480240,175.673187 200.126587,175.243744 
	C200.750748,174.719772 200.949005,174.413040 201.147263,174.106323 
	C201.147263,174.106323 201.025818,174.098190 201.413315,174.102112 
	C203.238358,173.110886 204.675919,172.115753 206.113480,171.120605 
	C206.113480,171.120621 205.994339,171.097702 206.380127,171.096848 
	C207.624008,170.453094 208.482101,169.810196 209.340195,169.167297 
	C209.340195,169.167297 209.682434,169.147751 210.165817,169.082001 
	C211.805435,168.385437 212.961655,167.754639 214.117874,167.123840 
	C214.117874,167.123840 214.024109,167.042923 214.363632,167.091278 
	C215.222839,166.807083 215.742523,166.474518 216.262207,166.141968 
	C216.262207,166.141953 216.649490,166.130997 217.191971,166.043640 
	C221.338242,164.736420 224.942047,163.516571 228.545837,162.296707 
	C228.879852,162.270706 229.213852,162.244705 230.153519,162.144287 
	C232.191299,161.745026 233.623428,161.420197 235.055557,161.095352 
	C235.491302,161.080475 235.927032,161.065613 237.144531,161.028503 
	C244.638168,160.879700 251.350037,160.753113 258.061920,160.626526 
	C258.498474,160.667252 258.935028,160.707993 260.076904,160.795044 
	C261.817291,160.774673 262.852325,160.707993 263.877502,160.943726 
	C263.441681,162.282501 263.015717,163.318863 262.200500,164.379517 
	C259.988617,164.364639 258.165924,164.325439 255.858734,164.184723 
	C250.589554,164.277649 245.804932,164.472122 240.761200,164.781479 
	C240.325760,164.964172 240.149399,165.031982 239.548492,165.027725 
	C235.684891,165.612457 232.245819,166.269287 228.592422,166.905533 
	C228.378098,166.884964 227.985977,167.062912 227.645126,167.028259 
	C226.495102,167.313309 225.685944,167.632980 224.641449,167.969147 
	C224.406143,167.985626 223.966721,168.157379 223.662125,168.115540 
	C222.832672,168.354294 222.307831,168.634903 221.568420,168.918457 
	C221.353882,168.921387 220.974716,169.122360 220.679199,169.107422 
	C219.894958,169.382645 219.406235,169.672836 218.666931,169.981384 
	C218.265091,170.071701 218.113846,170.143677 217.699677,170.213593 
	C217.179642,170.437592 216.922531,170.663651 216.481552,170.884415 
	C216.297699,170.879120 215.988815,171.078934 215.621429,171.078613 
	C212.839218,172.386765 210.424377,173.695236 208.004288,175.002014 
	C207.999054,175.000336 208.004318,175.011719 207.664032,175.073761 
	C205.903656,176.090927 204.483551,177.046051 202.801788,178.209595 
	C201.614395,179.123260 200.662231,179.796616 199.766815,180.538483 
	C179.879745,197.015091 170.269562,218.019806 171.579758,243.962189 
	C172.171661,255.681717 176.029083,266.362122 181.000000,277.037231 
	C180.151794,277.939331 179.447250,278.521484 178.477890,278.890808 
	C175.522659,272.741821 172.832245,266.805664 170.141815,260.869537 
	C170.141815,260.869537 170.078400,260.963684 170.165100,260.573883 
	C169.866898,259.053772 169.482010,257.923431 169.097122,256.793121 
z"/>
<path fill="#FEFCE6" opacity="1.000000" stroke="none" 
	d="
M215.364716,341.098145 
	C215.855347,353.306458 215.974731,365.630432 216.128082,378.409973 
	C212.677597,379.346741 212.910400,377.163239 213.202850,374.828064 
	C213.580475,371.813019 213.856995,368.785278 214.177063,365.763000 
	C213.648483,365.590363 213.119919,365.417725 212.591354,365.245087 
	C211.736710,367.149078 210.283188,369.009521 210.140854,370.965393 
	C209.730621,376.602478 206.534973,379.083801 201.125519,379.560303 
	C200.948837,378.856079 201.060532,378.486206 201.525482,378.085327 
	C204.007629,376.309418 206.136520,374.564514 208.265411,372.819580 
	C207.875290,372.169708 207.485153,371.519836 207.095016,370.869995 
	C205.369583,371.719879 203.724777,372.887695 201.901108,373.350189 
	C198.608536,374.185150 195.068542,375.467194 191.892120,374.953522 
	C188.435028,374.394409 186.406921,371.231812 186.171539,366.493347 
	C193.937180,366.493347 201.385452,366.493347 209.182556,366.423889 
	C209.423523,364.487701 209.315643,362.620911 209.044235,360.444214 
	C205.853653,352.961365 202.812744,350.951752 194.974030,350.943878 
	C186.903214,350.935760 183.518860,352.703400 181.137238,360.009033 
	C180.054901,363.329132 181.002319,367.310883 181.040222,371.268524 
	C181.331482,371.761353 181.611542,371.978149 181.983429,372.469177 
	C182.147568,373.119507 182.219864,373.495575 182.292145,373.871643 
	C180.432861,372.164032 178.573578,370.456421 176.458527,368.513916 
	C172.691071,373.363220 169.683533,380.146973 161.188080,378.955322 
	C160.979691,378.448730 161.017715,378.012878 161.009689,378.005829 
	C161.001663,377.998779 161.007614,378.017365 161.357697,378.028748 
	C162.937180,377.454834 164.166580,376.869507 165.563354,376.247314 
	C165.730713,376.210480 165.993332,375.990295 166.320740,375.969360 
	C167.529739,375.090302 168.411346,374.232147 169.297791,373.340271 
	C169.302643,373.306519 169.370392,373.298584 169.662872,373.236481 
	C170.399261,372.559174 170.843170,371.943970 171.246613,371.275269 
	C171.206146,371.221771 171.332260,371.267456 171.622925,371.175720 
	C172.284851,370.394470 172.656128,369.704987 173.271881,368.846313 
	C173.659103,368.112488 173.801849,367.547791 174.219131,366.727081 
	C174.642609,362.984222 174.791565,359.497437 174.962982,355.662079 
	C175.042236,354.853943 175.099030,354.394379 175.155823,353.934784 
	C178.448196,355.984131 181.108994,356.557892 183.484772,352.261658 
	C184.788193,349.904633 187.539734,348.348450 190.510376,345.634399 
	C196.163940,349.545044 207.296768,346.891113 210.762939,358.012695 
	C211.245850,358.077332 211.728775,358.141968 212.211700,358.206635 
	C212.808624,356.572754 213.719284,354.979980 213.939850,353.296783 
	C214.464661,349.291870 214.663147,345.244171 215.364716,341.098145 
z"/>
<path fill="#FEF9E0" opacity="1.000000" stroke="none" 
	d="
M315.984253,125.629913 
	C315.989563,162.383850 316.076965,198.767227 315.889709,235.149200 
	C315.863190,240.303741 314.500031,245.451385 313.371582,250.392609 
	C313.195801,247.108353 313.404022,244.033707 313.752594,240.513748 
	C313.913635,202.479141 313.967041,164.889786 313.866638,127.300835 
	C313.861115,125.234428 312.576324,123.171440 311.887024,121.106857 
	C311.254242,121.252831 310.621460,121.398804 309.988678,121.544769 
	C309.988678,126.942360 309.975128,132.339996 309.990997,137.737534 
	C310.061920,161.847824 310.141266,185.958084 310.168884,210.513824 
	C310.191071,213.936813 310.261902,216.914368 310.246155,220.320190 
	C310.214752,222.202972 310.269989,223.657501 310.228485,225.563721 
	C309.807037,233.028473 309.482269,240.041519 309.157532,247.054550 
	C308.775513,243.441284 308.069519,239.828735 308.061462,236.214645 
	C307.979309,199.310532 307.980133,162.406067 308.079102,125.502029 
	C308.085663,123.058632 309.080902,120.617889 309.616272,118.175911 
	C310.308441,118.018982 311.000580,117.862061 311.692749,117.705132 
	C313.121368,120.223061 314.549988,122.740990 315.984253,125.629913 
z"/>
<path fill="#E3B321" opacity="1.000000" stroke="none" 
	d="
M173.944595,366.983124 
	C173.801849,367.547791 173.659103,368.112488 172.931458,368.881714 
	C172.008484,369.813354 171.670380,370.540405 171.332275,371.267456 
	C171.332260,371.267456 171.206146,371.221771 170.887604,371.216187 
	C170.169510,371.906586 169.769958,372.602600 169.370392,373.298584 
	C169.370392,373.298584 169.302643,373.306519 168.943298,373.253235 
	C167.720428,374.130066 166.856873,375.060181 165.993332,375.990295 
	C165.993332,375.990295 165.730713,376.210480 165.185638,376.165344 
	C163.429581,376.752563 162.218597,377.384949 161.007614,378.017365 
	C161.007614,378.017365 161.001663,377.998779 160.736877,377.985291 
	C159.996521,378.187683 159.520950,378.403625 159.045380,378.619568 
	C158.585098,378.726990 158.124817,378.834412 156.938843,378.943665 
	C155.159988,378.903870 154.106827,378.862305 152.724686,378.581360 
	C151.077499,378.238647 149.732376,377.914398 148.445816,378.071045 
	C143.507584,378.672211 141.621704,376.849060 141.870041,371.667816 
	C142.267944,363.366455 142.189743,355.022736 141.862579,346.714630 
	C141.705246,342.719025 143.476303,341.251465 146.932449,341.071747 
	C148.902527,340.969269 150.895721,341.250702 152.875183,341.402771 
	C156.790573,341.703522 160.703644,342.034515 164.930359,342.466858 
	C165.824951,342.902100 166.406876,343.223450 167.085434,343.672241 
	C167.341965,343.952362 167.530624,344.048950 168.006302,344.165955 
	C168.506027,344.500397 168.747482,344.758423 169.022430,345.335144 
	C169.686768,346.113770 170.317612,346.573700 170.971817,347.015137 
	C170.995193,346.996704 170.964096,347.046997 170.986053,347.352234 
	C171.406891,348.060089 171.805771,348.462646 172.114670,348.934448 
	C172.024689,349.003693 172.189636,348.848022 172.125870,349.214050 
	C172.957642,351.089081 173.853195,352.598083 174.748734,354.107056 
	C174.748734,354.107056 174.959503,354.038239 175.057648,353.986511 
	C175.099030,354.394379 175.042236,354.853943 174.694183,355.946838 
	C174.250153,360.047821 174.097382,363.515472 173.944595,366.983124 
M149.604706,344.558990 
	C148.761978,345.848450 147.259979,347.104095 147.191772,348.433289 
	C146.911896,353.889038 147.065994,359.367218 147.065048,364.837128 
	C147.063385,374.487427 147.064819,374.487427 156.705460,373.179443 
	C163.492905,372.258545 166.984055,369.005066 168.371262,362.307709 
	C169.683594,355.971832 167.665649,350.196320 162.040619,347.529083 
	C158.520187,345.859802 154.244812,345.782684 149.604706,344.558990 
z"/>
<path fill="#FDFFFD" opacity="1.000000" stroke="none" 
	d="
M319.954956,374.585846 
	C318.912445,375.018311 317.869934,375.450775 316.102783,375.792328 
	C313.592010,373.201019 311.816254,370.693054 310.015137,368.203491 
	C309.158539,367.019470 308.253571,365.870453 306.924316,364.116943 
	C309.366730,360.646118 312.468262,356.238678 315.651306,351.715424 
	C313.486084,349.454010 311.586395,346.816437 307.774872,349.043396 
	C306.700806,349.670898 305.278107,349.701630 304.015320,350.006104 
	C303.715668,348.937164 302.823975,347.420776 303.229218,346.884552 
	C304.370605,345.374268 306.045227,344.266937 307.710846,342.826813 
	C307.647522,341.778992 307.562408,340.370453 307.506104,339.438690 
	C310.573822,338.399200 313.163361,337.201538 315.881561,336.697723 
	C318.232483,336.261963 321.021912,335.902618 323.104431,336.758209 
	C328.314148,338.898773 333.071808,341.185364 338.944031,338.264679 
	C340.922546,337.280609 344.066162,338.638855 346.677307,338.926575 
	C346.696106,339.590240 346.714935,340.253906 346.733765,340.917572 
	C345.190613,341.231415 343.655151,341.592377 342.102264,341.847137 
	C340.206940,342.157990 338.296997,342.379700 336.393372,342.639984 
	C338.264587,343.427795 340.164032,344.155457 342.000793,345.016724 
	C345.179169,346.507050 348.318695,348.080200 351.609680,350.141113 
	C351.169159,352.442871 350.593414,354.222137 349.754639,356.106812 
	C348.992126,356.475616 348.492706,356.739044 347.844788,356.704926 
	C346.743988,355.962158 345.791687,355.516907 344.627014,354.976990 
	C344.135803,354.695282 343.986176,354.434204 343.889709,353.849884 
	C343.538788,353.402039 343.263977,353.203400 342.994568,353.002380 
	C343.000000,353.000000 342.995789,353.010193 342.880310,352.776733 
	C342.459015,352.399323 342.153198,352.255402 341.900024,352.123383 
	C341.952667,352.135284 341.853607,352.178253 341.859131,351.802734 
	C338.576416,350.286072 335.288208,349.144867 331.995575,347.630707 
	C329.924561,345.176575 328.095490,341.754791 325.734314,341.336456 
	C322.403717,340.746338 317.669647,339.001160 314.869385,344.795197 
	C317.652679,346.576447 320.334564,348.292786 322.809753,350.284454 
	C317.713501,352.738525 317.630432,357.944336 315.461945,361.845459 
	C313.033661,366.213867 314.326904,370.471252 319.189087,373.249329 
	C319.577301,373.852570 319.766144,374.219208 319.954956,374.585846 
z"/>
<path fill="#E1B837" opacity="1.000000" stroke="none" 
	d="
M343.965790,354.099060 
	C343.986176,354.434204 344.135803,354.695282 344.672913,355.283325 
	C345.536346,356.486267 346.141510,357.288147 346.767883,358.471741 
	C346.983124,359.588867 347.177124,360.324310 347.340942,361.300903 
	C347.381042,361.887451 347.574066,362.138519 347.848969,362.473572 
	C347.808105,362.651855 347.952057,362.988159 347.886627,363.323364 
	C347.882172,364.106506 347.943146,364.554504 347.937073,365.188049 
	C347.870056,365.373596 347.923126,365.764618 347.681519,365.892944 
	C347.247955,366.323242 347.055969,366.625214 346.903534,366.911194 
	C346.943085,366.895233 346.884155,366.833496 346.455963,366.805084 
	C344.009491,366.896942 341.991211,367.017181 339.600647,367.066345 
	C338.476532,367.051331 337.724670,367.107452 336.518982,367.078735 
	C332.191223,366.993927 328.317352,366.993927 324.443481,366.993927 
	C324.165497,367.497894 323.887543,368.001862 323.609558,368.505829 
	C325.399475,370.347015 327.189423,372.188232 328.990021,374.015015 
	C329.000702,374.000580 328.970734,374.019257 329.054199,374.229065 
	C329.137665,374.438904 329.517365,374.681824 329.517365,374.681824 
	C329.517365,374.681824 329.964386,374.624176 330.348267,374.763550 
	C331.813568,374.884552 332.895050,374.866211 334.331696,374.919312 
	C336.487366,373.683685 338.287872,372.376617 340.046417,371.035217 
	C340.004486,371.000946 340.069733,371.087219 340.408020,370.966370 
	C342.559052,370.876892 344.371796,370.908264 346.211304,371.243744 
	C346.295349,371.743225 346.352600,371.938538 346.229126,372.435242 
	C342.780121,375.067078 339.797028,378.433075 336.162537,379.434021 
	C332.598572,380.415497 328.371979,378.991058 324.436218,378.622528 
	C324.436218,378.622528 324.374481,378.648804 324.264069,378.338348 
	C323.464874,377.644714 322.776093,377.261536 322.087311,376.878357 
	C322.087311,376.878357 322.016327,376.942291 321.972290,376.670990 
	C321.616669,376.201660 321.305115,376.003662 320.993561,375.805634 
	C320.752380,375.480316 320.511169,375.155029 320.112457,374.707794 
	C319.766144,374.219208 319.577301,373.852570 319.156403,372.866730 
	C314.673981,358.717072 321.624176,348.958374 334.778290,350.187439 
	C337.177765,350.411621 339.497223,351.492737 341.853607,352.178253 
	C341.853607,352.178253 341.952667,352.135284 341.991852,352.373657 
	C342.352631,352.744720 342.674194,352.877472 342.995789,353.010193 
	C342.995789,353.010193 343.000000,353.000000 343.083496,353.244995 
	C343.433258,353.692993 343.699524,353.896027 343.965790,354.099060 
M329.748230,362.982910 
	C333.439270,362.982910 337.130280,362.982910 341.026215,362.982910 
	C339.395660,356.382751 337.155121,354.527618 331.969696,354.883057 
	C327.381104,355.197601 324.791534,357.603271 324.229218,362.672302 
	C325.979340,362.790222 327.411743,362.886749 329.748230,362.982910 
z"/>
<path fill="#E1B530" opacity="1.000000" stroke="none" 
	d="
M289.923065,338.999542 
	C289.923065,338.999542 290.087646,338.646759 290.523804,338.400543 
	C292.634552,338.110931 294.309204,338.067566 295.983826,338.024170 
	C295.983826,338.024170 295.999237,337.999176 296.016174,338.474884 
	C296.033142,346.159912 296.033142,353.369263 296.033142,360.578583 
	C296.690735,360.895081 297.348297,361.211609 298.005890,361.528107 
	C300.118958,359.020355 302.263397,356.538208 304.336609,353.997894 
	C306.636230,351.180237 309.269135,349.811584 313.600922,351.886047 
	C310.938232,354.661072 308.686920,357.248596 306.181213,359.560394 
	C302.707031,362.765717 302.285645,365.518829 306.144104,368.923828 
	C309.123688,371.553192 311.477417,374.891785 314.688843,378.580688 
	C311.599792,378.805725 309.792969,378.937347 307.820312,378.971497 
	C307.654541,378.874054 307.270325,378.856659 307.139648,378.544250 
	C303.816254,374.669495 300.623596,371.107117 297.430939,367.544769 
	C297.007690,367.758331 296.584473,367.971924 296.161224,368.185486 
	C296.161224,370.303558 296.155579,372.421631 296.162781,374.539642 
	C296.170319,376.751373 296.405029,378.943390 293.018402,378.903473 
	C289.553558,378.862671 290.034027,376.513641 290.025238,374.401276 
	C289.975983,362.600739 289.954193,350.800140 289.923065,338.999542 
z"/>
<path fill="#C89031" opacity="1.000000" stroke="none" 
	d="
M313.612244,240.959076 
	C313.404022,244.033707 313.195801,247.108353 313.046143,250.603607 
	C312.992340,251.436172 312.879944,251.848114 312.530243,252.859924 
	C311.856567,255.646896 311.420227,257.834015 310.983887,260.021149 
	C310.983887,260.021149 310.794952,260.297089 310.561279,260.702637 
	C309.928192,262.414337 309.528809,263.720490 309.129456,265.026642 
	C309.129456,265.026642 308.893799,265.361328 308.683258,265.706116 
	C308.380127,266.365875 308.287506,266.680878 308.039185,267.207916 
	C307.865204,267.594421 307.846893,267.768860 307.828613,267.943298 
	C307.487488,268.602295 307.146393,269.261292 306.568390,270.390839 
	C306.221375,271.233582 306.111298,271.605804 306.001190,271.977997 
	C306.001190,271.977997 305.988403,271.993317 305.732880,272.156433 
	C305.289490,272.866974 305.101593,273.414429 304.913696,273.961914 
	C304.913727,273.961914 304.990784,273.986938 304.746674,274.054291 
	C304.235413,274.358490 304.132904,274.652740 304.195038,275.004333 
	C304.195038,275.004333 303.921082,275.252136 303.644714,275.590179 
	C303.248199,276.283508 303.128052,276.638794 303.007904,276.994110 
	C303.007904,276.994110 302.997162,276.999054 302.738678,277.150757 
	C302.265625,277.840393 302.051056,278.378387 301.836487,278.916351 
	C301.836487,278.916351 301.926880,278.948608 301.649719,278.990387 
	C301.243286,279.362213 301.113983,279.692230 300.984680,280.022278 
	C300.339325,280.730072 299.693970,281.437866 298.721008,282.559570 
	C298.250275,283.308075 298.107147,283.642731 297.963989,283.977356 
	C297.963989,283.977356 298.001801,283.998169 297.751129,284.069458 
	C297.353638,284.432800 297.206848,284.724854 297.060028,285.016907 
	C297.060028,285.016907 297.001404,285.001282 296.761353,285.088348 
	C296.349518,285.450928 296.177765,285.726440 296.006012,286.001923 
	C295.739105,286.211029 295.472198,286.420105 294.900696,286.932617 
	C294.299683,287.410553 294.172577,287.668488 294.214813,288.009888 
	C293.925446,288.265656 293.636078,288.521423 292.952515,289.040924 
	C292.258270,289.458191 292.111145,289.703491 292.116882,290.040558 
	C292.116882,290.040558 292.034790,290.028046 291.791321,290.115295 
	C291.216888,290.372253 291.053223,290.643097 291.056824,291.015045 
	C291.056824,291.015045 291.000092,291.000000 290.765808,291.087097 
	C290.232452,291.377899 290.080536,291.660675 290.075684,292.022522 
	C290.075684,292.022522 290.009155,292.006744 289.769226,292.091248 
	C289.346924,292.442749 289.164520,292.709778 288.982086,292.976807 
	C288.982086,292.976807 288.999268,293.000000 288.723511,293.030762 
	C288.256989,293.343628 288.066254,293.625732 287.875549,293.907837 
	C287.875549,293.907837 287.970795,293.948425 287.652954,293.925781 
	C286.918732,294.283875 286.502411,294.664673 286.086060,295.045441 
	C286.086060,295.045441 286.020813,295.010712 285.768219,295.081238 
	C285.322968,295.420502 285.130341,295.689209 284.937683,295.957947 
	C284.937683,295.957947 284.993744,296.000275 284.704132,296.020874 
	C284.191620,296.329620 283.968689,296.617767 283.745789,296.905945 
	C283.745789,296.905945 283.332458,296.992859 282.926575,297.122986 
	C282.168060,297.351318 281.969574,297.582153 281.925262,297.945587 
	C281.925262,297.945587 281.984894,297.998840 281.680817,298.002167 
	C281.109741,298.284302 280.842712,298.563141 280.575714,298.841949 
	C280.575714,298.841949 280.256531,298.836914 279.868469,298.958710 
	C279.275238,299.354889 279.070068,299.629272 278.864899,299.903656 
	C278.864899,299.903656 278.978516,299.913605 278.599548,299.909973 
	C277.084381,300.576721 275.948151,301.247101 274.811920,301.917480 
	C273.389893,302.477722 271.967896,303.037994 269.964020,303.777191 
	C268.864716,304.279266 268.347260,304.602448 267.829803,304.925659 
	C267.829803,304.925659 267.396454,304.939117 266.939514,305.014771 
	C266.317963,305.157562 266.153351,305.224701 265.748169,305.286194 
	C265.162292,305.369385 264.942169,305.585632 264.847198,305.929291 
	C264.417755,305.965698 263.988281,306.002075 262.979248,306.121887 
	C261.915222,306.450500 261.430847,306.695648 260.946442,306.940796 
	C260.946442,306.940796 261.013489,306.976868 260.612366,306.897797 
	C259.471741,306.982330 258.732208,307.145905 257.755127,307.279236 
	C257.142853,307.284943 256.926941,307.488953 256.869812,307.861023 
	C256.869812,307.861023 256.964996,307.895569 256.514832,307.786194 
	C253.273285,308.054718 250.481949,308.432617 247.690598,308.810547 
	C247.056198,308.756653 246.421783,308.702759 245.449921,308.533173 
	C245.112442,308.417480 244.936813,308.120148 245.047119,307.777863 
	C245.401855,306.519043 245.646301,305.602539 246.154999,304.621948 
	C246.938171,304.404327 247.457138,304.250763 248.411621,304.164612 
	C251.592468,303.876984 254.337814,303.521942 257.408630,303.178833 
	C258.154053,303.090546 258.574066,302.990265 259.299744,302.911011 
	C259.932953,302.706757 260.260468,302.481476 260.827271,302.320618 
	C261.406708,302.365601 261.713593,302.254333 262.360657,302.087189 
	C264.522644,301.448792 266.311096,300.774506 268.055054,300.070129 
	C268.010559,300.040070 268.057159,300.136841 268.380157,300.145569 
	C269.229401,299.812012 269.755676,299.469696 270.587219,299.105530 
	C271.267487,298.927826 271.642426,298.772003 272.240295,298.644897 
	C272.782288,298.567535 272.967010,298.348328 273.008667,298.007935 
	C273.000000,298.000000 273.016449,298.017822 273.351807,297.993469 
	C275.163422,297.018372 276.639648,296.067627 278.056122,295.115326 
	C277.996338,295.113770 278.110535,295.150208 278.428802,295.122925 
	C279.544647,294.441010 280.342255,293.786377 281.096069,293.078400 
	C281.052338,293.025024 281.095032,293.156281 281.361816,293.098755 
	C281.831970,292.757416 282.035309,292.473633 282.186401,292.216858 
	C282.134155,292.243835 282.250122,292.263245 282.560577,292.200012 
	C284.003906,291.182861 285.136810,290.228943 286.589539,289.170410 
	C289.383575,286.423859 291.857788,283.781860 294.521423,281.062134 
	C294.954987,280.822845 295.095886,280.596924 295.422974,280.133209 
	C296.581299,278.685394 297.450134,277.410950 298.508484,276.022858 
	C298.920288,275.690735 299.034760,275.425690 299.273499,275.012573 
	C299.607971,274.605865 299.710358,274.300690 300.008667,273.789856 
	C300.494324,273.107330 300.784088,272.630402 301.329651,271.937042 
	C302.083130,270.498993 302.580841,269.277374 303.049744,268.023071 
	C303.020966,267.990387 303.063416,268.064087 303.315826,267.896179 
	C303.733032,267.160339 303.897827,266.592377 304.030701,266.016174 
	C303.998749,266.007935 304.046906,266.053131 304.290436,265.875519 
	C304.671570,265.126770 304.809174,264.555603 305.044800,263.830017 
	C305.142853,263.675537 305.135834,263.309662 305.393829,263.094360 
	C305.812714,262.242096 305.973602,261.605103 306.219482,260.870544 
	C306.304504,260.772980 306.214600,260.530334 306.446411,260.338867 
	C306.746948,259.763458 306.815674,259.379486 307.004028,258.696228 
	C307.193054,257.973267 307.262512,257.549561 307.585266,256.851166 
	C308.202332,254.039200 308.566071,251.501938 308.929840,248.964691 
	C309.031342,248.544327 309.132812,248.123978 309.195923,247.379089 
	C309.482269,240.041519 309.807037,233.028473 310.446899,225.868225 
	C311.496185,228.143707 312.230347,230.566422 312.981079,233.411957 
	C313.202545,236.209549 313.407410,238.584305 313.612244,240.959076 
z"/>
<path fill="#C89031" opacity="1.000000" stroke="none" 
	d="
M287.180145,258.315704 
	C288.170135,255.797760 289.160126,253.279831 290.088013,250.383087 
	C290.025879,250.004272 290.171204,249.659576 290.433777,249.202942 
	C290.771423,247.494843 290.846466,246.243378 290.921539,244.991913 
	C291.023590,243.947174 291.125641,242.902435 291.426453,241.119965 
	C291.770477,229.929916 291.915680,219.477570 292.437622,209.016663 
	C293.569366,209.010605 294.324341,209.013123 295.179443,209.473389 
	C295.450195,220.153366 295.620911,230.375580 295.552643,240.900665 
	C295.255920,242.471298 295.198181,243.739075 295.057312,245.376694 
	C295.002075,246.498947 295.029999,247.251343 294.984863,248.208374 
	C294.911835,248.413010 294.926239,248.847305 294.674438,249.080933 
	C294.267120,250.208511 294.111603,251.102478 293.956116,251.996445 
	C287.451935,272.330750 274.177551,285.311249 252.955154,289.512146 
	C250.392090,290.019440 247.681366,289.780853 244.735291,289.513000 
	C244.631058,288.177368 244.830612,287.216736 245.469345,286.189453 
	C252.317383,284.435791 258.726257,282.748779 265.359802,281.024231 
	C265.584534,280.986725 265.988190,280.775360 266.244537,280.785950 
	C266.722015,280.553040 266.943115,280.309540 267.397278,279.996765 
	C267.630310,279.927460 268.002350,279.614441 268.220917,279.625916 
	C268.742218,279.523590 268.921509,279.307343 268.987610,278.995636 
	C268.997864,279.002563 268.988892,278.981171 269.312195,278.932617 
	C270.804718,277.970673 271.973999,277.057343 273.109314,276.080627 
	C273.075378,276.017273 273.098969,276.159058 273.381042,276.091064 
	C273.914062,275.737396 274.165009,275.451660 274.577881,275.114410 
	C274.739868,275.062866 274.942108,274.789581 275.226105,274.707581 
	C275.849487,274.151398 276.188873,273.677216 276.667664,273.171967 
	C276.807098,273.140900 276.984253,272.916748 277.232849,272.871185 
	C277.647400,272.547882 277.813385,272.270142 277.990295,271.996277 
	C278.001221,272.000183 277.988953,271.980377 278.286713,271.855774 
	C279.440399,270.530090 280.296295,269.329071 281.113770,268.056641 
	C281.075378,267.985199 281.134521,268.133057 281.427124,268.009460 
	C282.209930,266.980469 282.700104,266.075073 283.152710,265.156616 
	C283.115112,265.143494 283.178955,265.154663 283.473145,265.033417 
	C284.239990,263.991058 284.712646,263.069946 285.157288,262.084839 
	C285.129303,262.020813 285.134979,262.160431 285.375092,262.052368 
	C285.713745,261.620270 285.812286,261.296204 286.022339,260.844513 
	C286.133881,260.716888 286.155792,260.378632 286.391846,260.215942 
	C286.726837,259.695557 286.825775,259.337921 287.034058,258.831604 
	C287.143372,258.682922 287.180145,258.315704 287.180145,258.315704 
z"/>
<path fill="#DEB01D" opacity="1.000000" stroke="none" 
	d="
M181.028992,370.992523 
	C181.002319,367.310883 180.054901,363.329132 181.137238,360.009033 
	C183.518860,352.703400 186.903214,350.935760 194.974030,350.943878 
	C202.812744,350.951752 205.853653,352.961365 208.964081,360.859558 
	C208.976196,363.221008 208.904953,364.857178 208.833710,366.493347 
	C201.385452,366.493347 193.937180,366.493347 186.171539,366.493347 
	C186.406921,371.231812 188.435028,374.394409 191.892120,374.953522 
	C195.068542,375.467194 198.608536,374.185150 201.901108,373.350189 
	C203.724777,372.887695 205.369583,371.719879 207.095016,370.869995 
	C207.485153,371.519836 207.875290,372.169708 208.265411,372.819580 
	C206.136520,374.564514 204.007629,376.309418 201.195694,378.081482 
	C199.343246,378.611816 198.173843,379.114990 196.631958,379.693298 
	C195.503571,379.728577 194.747665,379.688782 193.742233,379.427826 
	C191.219925,378.551331 188.947128,377.895966 186.493958,377.223999 
	C186.313583,377.207367 185.992233,377.040100 185.890594,376.798431 
	C185.493225,376.387848 185.197525,376.218903 184.672028,375.896545 
	C183.977142,375.460419 183.512024,375.177673 183.046921,374.894958 
	C183.042130,374.574646 182.922119,374.305756 182.489517,373.979980 
	C182.219864,373.495575 182.147568,373.119507 181.929565,372.214447 
	C181.532227,371.454498 181.280609,371.223511 181.028992,370.992523 
M187.305023,362.585724 
	C191.565628,362.722595 195.827835,362.995422 200.086212,362.944611 
	C202.580521,362.914856 203.951126,361.386139 202.374176,359.066925 
	C200.194626,355.861481 196.679520,353.740417 193.091187,355.247711 
	C190.603119,356.292877 189.030960,359.518494 187.305023,362.585724 
z"/>
<path fill="#FDFFFD" opacity="1.000000" stroke="none" 
	d="
M353.002441,350.023102 
	C352.985443,349.857880 352.973572,349.691956 352.950592,349.527588 
	C352.632111,347.249573 352.312103,344.971802 351.992462,342.693970 
	C354.090485,343.274292 356.188507,343.854614 358.641632,344.533142 
	C361.492523,341.554688 364.056702,337.313843 370.015869,337.071991 
	C368.762878,340.167694 367.780579,342.594635 366.785614,345.052887 
	C367.762726,345.406219 368.156555,345.693573 368.339966,345.593872 
	C373.591675,342.738831 378.590240,344.357513 381.394562,349.882935 
	C382.491638,352.044464 383.609344,354.202271 384.549042,356.432800 
	C384.824615,357.086945 384.843384,358.301056 384.431519,358.722015 
	C380.949036,362.281433 383.684814,365.709808 384.110138,369.367432 
	C384.510223,372.808411 383.532623,376.624451 382.307953,379.962463 
	C381.517609,382.116547 379.189514,383.706451 376.647064,386.565033 
	C375.622040,384.306915 374.808472,382.514679 374.124084,380.062256 
	C375.156525,378.585480 376.059814,377.768860 377.161560,377.210663 
	C378.068298,377.419037 378.776520,377.369019 379.484772,377.318970 
	C378.655304,376.879333 377.825867,376.439667 377.143799,375.662598 
	C379.765686,371.534607 379.338104,368.158661 376.373962,364.682953 
	C375.553406,363.720825 376.100616,361.592224 376.402466,359.997986 
	C377.799408,359.465973 378.814148,358.934753 379.828918,358.403503 
	C378.870117,357.274689 377.911316,356.145874 376.681091,354.811523 
	C374.509918,349.686005 370.399567,347.974335 365.693176,348.134216 
	C361.446411,348.278534 357.231445,349.356750 353.002441,350.023102 
z"/>
<path fill="#C78E2A" opacity="1.000000" stroke="none" 
	d="
M251.077515,313.159515 
	C251.511261,313.163910 251.945023,313.168304 253.115341,313.129883 
	C259.257324,311.773102 264.662750,310.459076 270.068146,309.145081 
	C270.520752,309.063538 270.973389,308.981964 271.942810,308.765747 
	C272.767303,308.533356 272.935333,308.323700 272.963715,308.002136 
	C272.963715,308.002136 273.000641,308.012054 273.336517,307.988708 
	C274.800568,307.328339 275.928711,306.691284 277.056854,306.054260 
	C277.056854,306.054230 277.004852,306.000946 277.320007,306.043152 
	C278.099121,305.734741 278.563049,305.384186 279.026978,305.033600 
	C279.026978,305.033600 279.000000,305.000000 279.317902,305.024170 
	C280.113403,304.719910 280.591003,304.391510 281.068634,304.063110 
	C281.068634,304.063110 280.991516,304.017334 281.351868,304.019226 
	C282.600739,303.399445 283.489288,302.777802 284.377808,302.156128 
	C284.377808,302.156128 284.732941,302.107849 285.159058,301.922607 
	C286.667786,300.812836 287.750397,299.888336 288.832977,298.963837 
	C289.114716,298.779205 289.396454,298.594574 290.096619,298.166809 
	C290.709595,297.635071 290.904114,297.346558 291.098633,297.058014 
	C291.098633,297.058014 291.006775,297.029449 291.359009,297.021637 
	C292.759125,295.990936 293.807007,294.968048 294.854919,293.945160 
	C295.146698,293.742310 295.438477,293.539429 296.084717,293.055420 
	C296.747406,292.596558 296.891418,292.330078 296.871216,291.974823 
	C296.871216,291.974823 296.967316,291.975494 297.263123,291.827209 
	C298.298035,290.756470 299.037109,289.834015 299.776184,288.911560 
	C299.776184,288.911560 299.912048,288.937714 300.229187,288.777771 
	C301.339020,287.402008 302.131714,286.186157 302.924408,284.970306 
	C303.196228,284.757538 303.468018,284.544739 304.148438,283.992310 
	C305.029053,282.764313 305.501099,281.875977 305.973145,280.987671 
	C305.973145,280.987671 306.002808,280.999878 306.231812,280.905518 
	C306.755920,280.616638 306.932892,280.345123 306.991730,279.996613 
	C306.991730,279.996613 307.000000,280.000000 307.244873,279.943298 
	C307.575470,279.594604 307.661163,279.302643 307.793671,278.754272 
	C307.840820,278.324066 307.841125,278.150299 307.841431,277.976532 
	C307.841431,277.976532 307.984100,277.948822 308.182068,277.819977 
	C308.650940,277.521210 308.785950,277.276337 308.785065,276.956543 
	C308.785065,276.956543 308.919189,276.992767 309.227356,276.857056 
	C309.632568,276.134613 309.729614,275.547913 309.826660,274.961212 
	C309.826660,274.961212 309.949615,274.947052 310.188599,274.764038 
	C310.567200,274.041565 310.706787,273.502136 310.846375,272.962708 
	C310.846405,272.962708 310.955475,272.946991 311.233337,272.720032 
	C312.639679,269.326355 313.768127,266.159637 314.896576,262.992920 
	C314.896576,262.992920 315.124237,262.754333 315.362976,262.409180 
	C315.722137,261.704803 315.842560,261.345581 315.962982,260.986389 
	C315.962982,260.986389 315.997131,261.004852 316.236023,260.934601 
	C316.516907,260.582581 316.558929,260.300842 316.600922,260.019073 
	C316.586151,259.834778 316.571381,259.650513 316.659058,258.894653 
	C316.758423,257.885315 316.755310,257.447601 316.752228,257.009888 
	C316.901428,256.593964 317.050629,256.178070 317.451233,255.105591 
	C318.158264,251.635178 318.613922,248.821304 319.379517,246.043396 
	C320.362549,247.041550 321.035645,248.003769 321.713440,249.364578 
	C321.484833,251.843796 321.702515,254.108551 320.940979,255.973450 
	C317.450653,264.520813 313.904327,273.060638 309.921753,281.384766 
	C308.119080,285.152618 305.254120,288.412201 302.871094,291.902374 
	C302.871094,291.902374 302.943817,291.986237 302.674255,292.010773 
	C302.254730,292.349609 302.104706,292.663879 301.954681,292.978149 
	C301.954681,292.978149 302.002594,292.997131 301.742737,293.056091 
	C301.298401,293.394684 301.113953,293.674286 300.929474,293.953918 
	C300.929474,293.953918 300.993347,293.998169 300.710388,294.020752 
	C300.267456,294.353088 300.107513,294.662903 299.947571,294.972687 
	C299.947571,294.972687 300.001068,294.996735 299.737244,295.049591 
	C299.278992,295.378326 299.084534,295.654175 298.890045,295.930023 
	C298.890045,295.930023 298.973663,295.978973 298.692444,295.998901 
	C298.243622,296.327606 298.076050,296.636383 297.908447,296.945190 
	C297.908478,296.945190 297.986755,296.987183 297.708679,297.024841 
	C297.254608,297.356628 297.078613,297.650787 296.902588,297.944916 
	C296.902588,297.944916 296.983826,297.978851 296.702881,298.022278 
	C296.200043,298.343903 295.978149,298.622131 295.756226,298.900360 
	C295.451660,298.935760 295.212799,299.080017 294.626221,299.629150 
	C292.354828,301.558990 290.496918,303.192780 288.639008,304.826569 
	C288.639008,304.826569 288.303711,304.847931 287.809082,304.933533 
	C284.509766,306.648132 281.705048,308.277130 278.900330,309.906158 
	C278.900330,309.906158 279.012634,309.952026 278.596283,309.934265 
	C267.572784,314.438324 256.722076,317.841461 244.959503,316.748901 
	C244.974289,315.564087 245.008316,314.774384 245.430237,313.923767 
	C247.571243,313.628448 249.324371,313.393982 251.077515,313.159515 
z"/>
<path fill="#FDF7D8" opacity="1.000000" stroke="none" 
	d="
M263.879791,74.881783 
	C265.022827,71.060417 267.073059,71.713303 268.435852,74.409019 
	C269.506409,76.526642 269.891998,79.184624 269.911407,81.607933 
	C270.035492,97.092560 269.974060,112.578674 269.974579,129.001511 
	C269.975525,148.227814 269.975525,166.516907 269.975525,184.806000 
	C270.347107,184.857239 270.718658,184.908478 271.090240,184.959702 
	C271.729553,182.302292 272.368835,179.644867 273.117249,177.447449 
	C273.144775,186.939301 273.063202,195.971176 272.705811,204.744217 
	C272.101593,202.824356 271.773163,201.163315 271.444733,199.502289 
	C270.389832,200.151779 270.014343,200.838364 270.011749,201.526367 
	C269.969360,212.770111 270.062042,224.015411 269.883759,235.256561 
	C269.853180,237.182693 268.711975,239.091217 268.012543,240.543579 
	C267.860474,233.705612 267.779694,227.331924 267.797974,220.502106 
	C267.855957,205.041428 267.814911,190.036880 267.851562,174.707718 
	C267.895264,173.942093 267.861328,173.501068 267.884766,172.651871 
	C267.901825,171.148026 267.861481,170.052338 267.862976,168.728943 
	C267.904755,168.501205 267.819061,168.046127 267.882263,167.580688 
	C267.913055,162.391144 267.880615,157.667053 267.909790,152.483078 
	C267.983124,128.762665 268.009003,105.502098 267.988861,82.241585 
	C267.986328,79.302849 268.365448,76.029243 264.150146,75.149025 
	C264.060150,75.059807 263.879791,74.881783 263.879791,74.881783 
M272.010437,190.809174 
	C271.818787,189.669678 271.627136,188.530167 271.435455,187.390671 
	C270.935425,187.424103 270.435394,187.457535 269.935364,187.490967 
	C269.935364,190.577698 269.935364,193.664444 269.935364,196.751175 
	C270.373169,196.853348 270.810974,196.955521 271.248810,197.057693 
	C271.502838,195.250778 271.756866,193.443878 272.010437,190.809174 
z"/>
<path fill="#EBC34A" opacity="1.000000" stroke="none" 
	d="
M277.258667,129.480865 
	C277.224304,139.009094 277.133942,148.075745 276.734619,157.775848 
	C276.157928,158.955902 275.890198,159.502487 275.607147,160.080276 
	C274.933319,159.225143 274.307037,158.430313 273.521545,157.317566 
	C273.199158,155.333282 273.035950,153.666916 272.959137,151.630402 
	C272.987366,150.507416 272.929138,149.754578 272.926086,148.536102 
	C272.916962,141.712952 272.852631,135.355408 272.788330,128.997879 
	C272.859528,116.285919 272.913269,103.573807 273.019043,90.862137 
	C273.036743,88.732239 273.279877,86.604218 273.874390,84.432518 
	C275.195557,84.967247 276.061157,85.544792 276.926788,86.122345 
	C277.019775,87.460541 277.112762,88.798729 277.170227,90.937607 
	C277.207062,92.849762 277.279419,93.961243 277.351746,95.072723 
	C277.302063,106.388237 277.252380,117.703743 277.258667,129.480865 
z"/>
<path fill="#FDF7D8" opacity="1.000000" stroke="none" 
	d="
M292.060883,209.025238 
	C291.915680,219.477570 291.770477,229.929916 291.393951,240.726562 
	C290.781616,239.703415 290.069458,238.336441 290.067535,236.968445 
	C290.005219,193.069351 289.996552,149.170166 290.054871,105.271072 
	C290.057648,103.207169 289.006958,98.932602 293.442078,102.789551 
	C292.752625,104.197090 291.951019,105.175423 291.951050,106.153717 
	C291.951721,121.780586 292.054840,137.407455 292.062805,153.493530 
	C292.006958,158.963776 292.022919,163.974792 291.968384,169.240479 
	C291.935120,169.667328 291.972351,169.839508 291.950745,170.476624 
	C291.948242,183.636124 292.004547,196.330673 292.060883,209.025238 
z"/>
<path fill="#CB9632" opacity="1.000000" stroke="none" 
	d="
M321.708740,248.965973 
	C321.035645,248.003769 320.362549,247.041550 319.320068,245.577148 
	C318.938629,233.383377 318.926605,221.691818 318.914581,210.000259 
	C318.962677,207.297516 319.010742,204.594757 319.078125,201.050446 
	C319.037689,199.132965 318.977966,198.057037 318.918243,196.981125 
	C318.918243,196.981125 319.107025,197.010635 319.412598,196.797180 
	C320.749756,195.736588 321.781403,194.889465 322.813049,194.042358 
	C322.860901,194.468719 322.908752,194.895081 322.640656,195.664978 
	C322.100342,196.185394 321.875977,196.362259 321.651611,196.539124 
	C322.046906,196.706436 322.442169,196.873749 322.837463,197.041077 
	C322.837463,197.041077 323.090485,197.231873 323.079102,197.773621 
	C324.142273,199.339096 325.190491,201.209518 326.295746,201.243866 
	C331.868927,201.417007 337.457001,201.111618 343.484955,201.120056 
	C349.900604,201.206497 355.871124,201.147049 361.841644,201.087585 
	C350.285583,201.101105 338.727875,201.226074 327.174469,201.056976 
	C323.911102,201.009216 322.585968,202.074463 322.965729,205.991852 
	C323.048462,206.886887 323.104553,207.050201 323.105652,207.544983 
	C323.101532,208.761795 323.152374,209.647171 323.203217,210.532547 
	C323.149902,212.103821 323.096588,213.675110 323.025574,215.884537 
	C323.309235,217.659317 323.610565,218.795929 323.911896,219.932541 
	C323.911896,219.932541 323.874847,220.063889 323.581543,220.195343 
	C323.242279,220.887573 323.196350,221.448364 323.150391,222.009155 
	C323.109741,222.761185 323.069061,223.513199 323.020630,225.098770 
	C322.578186,233.610199 322.143463,241.288086 321.708740,248.965973 
z"/>
<path fill="#EBC34A" opacity="1.000000" stroke="none" 
	d="
M245.042358,313.984680 
	C245.008316,314.774384 244.974289,315.564087 244.662552,316.892822 
	C241.571884,317.350281 238.758911,317.268646 235.945953,317.187042 
	C235.216354,317.108490 234.486755,317.029938 233.121796,316.689514 
	C227.979752,315.319977 223.473053,314.212311 218.966354,313.104675 
	C218.627045,312.984589 218.287750,312.864502 217.436035,312.468750 
	C212.264099,309.845123 207.604568,307.497192 202.945038,305.149261 
	C202.499344,304.820526 202.053650,304.491791 201.255859,303.814331 
	C200.604782,303.305756 200.305786,303.145935 200.006790,302.986145 
	C199.551819,302.638184 199.096863,302.290253 198.221756,301.613434 
	C196.860092,300.537231 195.918549,299.789886 194.977005,299.042572 
	C194.442307,298.467072 193.907593,297.891602 193.035339,296.924988 
	C192.379074,296.065552 192.060394,295.597229 192.211105,295.027374 
	C192.992203,294.852692 193.303894,294.779541 193.572449,294.762421 
	C193.529312,294.818420 193.444504,294.931519 193.742294,294.892822 
	C194.333344,294.598572 194.626602,294.343018 195.255890,294.146179 
	C201.416168,298.018860 207.240417,301.832825 213.064682,305.646790 
	C213.393570,305.805725 213.722458,305.964661 214.498306,306.372314 
	C215.296814,306.729675 215.648346,306.838318 215.999893,306.946960 
	C215.999893,306.946960 216.368744,307.112701 216.797638,307.369995 
	C218.489044,308.057892 219.751541,308.488525 221.014038,308.919159 
	C221.338394,309.033936 221.662750,309.148743 222.438248,309.459503 
	C223.258362,309.748688 223.627335,309.841888 223.996307,309.935089 
	C223.996307,309.935089 224.376495,310.088196 224.717468,310.291138 
	C225.058441,310.494049 225.487564,310.714813 225.487564,310.714813 
	C225.487564,310.714813 225.968964,310.681946 225.968964,310.681946 
	C226.708908,310.828369 227.448853,310.974792 228.760376,311.346222 
	C229.898880,311.633026 230.465805,311.694885 231.032715,311.756714 
	C231.450882,311.880524 231.869064,312.004333 232.846115,312.375702 
	C234.263214,312.662933 235.121429,312.702576 235.979660,312.742218 
	C237.681946,312.878052 239.384247,313.013855 241.762009,313.358734 
	C243.305786,313.706757 244.174072,313.845703 245.042358,313.984680 
z"/>
<path fill="#DCB73C" opacity="1.000000" stroke="none" 
	d="
M276.244873,352.965485 
	C276.630188,352.375305 277.015533,351.785126 278.091675,351.057068 
	C280.145081,351.002533 281.507690,351.085907 282.910828,351.110260 
	C282.951355,351.051239 282.829803,351.126892 282.856689,351.584320 
	C283.016449,360.372040 283.149323,368.702301 283.282227,377.032593 
	C283.144379,377.439514 283.006531,377.846466 282.421021,378.604431 
	C280.313812,378.976776 278.654205,378.998077 276.994629,379.019379 
	C276.994629,379.019379 277.001129,378.997986 276.963867,378.645020 
	C276.907166,377.581482 276.887756,376.870880 277.230316,376.137329 
	C280.455261,377.044617 281.060791,375.505585 281.040161,373.076996 
	C280.990845,367.272003 281.051575,361.465942 280.968292,355.661713 
	C280.957825,354.933472 280.347931,354.213867 279.857513,353.145508 
	C278.799774,361.195587 277.818909,368.660767 276.516388,376.142151 
	C275.102814,377.081055 274.010864,378.003723 272.918945,378.926422 
	C272.918945,378.926422 273.012268,378.961395 272.572021,378.950623 
	C268.117340,379.971191 264.075439,381.180267 260.913177,377.109283 
	C260.992157,377.020966 261.152832,376.846863 261.547394,376.911407 
	C264.735962,377.309845 267.529968,377.643738 270.323975,377.977631 
	C270.351135,377.554199 270.378265,377.130737 270.405426,376.707306 
	C262.201111,374.601715 261.142120,373.013977 260.979919,363.959259 
	C260.903687,359.704224 260.322388,355.458191 260.305115,351.125153 
	C261.097778,350.995514 261.555267,350.948822 262.012756,350.902130 
	C262.012756,350.902130 262.329224,351.099854 262.570557,351.572083 
	C262.869965,356.790009 262.620361,361.568176 263.116272,366.267670 
	C263.363007,368.605774 264.976593,370.799622 265.971832,373.058716 
	C267.071503,373.416382 268.171143,373.774017 269.995117,374.127014 
	C272.463776,372.410461 274.208069,370.698547 275.952362,368.986633 
	C276.060150,367.292023 276.167969,365.597412 276.426880,363.138428 
	C276.466949,359.237854 276.355896,356.101654 276.244873,352.965485 
z"/>
<path fill="#FEFCE6" opacity="1.000000" stroke="none" 
	d="
M307.986084,379.068970 
	C309.792969,378.937347 311.599792,378.805725 314.688843,378.580688 
	C311.477417,374.891785 309.123688,371.553192 306.144104,368.923828 
	C302.285645,365.518829 302.707031,362.765717 306.181213,359.560394 
	C308.686920,357.248596 310.938232,354.661072 313.600922,351.886047 
	C309.269135,349.811584 306.636230,351.180237 304.336609,353.997894 
	C302.263397,356.538208 300.118958,359.020355 298.005890,361.528107 
	C297.348297,361.211609 296.690735,360.895081 296.033142,360.578583 
	C296.033142,353.369263 296.033142,346.159912 296.003815,338.484070 
	C296.828491,339.983185 298.280334,341.910858 298.408112,343.922638 
	C298.650635,347.741241 298.134766,351.608032 297.933929,355.454803 
	C298.438721,355.655762 298.943512,355.856689 299.448303,356.057648 
	C300.945007,354.272644 302.441742,352.487610 303.976868,350.354340 
	C305.278107,349.701630 306.700806,349.670898 307.774872,349.043396 
	C311.586395,346.816437 313.486084,349.454010 315.651306,351.715424 
	C312.468262,356.238678 309.366730,360.646118 306.924316,364.116943 
	C308.253571,365.870453 309.158539,367.019470 310.015137,368.203491 
	C311.816254,370.693054 313.592010,373.201019 315.683075,375.852173 
	C316.973419,379.475006 316.359558,382.479218 314.038940,384.697510 
	C312.041901,383.283539 310.422089,382.136658 308.401031,380.666046 
	C307.995209,379.917877 307.990662,379.493408 307.986084,379.068970 
z"/>
<path fill="#FDF7D8" opacity="1.000000" stroke="none" 
	d="
M376.020264,359.998779 
	C376.100616,361.592224 375.553406,363.720825 376.373962,364.682953 
	C379.338104,368.158661 379.765686,371.534607 377.134949,375.900635 
	C376.978699,376.476105 376.963104,376.952271 376.963074,376.952271 
	C376.059814,377.768860 375.156525,378.585480 374.119568,379.676819 
	C372.852051,380.535004 371.718231,381.118439 369.947998,381.697021 
	C367.781494,381.662140 366.220428,381.809174 364.730560,381.548859 
	C363.429260,381.321442 362.208130,380.635437 361.382874,380.095947 
	C364.250336,379.719757 366.686432,379.399689 369.343109,379.031525 
	C369.563660,378.983398 369.976379,378.800293 370.294434,378.842072 
	C371.447113,378.300507 372.281769,377.717163 373.062683,377.070862 
	C373.008972,377.007874 373.097046,377.147949 373.376312,377.109436 
	C373.996765,376.687317 374.337952,376.303772 374.815857,375.690918 
	C375.049103,375.121521 375.145721,374.781403 375.464355,374.253784 
	C375.815613,373.709381 375.944855,373.352539 376.161377,372.600616 
	C376.185699,371.136017 376.122681,370.066498 376.058716,368.686768 
	C375.726227,367.880890 375.394684,367.385223 374.928772,366.589813 
	C373.525146,365.541565 372.255890,364.793091 370.799866,363.832397 
	C370.070496,363.553040 369.527863,363.485901 368.664520,363.295227 
	C367.888519,363.147583 367.433167,363.123474 366.635010,362.937042 
	C364.529694,362.505859 362.767242,362.237000 360.814819,361.750488 
	C360.081360,361.358551 359.537781,361.184265 358.822784,360.670532 
	C358.522034,358.616730 358.392700,356.902374 358.253723,355.227783 
	C358.244080,355.267578 358.325714,355.274170 358.692688,355.212952 
	C362.662506,353.737091 366.459137,353.104462 368.932770,356.676971 
	C370.923370,359.551910 373.089844,359.401031 375.846985,358.998901 
	C375.956635,359.551880 375.988434,359.775330 376.020264,359.998779 
z"/>
<path fill="#FDF7D8" opacity="1.000000" stroke="none" 
	d="
M263.887360,160.641312 
	C262.852325,160.707993 261.817291,160.774673 260.403320,160.745605 
	C260.689240,158.767822 261.922913,156.888763 261.932861,155.003265 
	C262.063141,130.246460 261.997009,105.488655 262.069611,80.731361 
	C262.075043,78.876923 262.762970,77.024490 263.506714,75.026451 
	C263.879791,74.881783 264.060150,75.059807 264.117859,75.595039 
	C264.197083,96.736237 264.218597,117.342201 264.169556,138.389450 
	C264.138184,140.913818 264.177399,142.996902 264.144470,145.502319 
	C264.115173,147.922989 264.158020,149.921310 264.136993,152.343185 
	C264.113861,154.190369 264.154633,155.614014 264.153320,157.268585 
	C264.111206,157.499527 264.221436,157.955872 264.156494,158.288330 
	C264.023468,159.294296 263.955414,159.967804 263.887360,160.641312 
z"/>
<path fill="#FEFCE6" opacity="1.000000" stroke="none" 
	d="
M289.544525,339.085541 
	C289.954193,350.800140 289.975983,362.600739 290.025238,374.401276 
	C290.034027,376.513641 289.553558,378.862671 293.018402,378.903473 
	C296.405029,378.943390 296.170319,376.751373 296.162781,374.539642 
	C296.155579,372.421631 296.161224,370.303558 296.161224,368.185486 
	C296.584473,367.971924 297.007690,367.758331 297.430939,367.544769 
	C300.623596,371.107117 303.816254,374.669495 306.750000,378.588379 
	C302.372772,376.457001 298.363800,374.531403 295.984772,380.987885 
	C289.851715,380.897552 289.851715,380.897552 285.171509,384.693542 
	C286.105560,379.804047 287.051483,374.852478 287.998749,368.981903 
	C287.998413,361.041718 287.996765,354.020508 287.995117,346.999329 
	C288.385406,344.390076 288.775696,341.780792 289.544525,339.085541 
z"/>
<path fill="#EBC452" opacity="1.000000" stroke="none" 
	d="
M301.083374,146.060089 
	C301.001709,143.983017 300.920044,141.905945 300.748657,139.391602 
	C300.694427,138.202591 300.729919,137.450836 300.802307,135.923035 
	C300.782501,133.780106 300.725861,132.413193 300.669189,131.046295 
	C300.706329,127.322716 300.743500,123.599136 300.817200,119.010002 
	C300.970825,116.029114 301.087830,113.913788 301.234344,111.264763 
	C302.917084,112.515442 303.936066,113.272781 304.955017,114.030121 
	C304.847260,129.308884 304.739502,144.587646 304.131500,160.360565 
	C303.095917,160.872864 302.560577,160.890991 302.034180,160.454376 
	C301.723236,155.353119 301.403290,150.706604 301.083374,146.060089 
z"/>
<path fill="#FDFFFD" opacity="1.000000" stroke="none" 
	d="
M272.437988,128.998138 
	C272.852631,135.355408 272.916962,141.712952 272.700256,148.728958 
	C272.570435,150.258484 272.721558,151.129517 272.872681,152.000549 
	C273.035950,153.666916 273.199158,155.333282 273.445435,157.513153 
	C273.528503,158.026657 273.600403,158.436050 273.411072,158.553528 
	C273.221771,158.671005 272.873230,158.951981 272.637054,159.190948 
	C272.607422,160.627762 272.813965,161.825607 273.090424,163.415802 
	C273.105377,164.866028 273.050415,165.923889 272.709717,167.249146 
	C272.614227,170.015457 272.804474,172.514343 273.058594,175.339996 
	C273.084351,176.106979 273.046234,176.547211 273.008118,176.987457 
	C272.368835,179.644867 271.729553,182.302292 271.090240,184.959702 
	C270.718658,184.908478 270.347107,184.857239 269.975525,184.806000 
	C269.975525,166.516907 269.975525,148.227814 269.977112,129.470276 
	C270.681671,129.000687 271.384674,128.999542 272.437988,128.998138 
z"/>
<path fill="#FDF7D8" opacity="1.000000" stroke="none" 
	d="
M321.713440,249.364578 
	C322.143463,241.288086 322.578186,233.610199 323.089386,225.470154 
	C323.355560,225.275742 323.677155,225.525650 323.716095,225.813797 
	C326.885345,249.257462 321.734467,270.537445 307.053558,289.282684 
	C306.186920,290.389252 304.755402,291.053436 303.228943,291.912903 
	C305.254120,288.412201 308.119080,285.152618 309.921753,281.384766 
	C313.904327,273.060638 317.450653,264.520813 320.940979,255.973450 
	C321.702515,254.108551 321.484833,251.843796 321.713440,249.364578 
z"/>
<path fill="#FEFCE6" opacity="1.000000" stroke="none" 
	d="
M261.929626,350.539429 
	C261.555267,350.948822 261.097778,350.995514 259.985168,351.058624 
	C258.900391,351.127014 258.470734,351.178986 257.675018,351.289490 
	C256.927582,355.575012 256.546204,359.802002 256.164795,364.028961 
	C253.760727,363.751495 252.537384,364.326080 251.667755,367.120941 
	C249.115021,375.325104 242.689789,379.916260 235.210449,379.252106 
	C241.479843,376.697571 247.641983,373.890656 249.894485,366.626312 
	C252.237289,359.070648 251.427795,351.969757 245.865936,345.497986 
	C248.308640,347.056824 250.565933,349.694580 253.388824,350.635559 
	C255.880249,351.466034 259.002686,350.403442 261.929626,350.539429 
z"/>
<path fill="#E1B837" opacity="1.000000" stroke="none" 
	d="
M292.134583,153.034332 
	C292.054840,137.407455 291.951721,121.780586 291.951050,106.153717 
	C291.951019,105.175423 292.752625,104.197090 293.581970,103.133087 
	C294.057739,103.221664 294.132812,103.395912 294.323151,103.977463 
	C294.558472,104.680328 294.771790,104.783211 295.078400,104.693428 
	C295.190521,107.141525 295.302643,109.589630 295.372711,112.913254 
	C295.452332,118.893745 295.574005,123.998726 295.695679,129.103699 
	C295.589478,134.087967 295.483307,139.072235 295.444977,144.517868 
	C295.384155,147.364304 295.255463,149.749374 294.686157,152.571594 
	C293.541901,153.017288 292.838226,153.025818 292.134583,153.034332 
z"/>
<path fill="#E1B837" opacity="1.000000" stroke="none" 
	d="
M318.851471,161.999725 
	C318.921600,151.662460 318.991760,141.325211 319.364990,130.503906 
	C320.433075,130.369019 321.198090,130.718185 321.963074,131.067352 
	C322.015320,131.392548 322.174408,131.652313 322.664795,132.420288 
	C322.978394,142.263687 323.067474,151.533478 322.818573,160.917145 
	C322.377899,161.995132 322.275208,162.959229 322.161194,164.064423 
	C322.204651,164.384048 322.287079,164.548767 322.397125,164.699661 
	C322.502319,164.915543 322.607544,165.131424 322.481079,165.862976 
	C321.256866,167.254242 320.264374,168.129868 319.271881,169.005508 
	C319.175262,167.287979 319.078674,165.570465 318.981812,163.176468 
	C318.938171,162.333237 318.894806,162.166489 318.851471,161.999725 
z"/>
<path fill="#D7A026" opacity="1.000000" stroke="none" 
	d="
M302.025238,160.909119 
	C302.560577,160.890991 303.095917,160.872864 304.032410,160.799622 
	C304.575409,170.517685 304.717285,180.290863 304.831482,190.878998 
	C304.721252,192.421738 304.638672,193.149551 304.197937,194.028290 
	C303.565399,201.433640 303.291046,208.688065 303.029602,216.215729 
	C303.476624,216.975449 303.910797,217.461929 304.483704,218.344788 
	C304.536407,219.843948 304.450378,220.946762 304.102600,222.126465 
	C303.225891,222.199219 302.074707,222.223251 302.072784,222.186890 
	C301.981445,220.449600 301.989594,218.707077 301.987701,216.500076 
	C302.001038,197.659714 302.013153,179.284409 302.025238,160.909119 
z"/>
<path fill="#DDB657" opacity="1.000000" stroke="none" 
	d="
M383.258362,188.439865 
	C389.178955,188.175064 395.100769,187.766098 401.018738,187.815582 
	C401.819489,187.822250 402.601013,190.129868 403.391418,191.372070 
	C402.251617,192.009506 401.103607,193.210251 399.973389,193.193695 
	C392.165955,193.079361 384.362244,192.711288 376.487549,192.240112 
	C379.603485,192.055466 382.789246,192.055466 385.975006,192.055466 
	C386.268463,191.620331 386.561951,191.185196 386.855408,190.750076 
	C385.656372,189.980011 384.457367,189.209946 383.258362,188.439865 
z"/>
<path fill="#E5BD52" opacity="1.000000" stroke="none" 
	d="
M164.617676,342.352997 
	C160.703644,342.034515 156.790573,341.703522 152.875183,341.402771 
	C150.895721,341.250702 148.902527,340.969269 146.932449,341.071747 
	C143.476303,341.251465 141.705246,342.719025 141.862579,346.714630 
	C142.189743,355.022736 142.267944,363.366455 141.870041,371.667816 
	C141.621704,376.849060 143.507584,378.672211 148.445816,378.071045 
	C149.732376,377.914398 151.077499,378.238647 152.267975,378.687103 
	C148.150970,378.895203 144.161682,378.758209 140.172409,378.621216 
	C140.172409,378.621246 140.165802,378.291656 140.130890,377.680176 
	C140.040024,369.374512 139.984055,361.680389 139.928101,353.986237 
	C139.965164,353.241852 140.002228,352.497437 140.056488,350.934174 
	C140.119659,346.798798 140.165634,343.482330 140.211609,340.165833 
	C140.211609,340.165833 140.082428,340.058228 140.575348,340.083496 
	C147.052109,340.039642 153.035950,339.970490 159.019806,339.901367 
	C159.442291,339.980743 159.864777,340.060089 160.817230,340.386108 
	C162.437363,341.206207 163.527512,341.779602 164.617676,342.352997 
z"/>
<path fill="#CF957D" opacity="1.000000" stroke="none" 
	d="
M369.063538,206.918243 
	C376.805695,206.641357 384.614716,205.523911 392.165375,208.715286 
	C385.080780,213.461884 377.235718,209.518143 369.441528,210.540741 
	C369.000519,209.080795 369.032013,207.999527 369.063538,206.918243 
z"/>
<path fill="#D9A745" opacity="1.000000" stroke="none" 
	d="
M319.152557,169.251022 
	C320.264374,168.129868 321.256866,167.254242 322.503174,166.222534 
	C322.850067,167.115112 322.943207,168.163803 323.054932,169.974670 
	C323.119324,171.499268 323.165131,172.261688 323.152039,173.269608 
	C323.102509,173.683151 323.111847,173.851212 323.121216,174.019257 
	C323.138367,175.496368 323.155487,176.973480 323.183807,179.047333 
	C323.270599,180.717377 323.346222,181.790695 323.421875,182.864014 
	C323.298889,184.369156 323.175903,185.874283 323.084229,188.077316 
	C323.245392,189.849808 323.375214,190.924393 323.505066,191.998993 
	C323.335114,192.508224 323.165161,193.017441 322.904114,193.784515 
	C321.781403,194.889465 320.749756,195.736588 319.507568,196.807526 
	C319.189911,189.994537 319.082825,182.957718 319.001862,175.046555 
	C318.967957,172.784836 318.907959,171.397461 318.847961,170.010101 
	C318.909729,169.838898 318.971497,169.667709 319.152557,169.251022 
z"/>
<path fill="#CF957D" opacity="1.000000" stroke="none" 
	d="
M382.782990,188.320984 
	C384.457367,189.209946 385.656372,189.980011 386.855408,190.750076 
	C386.561951,191.185196 386.268463,191.620331 385.975006,192.055466 
	C382.789246,192.055466 379.603485,192.055466 376.108704,192.097366 
	C360.597534,192.200546 345.395355,192.261810 330.261963,192.136749 
	C334.250641,191.878998 338.170563,191.807587 342.530365,191.814117 
	C346.271698,191.873444 349.573151,191.854843 353.345764,191.900665 
	C357.797821,191.979095 361.783844,191.874847 365.757874,192.047195 
	C368.564209,192.168900 369.207977,190.740494 369.009979,188.383163 
	C373.442535,188.322800 377.875092,188.262436 382.782990,188.320984 
z"/>
<path fill="#FDFFFD" opacity="1.000000" stroke="none" 
	d="
M360.951569,380.152039 
	C362.208130,380.635437 363.429260,381.321442 364.730560,381.548859 
	C366.220428,381.809174 367.781494,381.662140 369.655823,381.842407 
	C370.209717,386.348480 367.270508,386.339417 364.339630,385.833710 
	C361.335785,385.315430 358.428314,383.950073 355.428680,383.764740 
	C351.160004,383.501007 348.275299,381.167725 345.322021,377.991333 
	C345.833221,376.624573 346.418427,375.812897 347.330444,375.046570 
	C349.422913,375.731659 351.188629,376.371429 353.182312,377.042206 
	C353.410248,377.073242 353.860229,377.162018 353.995972,377.353760 
	C354.339691,377.787628 354.588654,377.975372 354.958496,378.446045 
	C356.000885,380.691650 357.125153,381.949188 359.413330,379.816895 
	C360.136353,379.945099 360.543945,380.048553 360.951569,380.152039 
z"/>
<path fill="#FDFFFD" opacity="1.000000" stroke="none" 
	d="
M287.626465,346.960022 
	C287.996765,354.020508 287.998413,361.041718 287.996185,368.531494 
	C286.903168,368.689423 285.813995,368.378784 284.371399,367.569122 
	C284.203613,362.307800 284.447510,357.546021 284.495392,352.782318 
	C284.500885,352.236145 283.411530,351.678955 282.829803,351.126892 
	C282.829803,351.126892 282.951355,351.051239 282.924835,350.770447 
	C282.265564,349.991455 281.632782,349.493286 281.000000,348.995117 
	C281.142456,347.769989 281.284912,346.544861 281.490997,344.772552 
	C283.730011,345.606567 285.493927,346.263641 287.626465,346.960022 
z"/>
<path fill="#C88CAD" opacity="1.000000" stroke="none" 
	d="
M368.619995,206.744965 
	C369.032013,207.999527 369.000519,209.080795 368.978821,210.490601 
	C365.290771,210.734070 361.592896,210.649017 357.470978,209.979691 
	C355.777863,208.654709 354.560608,207.415787 353.228912,207.277390 
	C350.489258,206.992661 347.688416,207.296371 344.453339,207.306656 
	C337.049500,207.241440 330.105103,207.227478 323.160675,207.213531 
	C323.104553,207.050201 323.048462,206.886887 323.000610,206.466309 
	C338.064728,206.329910 353.120575,206.450790 368.619995,206.744965 
z"/>
<path fill="#882CB1" opacity="1.000000" stroke="none" 
	d="
M343.039825,200.974152 
	C337.457001,201.111618 331.868927,201.417007 326.295746,201.243866 
	C325.190491,201.209518 324.142273,199.339096 323.128967,197.924026 
	C332.508057,197.598663 341.825928,197.664642 351.232056,197.807205 
	C348.031494,197.497421 345.005524,197.815369 343.039825,200.974152 
z"/>
<path fill="#A34C9A" opacity="1.000000" stroke="none" 
	d="
M359.809235,173.422577 
	C353.152618,173.483261 346.495972,173.543945 339.387817,173.153015 
	C338.297577,171.843735 337.658844,170.986084 337.020081,170.128448 
	C345.669067,170.107864 354.318024,170.087280 363.411621,170.425385 
	C362.507202,171.663605 361.158234,172.543091 359.809235,173.422577 
z"/>
<path fill="#882CB1" opacity="1.000000" stroke="none" 
	d="
M323.935577,182.875687 
	C323.346222,181.790695 323.270599,180.717377 323.308655,179.394836 
	C331.273560,179.178757 339.124725,179.211914 347.447937,179.724487 
	C347.991089,181.002335 348.062164,181.800735 348.133240,182.599152 
	C340.238586,182.695221 332.343933,182.791290 323.935577,182.875687 
z"/>
<path fill="#FDF7D8" opacity="1.000000" stroke="none" 
	d="
M236.011353,317.537170 
	C238.758911,317.268646 241.571884,317.350281 244.681793,317.287964 
	C256.722076,317.841461 267.572784,314.438324 278.548035,309.897369 
	C278.418091,310.656586 278.081696,311.903534 277.396667,312.139801 
	C271.466217,314.185150 265.567932,316.465302 259.472717,317.852142 
	C253.063354,319.310486 246.463730,320.029724 239.907486,320.655457 
	C238.729370,320.767883 237.358582,318.861511 236.011353,317.537170 
z"/>
<path fill="#882CB1" opacity="1.000000" stroke="none" 
	d="
M324.383911,219.996490 
	C323.610565,218.795929 323.309235,217.659317 323.148376,216.255463 
	C330.198303,216.026413 337.107727,216.064606 344.508240,216.460266 
	C345.292694,217.704941 345.586090,218.592163 345.879517,219.479401 
	C338.871643,219.673080 331.863770,219.866760 324.383911,219.996490 
z"/>
<path fill="#FDF7D8" opacity="1.000000" stroke="none" 
	d="
M352.763641,350.024475 
	C357.231445,349.356750 361.446411,348.278534 365.693176,348.134216 
	C370.399567,347.974335 374.509918,349.686005 376.663208,355.122986 
	C376.553223,356.100281 376.189636,356.560638 375.578247,356.803986 
	C370.969574,349.161591 362.349274,347.509216 355.115906,352.833435 
	C353.835236,355.593597 352.902893,358.299347 351.654297,360.836365 
	C350.897919,359.112244 350.457794,357.556824 350.017700,356.001373 
	C350.593414,354.222137 351.169159,352.442871 351.896057,350.345093 
	C352.047211,350.026520 352.524841,350.025848 352.763641,350.024475 
z"/>
<path fill="#FDFFFD" opacity="1.000000" stroke="none" 
	d="
M307.331970,257.125885 
	C307.262512,257.549561 307.193054,257.973267 306.777771,258.889404 
	C306.359528,259.764679 306.287048,260.147522 306.214600,260.530334 
	C306.214600,260.530334 306.304504,260.772980 305.926208,261.057312 
	C305.410553,261.997620 305.273193,262.653656 305.135834,263.309662 
	C305.135834,263.309662 305.142853,263.675537 304.799988,264.007996 
	C304.320404,264.911377 304.183655,265.482239 304.046906,266.053131 
	C304.046906,266.053131 303.998749,266.007935 303.760803,266.169189 
	C303.369690,266.908356 303.216553,267.486206 303.063416,268.064087 
	C303.063416,268.064087 303.020966,267.990387 302.675964,268.067261 
	C301.911926,269.480560 301.492889,270.817017 301.073853,272.153473 
	C300.784088,272.630402 300.494324,273.107330 299.805481,273.916351 
	C299.163605,274.485901 299.041931,274.774445 299.041412,275.114105 
	C299.034760,275.425690 298.920288,275.690735 298.096313,275.980896 
	C296.670532,276.042511 295.846405,276.032410 295.012085,276.012329 
	C295.001892,276.002380 294.979492,275.984650 294.982819,275.595886 
	C294.949615,274.429688 294.913055,273.652222 294.901855,272.945984 
	C294.927185,273.017242 294.854187,272.883392 295.226501,272.874268 
	C295.986145,271.839142 296.373444,270.813171 296.751068,269.797852 
	C296.741394,269.808533 296.738068,269.770081 297.107697,269.801575 
	C299.598938,265.871979 301.818024,261.957336 303.748413,257.905151 
	C304.078430,257.212341 303.175079,255.931992 302.832428,254.598587 
	C302.939667,253.859161 303.056000,253.444214 303.501068,252.959351 
	C304.221130,251.926086 304.612396,250.962738 305.003662,249.999390 
	C305.451355,249.999863 305.899048,250.000320 306.674103,250.393463 
	C307.111633,252.899399 307.221802,255.012650 307.331970,257.125885 
z"/>
<path fill="#CF957D" opacity="1.000000" stroke="none" 
	d="
M369.009583,179.194244 
	C374.458252,179.125107 379.909821,178.942886 385.353119,179.082428 
	C386.438171,179.110245 387.491730,180.367828 388.559814,181.058319 
	C387.474213,181.732178 386.408936,182.945831 385.299591,182.987534 
	C380.185822,183.179840 375.059784,183.045532 369.471375,182.597809 
	C369.006287,181.176285 369.007935,180.185272 369.009583,179.194244 
z"/>
<path fill="#C3898E" opacity="1.000000" stroke="none" 
	d="
M359.915558,216.233643 
	C365.547363,216.164886 371.181244,216.000565 376.809021,216.120331 
	C377.760925,216.140579 378.686798,217.384125 379.624725,218.062500 
	C378.650208,218.676910 377.692047,219.789139 376.698456,219.821777 
	C371.730377,219.984818 366.753052,219.864746 361.305725,219.428329 
	C360.526825,218.086166 360.221191,217.159897 359.915558,216.233643 
z"/>
<path fill="#FDFFFD" opacity="1.000000" stroke="none" 
	d="
M296.299713,381.144470 
	C298.363800,374.531403 302.372772,376.457001 306.880676,378.900757 
	C307.270325,378.856659 307.654541,378.874054 307.820312,378.971497 
	C307.990662,379.493408 307.995209,379.917877 308.004150,380.660339 
	C307.083313,384.900055 303.422821,386.453491 299.961151,384.263824 
	C298.718231,383.477661 297.722931,382.300018 296.299713,381.144470 
z"/>
<path fill="#882CB1" opacity="1.000000" stroke="none" 
	d="
M342.090454,191.736176 
	C338.170563,191.807587 334.250641,191.878998 329.932678,192.005646 
	C327.857941,192.054260 326.181274,192.047653 324.004822,192.020020 
	C323.375214,190.924393 323.245392,189.849808 323.190063,188.444794 
	C330.206665,188.186722 337.148804,188.259048 344.499146,188.742432 
	C343.968353,190.014389 343.029419,190.875275 342.090454,191.736176 
z"/>
<path fill="#882CB1" opacity="1.000000" stroke="none" 
	d="
M323.105652,207.544983 
	C330.105103,207.227478 337.049500,207.241440 344.473389,207.605255 
	C344.952087,208.559692 344.951355,209.164291 344.490265,209.814850 
	C339.116394,210.048477 334.202942,210.236099 329.289459,210.423721 
	C327.547913,210.468857 325.806366,210.513992 323.634033,210.545837 
	C323.152374,209.647171 323.101532,208.761795 323.105652,207.544983 
z"/>
<path fill="#A34C9A" opacity="1.000000" stroke="none" 
	d="
M343.484955,201.120056 
	C345.005524,197.815369 348.031494,197.497421 351.581970,197.937988 
	C355.124420,197.866043 358.411560,197.556915 361.683868,197.671616 
	C364.301666,197.763367 364.423340,198.420135 362.280609,201.056534 
	C355.871124,201.147049 349.900604,201.206497 343.484955,201.120056 
z"/>
<path fill="#FEFCE6" opacity="1.000000" stroke="none" 
	d="
M347.003662,375.001221 
	C346.418427,375.812897 345.833221,376.624573 345.121490,377.709717 
	C341.033295,380.817017 336.901489,382.565186 331.738739,382.128265 
	C327.783325,381.793579 323.726166,382.661224 319.538055,382.073395 
	C321.052399,381.180817 322.566742,380.288239 324.258667,379.009094 
	C328.371979,378.991058 332.598572,380.415497 336.162537,379.434021 
	C339.797028,378.433075 342.780121,375.067078 346.412048,372.669922 
	C346.851685,373.402588 346.927673,374.201904 347.003662,375.001221 
z"/>
<path fill="#D6A736" opacity="1.000000" stroke="none" 
	d="
M193.615570,294.706421 
	C193.303894,294.779541 192.992203,294.852692 191.953552,294.891754 
	C190.467712,294.266022 189.708786,293.674438 188.949860,293.082855 
	C188.949860,293.082855 188.991562,293.009491 188.848755,292.728455 
	C187.466446,291.304382 186.226944,290.161285 184.987457,289.018219 
	C183.878555,287.445526 182.769653,285.872864 181.355804,283.804932 
	C180.409271,282.498230 179.767715,281.686768 179.126160,280.875275 
	C179.126160,280.875275 179.098907,281.013367 179.108765,280.642029 
	C178.993317,279.881683 178.868011,279.492676 178.742706,279.103638 
	C179.447250,278.521484 180.151794,277.939331 181.276505,277.245758 
	C183.637573,279.977539 185.585281,282.816132 187.517532,285.665222 
	C189.557922,288.673737 191.583527,291.692261 193.615570,294.706421 
z"/>
<path fill="#FEF9E0" opacity="1.000000" stroke="none" 
	d="
M323.156586,160.803253 
	C323.067474,151.533478 322.978394,142.263687 322.870453,132.530487 
	C323.558472,134.161774 324.265320,136.256470 325.087402,138.692688 
	C325.951599,138.428619 327.212891,138.043228 328.668823,137.598358 
	C328.963837,140.152145 330.084320,142.763641 325.959869,143.154099 
	C325.252960,143.221008 324.165833,144.630676 324.124939,145.462845 
	C323.929718,149.434540 323.861969,153.434006 324.160675,157.392548 
	C324.230896,158.323441 325.808716,159.727875 326.848297,159.884628 
	C329.609711,160.301071 332.455902,160.155228 335.168579,160.540924 
	C331.098022,160.833633 327.127319,160.818436 323.156586,160.803253 
z"/>
<path fill="#A34C9A" opacity="1.000000" stroke="none" 
	d="
M322.917480,164.671829 
	C322.287079,164.548767 322.204651,164.384048 322.612793,164.048157 
	C328.410034,163.771027 333.744354,163.651260 339.078674,163.531494 
	C339.049561,163.146637 339.020447,162.761765 338.991333,162.376907 
	C338.342743,161.933777 337.694153,161.490646 337.045532,161.047516 
	C340.664673,160.985382 344.288818,160.798370 347.899658,160.943008 
	C348.885223,160.982468 349.830841,162.018951 350.794769,162.597885 
	C349.748474,163.263275 348.714355,164.482574 347.653931,164.505905 
	C339.584778,164.683502 331.510498,164.627350 322.917480,164.671829 
z"/>
<path fill="#FEFCE6" opacity="1.000000" stroke="none" 
	d="
M245.870239,344.821411 
	C237.042786,338.913483 226.979065,339.822021 216.562836,340.172058 
	C216.766571,339.251373 217.513351,337.401672 218.077988,337.455719 
	C225.715775,338.186615 233.369400,338.948669 240.917603,340.278961 
	C242.765060,340.604553 244.247025,343.004028 245.870239,344.821411 
z"/>
<path fill="#C3898E" opacity="1.000000" stroke="none" 
	d="
M368.550232,188.286072 
	C369.207977,190.740494 368.564209,192.168900 365.757874,192.047195 
	C361.783844,191.874847 357.797821,191.979095 353.418823,191.565430 
	C353.005463,190.251114 352.990204,189.336472 352.974915,188.421844 
	C358.013458,188.344223 363.051971,188.266586 368.550232,188.286072 
z"/>
<path fill="#882CB1" opacity="1.000000" stroke="none" 
	d="
M336.558533,170.061035 
	C337.658844,170.986084 338.297577,171.843735 338.950378,172.978180 
	C334.588745,173.378922 330.213013,173.502869 325.205200,173.422501 
	C324.119049,173.153488 323.664978,173.088791 323.210938,173.024109 
	C323.165131,172.261688 323.119324,171.499268 323.152527,170.360947 
	C327.519989,169.987915 331.808502,169.990768 336.558533,170.061035 
z"/>
<path fill="#A34C9A" opacity="1.000000" stroke="none" 
	d="
M359.458496,216.182831 
	C360.221191,217.159897 360.526825,218.086166 360.843231,219.304810 
	C356.169769,219.645203 351.485535,219.693222 346.340393,219.610306 
	C345.586090,218.592163 345.292694,217.704941 344.967316,216.533447 
	C349.624054,216.210129 354.312744,216.171082 359.458496,216.182831 
z"/>
<path fill="#FEF9E0" opacity="1.000000" stroke="none" 
	d="
M304.364319,222.049561 
	C304.450378,220.946762 304.536407,219.843948 304.611511,218.063156 
	C304.579254,216.976425 304.557922,216.567673 304.671143,215.698456 
	C304.722473,208.117767 304.639313,200.997574 304.556152,193.877365 
	C304.638672,193.149551 304.721252,192.421738 304.908936,191.341766 
	C305.324402,191.551071 305.907257,192.113358 305.905518,192.673843 
	C305.854034,209.248718 305.744507,225.823395 305.270386,242.373520 
	C304.760284,241.907974 304.623962,241.467026 304.572052,240.560165 
	C304.559113,234.079361 304.461700,228.064453 304.364319,222.049561 
z"/>
<path fill="#A34C9A" opacity="1.000000" stroke="none" 
	d="
M348.506439,182.709885 
	C348.062164,181.800735 347.991089,181.002335 347.901520,179.806961 
	C351.913483,179.333542 355.943878,179.257095 360.453400,179.583893 
	C360.923920,180.923615 360.915344,181.860107 360.906738,182.796570 
	C356.897705,182.804596 352.888672,182.812607 348.506439,182.709885 
z"/>
<path fill="#882CB1" opacity="1.000000" stroke="none" 
	d="
M336.772461,160.954468 
	C337.694153,161.490646 338.342743,161.933777 338.991333,162.376907 
	C339.020447,162.761765 339.049561,163.146637 339.078674,163.531494 
	C333.744354,163.651260 328.410034,163.771027 322.624115,163.907074 
	C322.275208,162.959229 322.377899,161.995132 322.818573,160.917130 
	C327.127319,160.818436 331.098022,160.833633 335.515564,160.749634 
	C336.141388,160.720795 336.320404,160.791122 336.772461,160.954468 
z"/>
<path fill="#FEFCE6" opacity="1.000000" stroke="none" 
	d="
M139.539246,354.085815 
	C139.984055,361.680389 140.040024,369.374512 140.051041,377.535950 
	C139.344696,377.219574 138.125870,376.447235 138.109650,375.650421 
	C137.974655,369.014404 138.037476,362.373535 138.127045,355.735291 
	C138.134079,355.214172 138.793930,354.701843 139.539246,354.085815 
z"/>
<path fill="#FEF9E0" opacity="1.000000" stroke="none" 
	d="
M296.053223,129.035477 
	C295.574005,123.998726 295.452332,118.893745 295.513153,113.341675 
	C296.167999,113.026810 297.056610,113.172180 297.052917,113.289413 
	C296.888062,118.516914 296.644714,123.741936 296.053223,129.035477 
z"/>
<path fill="#DCB73C" opacity="1.000000" stroke="none" 
	d="
M256.209106,364.434692 
	C256.546204,359.802002 256.927582,355.575012 257.662537,351.740448 
	C258.042389,360.500214 256.793854,369.029388 261.152832,376.846863 
	C261.152832,376.846863 260.992157,377.020966 260.631775,377.043762 
	C259.897675,376.981903 259.523987,376.897217 259.150299,376.812531 
	C259.150299,376.812531 259.041412,376.956207 259.053375,376.548767 
	C258.122101,373.734344 257.178864,371.327362 256.235657,368.920380 
	C256.241577,367.560394 256.247498,366.200439 256.209106,364.434692 
z"/>
<path fill="#C3898E" opacity="1.000000" stroke="none" 
	d="
M274.809082,396.027710 
	C275.061432,394.404175 275.192657,393.108978 275.323914,391.813812 
	C275.736603,391.836792 276.149323,391.859772 276.562042,391.882721 
	C276.562042,394.818756 276.562042,397.754791 276.562042,401.137817 
	C274.516174,400.114044 272.747375,399.228943 270.978577,398.343811 
	C270.786926,398.766083 270.595276,399.188324 270.403656,399.610596 
	C270.282867,397.428619 270.162109,395.246613 269.982452,392.000092 
	C272.248688,394.097992 273.468323,395.227020 274.809082,396.027710 
z"/>
<path fill="#FDF7D8" opacity="1.000000" stroke="none" 
	d="
M178.477890,278.890808 
	C178.868011,279.492676 178.993317,279.881683 179.135269,280.576294 
	C175.365540,279.946838 173.452316,277.680176 172.915588,273.660431 
	C172.376358,269.621979 171.020645,265.692505 170.082275,261.292419 
	C172.832245,266.805664 175.522659,272.741821 178.477890,278.890808 
z"/>
<path fill="#C88CAD" opacity="1.000000" stroke="none" 
	d="
M360.261841,173.592850 
	C361.158234,172.543091 362.507202,171.663605 363.872498,170.453094 
	C365.851959,170.057465 367.840881,169.780304 369.767975,170.013733 
	C370.803619,170.139160 371.733063,171.141144 372.710449,171.747620 
	C371.664551,172.460037 370.663452,173.689575 369.564056,173.784836 
	C366.636780,174.038467 363.667206,173.803696 360.261841,173.592850 
z"/>
<path fill="#FDF7D8" opacity="1.000000" stroke="none" 
	d="
M167.629059,229.246613 
	C167.948364,235.364014 167.977722,241.730850 167.935455,248.564148 
	C167.341721,248.681870 166.353149,248.313995 166.366592,247.987274 
	C166.619995,241.820618 166.991898,235.658859 167.629059,229.246613 
z"/>
<path fill="#FEFCE6" opacity="1.000000" stroke="none" 
	d="
M158.917236,339.514893 
	C153.035950,339.970490 147.052109,340.039642 140.616791,340.156616 
	C140.371170,339.622101 140.588348,338.527344 140.781235,338.531616 
	C146.793854,338.664551 152.804077,338.904724 158.917236,339.514893 
z"/>
<path fill="#A34C9A" opacity="1.000000" stroke="none" 
	d="
M344.950653,209.768890 
	C344.951355,209.164291 344.952087,208.559692 344.932800,207.656509 
	C347.688416,207.296371 350.489258,206.992661 353.228912,207.277390 
	C354.560608,207.415787 355.777863,208.654709 357.112152,209.809616 
	C356.126221,210.253769 355.075073,210.283737 353.176086,210.169022 
	C349.869049,209.939178 347.409851,209.854034 344.950653,209.768890 
z"/>
<path fill="#A34C9A" opacity="1.000000" stroke="none" 
	d="
M352.531250,188.342621 
	C352.990204,189.336472 353.005463,190.251114 352.947662,191.500977 
	C349.573151,191.854843 346.271698,191.873444 342.530365,191.814102 
	C343.029419,190.875275 343.968353,190.014389 344.910522,188.850922 
	C347.305023,188.453354 349.696289,188.358383 352.531250,188.342621 
z"/>
<path fill="#FEFCE6" opacity="1.000000" stroke="none" 
	d="
M259.128052,377.189880 
	C259.523987,376.897217 259.897675,376.981903 260.552795,377.132080 
	C264.075439,381.180267 268.117340,379.971191 272.530029,378.922729 
	C273.309692,381.769440 271.555695,382.072449 269.335754,382.018585 
	C265.302185,381.920685 260.996674,382.550659 259.128052,377.189880 
z"/>
<path fill="#FDF7D8" opacity="1.000000" stroke="none" 
	d="
M177.782379,199.114212 
	C176.035980,203.943924 173.935226,208.785248 171.587463,213.866028 
	C170.822861,208.077286 172.669479,203.009857 177.782379,199.114212 
z"/>
<path fill="#FEFCE6" opacity="1.000000" stroke="none" 
	d="
M282.856689,351.584320 
	C283.411530,351.678955 284.500885,352.236145 284.495392,352.782318 
	C284.447510,357.546021 284.203613,362.307800 284.030090,367.536438 
	C284.015533,370.945190 283.988861,373.887634 283.622192,376.931335 
	C283.149323,368.702301 283.016449,360.372040 282.856689,351.584320 
z"/>
<path fill="#C3898E" opacity="1.000000" stroke="none" 
	d="
M361.351440,182.887604 
	C360.915344,181.860107 360.923920,180.923615 360.906677,179.636810 
	C363.293091,179.210648 365.705292,179.134827 368.563538,179.126633 
	C369.007935,180.185272 369.006287,181.176285 369.006592,182.539215 
	C366.604431,182.933640 364.200287,182.956131 361.351440,182.887604 
z"/>
<path fill="#FDF7D8" opacity="1.000000" stroke="none" 
	d="
M202.963882,305.514038 
	C207.604568,307.497192 212.264099,309.845123 217.165375,312.457855 
	C212.167953,311.349823 206.099091,311.725769 202.963882,305.514038 
z"/>
<path fill="#FEFCE6" opacity="1.000000" stroke="none" 
	d="
M255.952377,369.085266 
	C257.178864,371.327362 258.122101,373.734344 259.124817,376.493774 
	C256.335144,376.846252 253.486038,376.846252 251.361145,376.846252 
	C252.523514,374.796661 254.096313,372.023407 255.952377,369.085266 
z"/>
<path fill="#FEFCE6" opacity="1.000000" stroke="none" 
	d="
M265.987396,372.645905 
	C264.976593,370.799622 263.363007,368.605774 263.116272,366.267670 
	C262.620361,361.568176 262.869965,356.790009 262.756378,351.588806 
	C263.328308,351.639435 264.561523,352.254028 264.493195,352.635620 
	C263.297211,359.315094 263.753693,365.834717 265.987396,372.645905 
z"/>
<path fill="#FEF9E0" opacity="1.000000" stroke="none" 
	d="
M305.212646,113.840034 
	C303.936066,113.272781 302.917084,112.515442 301.234344,111.264763 
	C301.087830,113.913788 300.970825,116.029114 300.765228,118.548363 
	C300.408600,117.370697 299.897308,115.782669 299.939026,114.209297 
	C299.983246,112.541077 300.552795,110.886787 300.892944,109.226410 
	C302.418732,110.700920 303.944519,112.175438 305.212646,113.840034 
z"/>
<path fill="#FEFCE6" opacity="1.000000" stroke="none" 
	d="
M140.166901,379.024750 
	C144.161682,378.758209 148.150970,378.895203 152.596954,378.926453 
	C154.106827,378.862305 155.159988,378.903870 156.597168,379.040955 
	C151.769028,379.700165 146.559525,380.292816 141.338943,380.762573 
	C140.989273,380.794037 140.555862,379.894714 140.166901,379.024750 
z"/>
<path fill="#FEFCE6" opacity="1.000000" stroke="none" 
	d="
M164.930359,342.466858 
	C163.527512,341.779602 162.437363,341.206207 161.140152,340.397705 
	C165.828964,337.698914 168.408936,340.919464 170.895508,344.698975 
	C170.843918,345.155365 170.623749,345.374298 170.358276,345.283630 
	C169.724838,345.134094 169.356888,345.075256 168.988937,345.016418 
	C168.747482,344.758423 168.506027,344.500397 167.973389,343.970886 
	C167.499741,343.429688 167.268600,343.378174 166.988800,343.544830 
	C166.406876,343.223450 165.824951,342.902100 164.930359,342.466858 
z"/>
<path fill="#FDF7D8" opacity="1.000000" stroke="none" 
	d="
M219.039734,313.456116 
	C223.473053,314.212311 227.979752,315.319977 232.754272,316.694916 
	C227.800110,318.492859 223.398376,316.406952 219.039734,313.456116 
z"/>
<path fill="#FEFCE6" opacity="1.000000" stroke="none" 
	d="
M216.151794,379.240112 
	C221.102066,378.896088 226.078445,378.959961 231.510956,379.114929 
	C226.714447,379.725647 221.534409,382.838776 216.151794,379.240112 
z"/>
<path fill="#C88CAD" opacity="1.000000" stroke="none" 
	d="
M344.490265,209.814865 
	C347.409851,209.854034 349.869049,209.939178 352.784760,210.134521 
	C345.522827,210.419449 337.804382,210.594208 329.687683,210.596344 
	C334.202942,210.236099 339.116394,210.048477 344.490265,209.814865 
z"/>
<path fill="#FDF7D8" opacity="1.000000" stroke="none" 
	d="
M257.944641,160.293533 
	C251.350037,160.753113 244.638168,160.879700 237.461060,160.941620 
	C243.939651,160.571503 250.883499,160.266022 257.944641,160.293533 
z"/>
<path fill="#A34C9A" opacity="1.000000" stroke="none" 
	d="
M332.031616,396.061737 
	C332.186340,397.314880 332.340820,398.239288 332.649078,400.084076 
	C330.125549,398.671448 328.229034,397.609833 326.338043,396.551300 
	C328.015442,395.714417 329.805359,394.821350 331.539856,393.955994 
	C331.640289,394.319153 331.835785,395.026062 332.031616,396.061737 
z"/>
<path fill="#FEFCE6" opacity="1.000000" stroke="none" 
	d="
M139.797638,340.179932 
	C140.165634,343.482330 140.119659,346.798798 139.986969,350.566650 
	C136.546143,347.561890 137.483231,343.900940 139.797638,340.179932 
z"/>
<path fill="#FEFCE6" opacity="1.000000" stroke="none" 
	d="
M275.552612,368.987976 
	C274.208069,370.698547 272.463776,372.410461 270.353149,374.045654 
	C270.534119,371.090393 271.158997,368.292328 275.552612,368.987976 
z"/>
<path fill="#FEFCE6" opacity="1.000000" stroke="none" 
	d="
M275.946472,353.147583 
	C276.355896,356.101654 276.466949,359.237854 276.366394,362.716370 
	C275.985870,359.815704 275.816956,356.572723 275.946472,353.147583 
z"/>
<path fill="#FEFCE6" opacity="1.000000" stroke="none" 
	d="
M276.994568,379.367493 
	C278.654205,378.998077 280.313812,378.976776 282.382812,378.924805 
	C282.772034,379.758972 282.751831,380.623810 282.731628,381.488678 
	C280.915894,380.584900 277.946869,383.412903 276.994568,379.367493 
z"/>
<path fill="#FDF7D8" opacity="1.000000" stroke="none" 
	d="
M228.087219,162.210754 
	C224.942047,163.516571 221.338242,164.736420 217.356262,165.940979 
	C219.748489,162.473755 223.475052,161.701050 228.087219,162.210754 
z"/>
<path fill="#FDF7D8" opacity="1.000000" stroke="none" 
	d="
M279.301270,309.952393 
	C281.705048,308.277130 284.509766,306.648132 287.670044,305.027710 
	C286.948334,309.537231 283.155304,309.482758 279.301270,309.952393 
z"/>
<path fill="#FEF9E0" opacity="1.000000" stroke="none" 
	d="
M307.585266,256.851166 
	C307.221802,255.012650 307.111633,252.899399 307.009888,250.394165 
	C307.463013,249.674927 307.907776,249.347672 308.641174,248.992554 
	C308.566071,251.501938 308.202332,254.039200 307.585266,256.851166 
z"/>
<path fill="#FEFCE6" opacity="1.000000" stroke="none" 
	d="
M193.991745,379.648987 
	C194.747665,379.688782 195.503571,379.728577 196.955185,379.809753 
	C198.104340,379.915497 198.557800,379.979919 199.011261,380.044312 
	C196.703461,383.515320 193.950256,382.373322 191.448425,380.377289 
	C192.491364,379.850861 193.241562,379.749908 193.991745,379.648987 
z"/>
<path fill="#FEFCE6" opacity="1.000000" stroke="none" 
	d="
M170.954025,345.045929 
	C172.371231,347.075623 173.678329,349.214783 174.702118,351.560364 
	C173.675766,350.793854 172.932709,349.820923 172.189636,348.848022 
	C172.189636,348.848022 172.024689,349.003693 172.093658,348.634521 
	C171.763123,347.859253 171.363617,347.453125 170.964096,347.046997 
	C170.964096,347.046997 170.995193,346.996704 170.961441,346.731018 
	C170.826370,346.101685 170.725052,345.737976 170.623749,345.374298 
	C170.623749,345.374298 170.843918,345.155365 170.954025,345.045929 
z"/>
<path fill="#FDF7D8" opacity="1.000000" stroke="none" 
	d="
M289.020691,304.855011 
	C290.496918,303.192780 292.354828,301.558990 294.543640,299.827209 
	C293.050507,301.447327 291.226440,303.165375 289.020691,304.855011 
z"/>
<path fill="#FEFCE6" opacity="1.000000" stroke="none" 
	d="
M295.922180,337.648834 
	C294.309204,338.067566 292.634552,338.110931 290.545227,338.207092 
	C291.492615,334.747864 293.567322,335.375977 295.922180,337.648834 
z"/>
<path fill="#FDF7D8" opacity="1.000000" stroke="none" 
	d="
M181.705597,193.059860 
	C180.943970,194.961594 179.819473,196.876495 178.403870,198.953766 
	C179.189438,197.101776 180.266083,195.087402 181.705597,193.059860 
z"/>
<path fill="#FEF9E0" opacity="1.000000" stroke="none" 
	d="
M305.005920,249.644562 
	C304.612396,250.962738 304.221130,251.926086 303.471680,252.564011 
	C303.439758,249.853851 303.765991,247.469131 304.288574,245.041351 
	C304.484894,244.998291 304.885742,244.968063 304.885742,244.968063 
	C304.926544,246.408615 304.967377,247.849182 305.005920,249.644562 
z"/>
<path fill="#FEFCE6" opacity="1.000000" stroke="none" 
	d="
M183.027634,375.290527 
	C183.512024,375.177673 183.977142,375.460419 184.741928,376.163879 
	C185.358475,376.736450 185.675354,376.888275 185.992233,377.040100 
	C185.992233,377.040100 186.313583,377.207367 186.647156,377.459229 
	C187.345245,378.128662 187.709732,378.546204 188.074219,378.963745 
	C185.610336,379.069397 183.113708,379.225677 183.027634,375.290527 
z"/>
<path fill="#E5BD52" opacity="1.000000" stroke="none" 
	d="
M188.386734,378.993011 
	C187.709732,378.546204 187.345245,378.128662 186.827530,377.475861 
	C188.947128,377.895966 191.219925,378.551331 193.742233,379.427826 
	C193.241562,379.749908 192.491364,379.850861 191.361694,380.001282 
	C190.221222,379.707947 189.460236,379.365112 188.386734,378.993011 
z"/>
<path fill="#FEFCF0" opacity="1.000000" stroke="none" 
	d="
M288.076904,398.421997 
	C286.685181,399.311584 285.039398,400.027618 283.393585,400.743652 
	C283.231232,400.285156 283.068848,399.826660 282.906494,399.368164 
	C284.545288,398.994904 286.184052,398.621674 288.076904,398.421997 
z"/>
<path fill="#FEFCE6" opacity="1.000000" stroke="none" 
	d="
M280.660278,349.001282 
	C281.632782,349.493286 282.265564,349.991455 282.884338,350.829468 
	C281.507690,351.085907 280.145081,351.002533 278.376709,350.846558 
	C278.754211,350.185089 279.537415,349.596252 280.660278,349.001282 
z"/>
<path fill="#FDF7D8" opacity="1.000000" stroke="none" 
	d="
M276.963074,85.735329 
	C276.061157,85.544792 275.195557,84.967247 274.170532,84.121750 
	C274.660889,83.332748 275.310608,82.811691 275.960327,82.290634 
	C276.306671,83.309868 276.653046,84.329094 276.963074,85.735329 
z"/>
<path fill="#FDF7D8" opacity="1.000000" stroke="none" 
	d="
M234.823639,160.810837 
	C233.623428,161.420197 232.191299,161.745026 230.381042,162.028473 
	C231.532532,161.500183 233.062119,161.013245 234.823639,160.810837 
z"/>
<path fill="#FDF7D8" opacity="1.000000" stroke="none" 
	d="
M184.986923,289.389984 
	C186.226944,290.161285 187.466446,291.304382 188.811920,292.749054 
	C187.607407,291.954346 186.296890,290.858063 184.986923,289.389984 
z"/>
<path fill="#E5BD52" opacity="1.000000" stroke="none" 
	d="
M172.125870,349.214050 
	C172.932709,349.820923 173.675766,350.793854 174.668976,351.956512 
	C174.920853,352.586914 174.922577,353.027557 174.836517,353.787628 
	C173.853195,352.598083 172.957642,351.089081 172.125870,349.214050 
z"/>
<path fill="#FEFCE6" opacity="1.000000" stroke="none" 
	d="
M268.260681,348.160522 
	C269.118591,347.854004 269.898773,347.883667 270.678925,347.913330 
	C270.529785,348.475830 270.380615,349.038330 270.231476,349.600830 
	C269.548645,349.232788 268.865784,348.864746 268.260681,348.160522 
z"/>
<path fill="#FDF7D8" opacity="1.000000" stroke="none" 
	d="
M205.735641,171.073959 
	C204.675919,172.115753 203.238358,173.110886 201.464920,174.134369 
	C202.538620,173.117569 203.948212,172.072433 205.735641,171.073959 
z"/>
<path fill="#FEFCE6" opacity="1.000000" stroke="none" 
	d="
M276.868347,376.160309 
	C276.887756,376.870880 276.907166,377.581482 276.953217,378.648865 
	C275.897034,379.000061 274.814178,378.994415 273.325134,378.957581 
	C274.010864,378.003723 275.102814,377.081055 276.592377,376.087952 
	C276.990021,376.017578 276.868347,376.160309 276.868347,376.160309 
z"/>
<path fill="#FDF7D8" opacity="1.000000" stroke="none" 
	d="
M188.961365,293.446930 
	C189.708786,293.674438 190.467712,294.266022 191.484161,294.993256 
	C192.060394,295.597229 192.379074,296.065552 192.759247,296.809875 
	C191.538116,295.994232 190.255493,294.902618 188.961365,293.446930 
z"/>
<path fill="#FEFCF0" opacity="1.000000" stroke="none" 
	d="
M287.967651,395.009430 
	C287.462494,394.298279 287.215698,393.766266 286.968903,393.234283 
	C287.156219,393.078156 287.343567,392.922028 287.530884,392.765900 
	C288.654114,393.733917 289.777313,394.701965 290.900513,395.669983 
	C290.677277,395.960663 290.454041,396.251343 290.230804,396.541992 
	C289.562561,396.090851 288.894287,395.639709 287.967651,395.009430 
z"/>
<path fill="#FDF7D8" opacity="1.000000" stroke="none" 
	d="
M194.977188,299.394470 
	C195.918549,299.789886 196.860092,300.537231 197.933990,301.558044 
	C195.077103,304.039337 195.532166,301.144867 194.977188,299.394470 
z"/>
<path fill="#FDF7D8" opacity="1.000000" stroke="none" 
	d="
M167.885773,251.130493 
	C168.462830,252.387131 168.757172,253.825302 168.983826,255.640503 
	C168.478439,254.449020 168.040741,252.880539 167.885773,251.130493 
z"/>
<path fill="#FEFCE6" opacity="1.000000" stroke="none" 
	d="
M262.142426,344.867310 
	C262.125031,345.667664 261.876190,346.238098 261.627350,346.808533 
	C261.150055,346.332947 260.672760,345.857330 260.195465,345.381744 
	C260.767303,345.133636 261.339142,344.885529 262.142426,344.867310 
z"/>
<path fill="#FDF7D8" opacity="1.000000" stroke="none" 
	d="
M277.654724,94.971558 
	C277.279419,93.961243 277.207062,92.849762 277.247375,91.332138 
	C277.559296,92.240784 277.758453,93.555588 277.654724,94.971558 
z"/>
<path fill="#FDF7D8" opacity="1.000000" stroke="none" 
	d="
M184.724854,189.078003 
	C184.282639,190.329071 183.473602,191.584702 182.357117,192.951691 
	C182.819138,191.736237 183.588593,190.409409 184.724854,189.078003 
z"/>
<path fill="#FEF9E0" opacity="1.000000" stroke="none" 
	d="
M321.980225,130.691284 
	C321.198090,130.718185 320.433075,130.369019 319.347504,130.039154 
	C319.015259,129.606247 319.003632,129.154037 319.000793,128.362976 
	C320.005524,128.787827 321.001434,129.551529 321.980225,130.691284 
z"/>
<path fill="#E5BD52" opacity="1.000000" stroke="none" 
	d="
M199.274094,379.956421 
	C198.557800,379.979919 198.104340,379.915497 197.327667,379.734619 
	C198.173843,379.114990 199.343246,378.611816 200.842438,378.112457 
	C201.060532,378.486206 200.948837,378.856079 200.735657,379.497009 
	C200.268417,379.801483 199.902664,379.834991 199.274094,379.956421 
z"/>
<path fill="#FEFCE6" opacity="1.000000" stroke="none" 
	d="
M264.479248,339.300903 
	C264.473724,338.551056 264.677094,338.019165 264.880432,337.487305 
	C265.152344,337.621399 265.424286,337.755493 265.696198,337.889587 
	C265.360168,338.432709 265.024139,338.975800 264.479248,339.300903 
z"/>
<path fill="#FEFCE6" opacity="1.000000" stroke="none" 
	d="
M255.497681,381.672058 
	C256.036346,382.014160 256.359497,382.569977 256.682648,383.125824 
	C256.406250,383.252380 256.129852,383.378937 255.853455,383.505524 
	C255.663040,382.965607 255.472610,382.425720 255.497681,381.672058 
z"/>
<path fill="#FDF7D8" opacity="1.000000" stroke="none" 
	d="
M213.737305,167.073456 
	C212.961655,167.754639 211.805435,168.385437 210.309921,168.989197 
	C211.099335,168.315811 212.228027,167.669449 213.737305,167.073456 
z"/>
<path fill="#FDF7D8" opacity="1.000000" stroke="none" 
	d="
M179.082321,281.245300 
	C179.767715,281.686768 180.409271,282.498230 181.088287,283.626892 
	C180.430008,283.167847 179.734238,282.391571 179.082321,281.245300 
z"/>
<path fill="#FDF7D8" opacity="1.000000" stroke="none" 
	d="
M168.537247,224.148773 
	C168.717361,224.850281 168.643356,225.709930 168.353958,226.814453 
	C168.186752,226.141876 168.234940,225.224380 168.537247,224.148773 
z"/>
<path fill="#FDF7D8" opacity="1.000000" stroke="none" 
	d="
M208.952759,169.119583 
	C208.482101,169.810196 207.624008,170.453094 206.438522,171.118683 
	C206.929199,170.451538 207.747253,169.761703 208.952759,169.119583 
z"/>
<path fill="#FEF9E0" opacity="1.000000" stroke="none" 
	d="
M300.468719,131.304810 
	C300.725861,132.413193 300.782501,133.780106 300.749908,135.549255 
	C300.529846,134.488770 300.399048,133.026062 300.468719,131.304810 
z"/>
<path fill="#EBC452" opacity="1.000000" stroke="none" 
	d="
M304.884583,244.640274 
	C304.885742,244.968063 304.484894,244.998291 304.227997,244.692780 
	C304.143280,243.266861 304.315430,242.146469 304.487610,241.026077 
	C304.623962,241.467026 304.760284,241.907974 304.991455,242.683960 
	C305.018707,243.450150 304.951050,243.881317 304.884583,244.640274 
z"/>
<path fill="#FEFCE6" opacity="1.000000" stroke="none" 
	d="
M322.101868,377.235138 
	C322.776093,377.261536 323.464874,377.644714 324.241028,378.313934 
	C323.591095,378.263947 322.853729,377.927917 322.101868,377.235138 
z"/>
<path fill="#FDF7D8" opacity="1.000000" stroke="none" 
	d="
M290.656738,245.258408 
	C290.846466,246.243378 290.771423,247.494843 290.426697,249.016037 
	C290.235321,248.032150 290.313629,246.778519 290.656738,245.258408 
z"/>
<path fill="#FDF7D8" opacity="1.000000" stroke="none" 
	d="
M169.056351,257.182495 
	C169.482010,257.923431 169.866898,259.053772 170.218933,260.555908 
	C169.795914,259.809143 169.405746,258.690491 169.056351,257.182495 
z"/>
<path fill="#E5BD52" opacity="1.000000" stroke="none" 
	d="
M159.348145,378.739502 
	C159.520950,378.403625 159.996521,378.187683 160.744904,377.992310 
	C161.017715,378.012878 160.979691,378.448730 160.966705,378.666870 
	C160.519470,378.876465 160.085190,378.867950 159.348145,378.739502 
z"/>
<path fill="#FDF7D8" opacity="1.000000" stroke="none" 
	d="
M215.926025,166.100677 
	C215.742523,166.474518 215.222839,166.807083 214.387924,167.148239 
	C214.578400,166.791031 215.084106,166.425217 215.926025,166.100677 
z"/>
<path fill="#D9A745" opacity="1.000000" stroke="none" 
	d="
M322.868988,196.765167 
	C322.442169,196.873749 322.046906,196.706436 321.651611,196.539124 
	C321.875977,196.362259 322.100342,196.185394 322.554382,195.977341 
	C322.822876,196.127182 322.861694,196.308212 322.868988,196.765167 
z"/>
<path fill="#C88CAD" opacity="1.000000" stroke="none" 
	d="
M323.152039,173.269608 
	C323.664978,173.088791 324.119049,173.153488 324.737854,173.373779 
	C324.519073,173.682190 324.135498,173.835007 323.436584,174.003540 
	C323.111847,173.851212 323.102509,173.683151 323.152039,173.269608 
z"/>
<path fill="#FDF7D8" opacity="1.000000" stroke="none" 
	d="
M323.354187,221.845062 
	C323.196350,221.448364 323.242279,220.887573 323.574951,220.263245 
	C323.760437,220.693466 323.659210,221.187210 323.354187,221.845062 
z"/>
<path fill="#FDF7D8" opacity="1.000000" stroke="none" 
	d="
M169.670593,219.206757 
	C169.834412,219.625961 169.742905,220.254562 169.410889,221.107391 
	C169.252029,220.693130 169.333664,220.054642 169.670593,219.206757 
z"/>
<path fill="#FDF7D8" opacity="1.000000" stroke="none" 
	d="
M194.878174,179.157104 
	C194.951904,179.383331 194.766403,179.676300 194.319275,180.032425 
	C194.244736,179.805008 194.431839,179.514435 194.878174,179.157104 
z"/>
<path fill="#FDF7D8" opacity="1.000000" stroke="none" 
	d="
M195.875336,178.160126 
	C195.958969,178.386765 195.781570,178.685242 195.346832,179.060852 
	C195.264404,178.835999 195.439346,178.533981 195.875336,178.160126 
z"/>
<path fill="#FDF7D8" opacity="1.000000" stroke="none" 
	d="
M196.995331,177.165985 
	C197.060867,177.407516 196.842072,177.700180 196.353149,178.065063 
	C196.292358,177.830551 196.501663,177.523834 196.995331,177.165985 
z"/>
<path fill="#FDF7D8" opacity="1.000000" stroke="none" 
	d="
M185.830643,188.132355 
	C185.911575,188.350189 185.743301,188.650192 185.316620,189.019852 
	C185.232620,188.797852 185.407028,188.506195 185.830643,188.132355 
z"/>
<path fill="#FDF7D8" opacity="1.000000" stroke="none" 
	d="
M198.748734,176.042389 
	C198.979782,176.355164 198.813690,176.603836 198.219635,176.738708 
	C198.085083,176.464569 198.271729,176.249023 198.748734,176.042389 
z"/>
<path fill="#FDF7D8" opacity="1.000000" stroke="none" 
	d="
M187.848663,186.134888 
	C187.919937,186.352798 187.737152,186.642761 187.298920,187.002045 
	C187.227188,186.783234 187.410889,186.495087 187.848663,186.134888 
z"/>
<path fill="#FDF7D8" opacity="1.000000" stroke="none" 
	d="
M188.840988,185.137039 
	C188.920334,185.354431 188.749939,185.657608 188.319870,186.031540 
	C188.237213,185.809128 188.414230,185.515976 188.840988,185.137039 
z"/>
<path fill="#FDF7D8" opacity="1.000000" stroke="none" 
	d="
M189.815582,184.123016 
	C189.894928,184.331482 189.727325,184.627716 189.306580,185.007401 
	C189.225174,184.797485 189.396896,184.504120 189.815582,184.123016 
z"/>
<path fill="#FDF7D8" opacity="1.000000" stroke="none" 
	d="
M190.766052,183.098648 
	C190.847733,183.303101 190.688919,183.598770 190.284271,183.978821 
	C190.200790,183.772095 190.363174,183.480988 190.766052,183.098648 
z"/>
<path fill="#FDF7D8" opacity="1.000000" stroke="none" 
	d="
M186.822540,187.125244 
	C186.900604,187.339310 186.729752,187.634811 186.305145,188.004852 
	C186.225464,187.788483 186.399536,187.497604 186.822540,187.125244 
z"/>
<path fill="#FDF7D8" opacity="1.000000" stroke="none" 
	d="
M191.811462,182.106323 
	C191.876282,182.317810 191.694122,182.599701 191.259705,182.944031 
	C191.193130,182.729904 191.378799,182.453323 191.811462,182.106323 
z"/>
<path fill="#FDF7D8" opacity="1.000000" stroke="none" 
	d="
M192.805786,181.115250 
	C192.882919,181.330536 192.716293,181.631378 192.291779,181.994904 
	C192.209930,181.772003 192.385971,181.486404 192.805786,181.115250 
z"/>
<path fill="#FDF7D8" opacity="1.000000" stroke="none" 
	d="
M193.842987,180.131012 
	C193.909943,180.345001 193.725220,180.629913 193.285004,180.982269 
	C193.216782,180.767105 193.404037,180.484528 193.842987,180.131012 
z"/>
<path fill="#FEFCE6" opacity="1.000000" stroke="none" 
	d="
M321.087158,376.074463 
	C321.305115,376.003662 321.616669,376.201660 322.019928,376.667816 
	C321.801331,376.738403 321.491028,376.540863 321.087158,376.074463 
z"/>
<path fill="#FDF7D8" opacity="1.000000" stroke="none" 
	d="
M295.022430,104.558205 
	C294.771790,104.783211 294.558472,104.680328 294.500580,104.199966 
	C294.734650,104.114120 294.869232,104.250069 295.022430,104.558205 
z"/>
<path fill="#FDF7D8" opacity="1.000000" stroke="none" 
	d="
M200.877411,174.166641 
	C200.949005,174.413040 200.750748,174.719772 200.271576,175.068527 
	C200.196289,174.816025 200.401917,174.521500 200.877411,174.166641 
z"/>
<path fill="#FDF7D8" opacity="1.000000" stroke="none" 
	d="
M300.191071,294.883484 
	C300.107513,294.662903 300.267456,294.353088 300.692047,293.986481 
	C300.782654,294.217865 300.608612,294.506042 300.191071,294.883484 
z"/>
<path fill="#FDF7D8" opacity="1.000000" stroke="none" 
	d="
M299.147461,295.866760 
	C299.084534,295.654175 299.278992,295.378326 299.722260,295.024353 
	C299.782349,295.232025 299.593597,295.517761 299.147461,295.866760 
z"/>
<path fill="#FDF7D8" opacity="1.000000" stroke="none" 
	d="
M298.158325,296.859009 
	C298.076050,296.636383 298.243622,296.327606 298.673218,295.954407 
	C298.759583,296.184296 298.583893,296.478577 298.158325,296.859009 
z"/>
<path fill="#FDF7D8" opacity="1.000000" stroke="none" 
	d="
M297.152374,297.861511 
	C297.078613,297.650787 297.254608,297.356628 297.687805,296.985626 
	C297.764038,297.198547 297.583099,297.488312 297.152374,297.861511 
z"/>
<path fill="#FDF7D8" opacity="1.000000" stroke="none" 
	d="
M296.036896,298.851654 
	C295.978149,298.622131 296.200043,298.343903 296.682953,297.983032 
	C296.735168,298.201233 296.526367,298.502106 296.036896,298.851654 
z"/>
<path fill="#FDF7D8" opacity="1.000000" stroke="none" 
	d="
M302.199432,292.880493 
	C302.104706,292.663879 302.254730,292.349609 302.662994,291.956421 
	C302.762238,292.179321 302.603210,292.481079 302.199432,292.880493 
z"/>
<path fill="#FDF7D8" opacity="1.000000" stroke="none" 
	d="
M301.181702,293.883545 
	C301.113953,293.674286 301.298401,293.394684 301.729248,293.034058 
	C301.795105,293.239777 301.614532,293.526489 301.181702,293.883545 
z"/>
<path fill="#FDF7D8" opacity="1.000000" stroke="none" 
	d="
M170.617279,216.147644 
	C170.752213,216.324600 170.679230,216.659271 170.383606,217.142883 
	C170.243774,216.962997 170.326569,216.634186 170.617279,216.147644 
z"/>
<path fill="#FDF7D8" opacity="1.000000" stroke="none" 
	d="
M200.109818,303.223511 
	C200.305786,303.145935 200.604782,303.305756 200.998093,303.711426 
	C200.799225,303.791809 200.506027,303.626343 200.109818,303.223511 
z"/>
<path fill="#C78E2A" opacity="1.000000" stroke="none" 
	d="
M263.938110,183.024017 
	C253.749130,183.399750 243.154144,182.100739 233.444717,184.454575 
	C199.754425,192.622040 182.445358,226.959671 193.617432,258.369354 
	C194.926071,262.048492 196.630020,265.587036 197.832565,269.591553 
	C196.643555,270.009338 195.772690,270.026306 194.726929,270.007874 
	C194.552002,269.972473 194.195892,269.943909 194.170166,269.640625 
	C193.777130,268.877075 193.409821,268.416748 193.020294,267.976135 
	C192.998062,267.995789 193.052185,267.972565 193.044769,267.669250 
	C192.715149,266.881378 192.392944,266.396851 192.045212,265.947083 
	C192.019699,265.981873 192.103928,265.963226 192.087631,265.661865 
	C191.740646,264.875671 191.409943,264.390900 191.054688,263.943970 
	C191.030106,263.981873 191.117294,263.958282 191.119751,263.650665 
	C190.778610,262.857361 190.435028,262.371674 190.071869,261.923859 
	C190.052307,261.961731 190.137558,261.962067 190.142578,261.615234 
	C189.794281,260.435333 189.440964,259.602264 189.116287,258.566650 
	C189.144943,258.364136 188.964523,257.997009 188.953461,257.695496 
	C188.672531,257.105377 188.402695,256.816803 188.186493,256.265564 
	C188.139725,255.664276 188.039337,255.325623 187.964951,254.656998 
	C187.708694,253.739578 187.426422,253.152145 187.196259,252.422455 
	C187.248367,252.280197 187.084167,252.025574 187.109711,251.591019 
	C186.772995,245.100647 186.410721,239.044815 186.189911,232.703476 
	C186.266891,231.803177 186.202408,231.188400 186.405289,230.267090 
	C186.781464,228.639130 186.890274,227.317703 187.181244,225.677475 
	C191.770493,210.155914 199.932297,197.635895 213.251801,188.693115 
	C220.539566,183.800079 228.503952,180.968124 237.421326,180.064362 
	C238.062775,179.924683 238.479218,179.785370 239.352097,179.509506 
	C247.846390,179.225586 255.884216,179.078247 263.892517,179.000519 
	C263.863007,179.070114 263.915375,179.211975 263.949951,179.592560 
	C263.969086,180.990112 263.953583,182.007080 263.938110,183.024017 
z"/>
<path fill="#C89031" opacity="1.000000" stroke="none" 
	d="
M200.857727,261.260803 
	C191.592209,246.281860 194.108414,222.434280 206.210861,207.542435 
	C216.793350,194.520905 230.428085,188.293365 247.041046,188.159714 
	C252.362000,188.116898 257.683014,188.081299 263.492676,188.323395 
	C263.916534,189.773865 263.851685,190.943344 263.335815,192.084106 
	C253.474686,192.646454 244.064621,193.237473 234.374832,193.823151 
	C233.741409,193.930222 233.387695,194.042648 232.667725,194.127716 
	C229.071198,195.667664 225.840942,197.234955 222.441925,198.822662 
	C222.273193,198.843063 222.004150,199.050842 221.724640,199.071014 
	C221.190338,199.349533 220.935562,199.607849 220.492615,199.916931 
	C220.304459,199.967697 220.017303,200.231262 219.773590,200.240997 
	C219.194870,200.383438 218.999603,200.627808 218.678406,201.064072 
	C217.893036,201.654297 217.373337,202.164276 216.532455,202.771179 
	C214.828461,204.257584 213.445618,205.647034 212.032532,207.021530 
	C212.002258,207.006561 212.026703,207.069519 211.784668,207.129318 
	C211.220917,207.377213 211.071228,207.657272 211.055984,208.027710 
	C211.018372,208.026108 211.033707,208.099823 210.803284,208.163895 
	C210.287781,208.421097 210.132401,208.689377 209.834381,209.168060 
	C209.332870,209.854004 209.103653,210.404663 208.595917,211.095703 
	C207.828918,212.118591 207.340439,213.001099 206.875183,213.947235 
	C206.898438,214.010864 206.877640,213.878220 206.574158,214.041809 
	C204.180527,219.805069 202.090363,225.404739 199.912552,231.279465 
	C199.830917,231.938095 199.836945,232.321655 199.567627,233.042358 
	C198.286057,242.612183 199.717209,251.455505 203.143097,259.968201 
	C202.122009,260.432495 201.489868,260.846649 200.857727,261.260803 
z"/>
<path fill="#C78E2A" opacity="1.000000" stroke="none" 
	d="
M304.572052,240.560165 
	C304.315430,242.146469 304.143280,243.266861 304.031677,244.735825 
	C303.765991,247.469131 303.439758,249.853851 303.142914,252.633926 
	C303.056000,253.444214 302.939667,253.859161 302.603088,254.873276 
	C300.501251,260.238312 298.619659,265.004211 296.738068,269.770081 
	C296.738068,269.770081 296.741394,269.808533 296.469513,269.939667 
	C295.749817,271.008331 295.302002,271.945862 294.854187,272.883392 
	C294.854187,272.883392 294.927185,273.017242 294.596649,273.064819 
	C293.384369,274.352936 292.502625,275.593506 291.437775,276.931641 
	C291.029297,277.221497 290.914703,277.465698 290.668823,277.889526 
	C290.271576,278.337891 290.116455,278.658630 289.981049,278.989685 
	C290.000793,279.000000 289.976868,278.962341 289.727051,279.034912 
	C289.345917,279.411682 289.214661,279.715881 289.045563,280.025208 
	C289.007751,280.030365 289.030365,280.103271 288.812744,280.181580 
	C288.321533,280.449768 288.175079,280.710968 288.099731,281.030914 
	C288.043701,281.018341 288.038361,281.133087 287.800659,281.166046 
	C287.239716,281.371735 287.099792,281.640076 287.010437,282.105042 
	C286.877716,282.205994 286.784821,282.526367 286.474609,282.639404 
	C285.066772,283.793854 283.969116,284.835266 282.577423,285.995361 
	C281.181824,287.070526 280.080292,288.027008 278.988770,288.992340 
	C278.998810,289.001221 278.986450,288.978546 278.668091,288.998535 
	C277.531342,289.644196 276.712952,290.269836 275.954712,290.910461 
	C276.014862,290.925415 275.903534,290.869263 275.576233,290.889648 
	C274.389313,291.557922 273.529694,292.205841 272.490723,292.856720 
	C272.311310,292.859680 272.012451,293.058350 271.715210,293.054565 
	C271.130005,293.309479 270.842072,293.568146 270.395782,293.821838 
	C270.237457,293.816833 270.005280,294.032379 269.735718,294.042175 
	C269.242584,294.310486 269.019073,294.568939 268.855103,294.812866 
	C268.914642,294.798309 268.800079,294.754578 268.404327,294.778839 
	C265.294647,295.769318 262.580719,296.735535 259.543396,297.694763 
	C258.826385,297.840332 258.432800,297.992889 257.677673,298.139496 
	C256.216370,298.453857 255.116592,298.774170 253.675858,299.095398 
	C252.883606,299.142181 252.432297,299.188110 251.549133,299.168304 
	C249.077652,299.044312 247.038040,298.986053 244.976379,298.566345 
	C245.012268,297.245178 245.070221,296.285461 245.128174,295.325775 
	C270.664856,294.746155 287.738617,281.710388 297.343781,258.750702 
	C299.822266,252.826233 300.292603,245.902237 300.827545,239.367142 
	C301.408417,232.271484 300.988617,225.093903 301.281097,217.475403 
	C301.557037,216.998749 301.986481,216.965149 301.986450,216.965149 
	C301.989594,218.707077 301.981445,220.449600 302.072784,222.186890 
	C302.074707,222.223251 303.225891,222.199219 304.102600,222.126465 
	C304.461700,228.064453 304.559113,234.079361 304.572052,240.560165 
z"/>
<path fill="#FFFFFF" opacity="1.000000" stroke="none" 
	d="
M294.926239,248.847305 
	C294.926239,248.847305 294.911835,248.413010 295.238007,247.995148 
	C295.702484,246.715912 295.840790,245.854538 295.988708,244.555084 
	C295.929443,242.943924 295.860504,241.770859 295.791626,240.597794 
	C295.620911,230.375580 295.450195,220.153366 295.281860,209.011932 
	C295.358948,192.402084 295.433655,176.711487 295.508392,161.020874 
	C297.999512,160.744110 299.131592,161.656097 299.059418,164.359497 
	C298.908600,170.008224 299.016907,175.663849 299.016876,181.316635 
	C299.016815,192.709137 298.904083,204.103119 299.056763,215.493561 
	C299.147614,222.273682 299.978241,229.053558 299.866974,235.823532 
	C299.786224,240.735794 298.791656,245.645950 297.975677,250.518723 
	C297.859222,251.214081 296.449921,251.692932 295.330933,251.741837 
	C294.990662,250.423553 294.958466,249.635437 294.926239,248.847305 
z"/>
<path fill="#D9A745" opacity="1.000000" stroke="none" 
	d="
M263.786835,192.112808 
	C263.851685,190.943344 263.916534,189.773865 263.954468,188.271362 
	C263.962189,186.572449 263.996887,185.206604 263.984833,183.432388 
	C263.953583,182.007080 263.969086,180.990112 264.213318,179.362930 
	C265.297119,177.487946 266.152130,176.223175 267.198425,174.981110 
	C267.389679,175.003799 267.773895,175.032318 267.773895,175.032318 
	C267.814911,190.036880 267.855957,205.041428 267.545715,220.337769 
	C266.101349,220.473297 265.008240,220.317047 263.496674,220.112885 
	C261.409058,219.914810 259.739868,219.764648 258.226868,219.445282 
	C258.905884,218.216980 259.428772,217.157898 260.334839,216.020599 
	C261.913147,214.981277 263.108276,214.020172 264.524292,213.269043 
	C265.438995,213.590271 266.132843,213.701523 266.826660,213.812759 
	C265.900146,212.882034 264.973663,211.951294 263.621796,210.938721 
	C262.075287,210.635315 260.954163,210.413757 259.692627,209.878845 
	C256.415802,209.240799 253.279404,208.916061 250.145813,208.591614 
	C252.444290,208.051819 254.720749,207.517197 257.425842,206.905518 
	C259.770966,206.828476 261.687500,206.828476 264.488190,206.828476 
	C263.837708,204.306274 263.418396,202.680435 263.009399,200.797333 
	C263.020416,200.368362 263.021027,200.196701 263.020569,199.761597 
	C263.012939,199.322678 263.006348,199.147171 262.766052,198.705170 
	C261.356262,198.336487 260.180115,198.234314 258.563385,198.115967 
	C255.121155,197.925278 252.119507,197.750778 249.451202,197.416061 
	C250.518982,197.162552 251.253433,197.069260 252.384903,197.062759 
	C253.858398,197.089142 254.934875,197.028717 256.435303,197.024628 
	C258.566315,197.045761 260.273407,197.010529 262.374817,196.993561 
	C263.108368,195.378815 263.447601,193.745804 263.786835,192.112808 
z"/>
<path fill="#D7A026" opacity="1.000000" stroke="none" 
	d="
M295.372955,160.594086 
	C295.433655,176.711487 295.358948,192.402084 295.181763,208.554169 
	C294.324341,209.013123 293.569366,209.010605 292.437622,209.016663 
	C292.004547,196.330673 291.948242,183.636124 292.118896,170.388580 
	C292.408569,169.726990 292.550873,169.564560 292.520142,169.519119 
	C292.387329,169.322678 292.203888,169.160477 292.038849,168.985825 
	C292.022919,163.974792 292.006958,158.963776 292.062805,153.493546 
	C292.838226,153.025818 293.541901,153.017288 294.597717,153.012878 
	C295.045776,155.400421 295.141663,157.783859 295.372955,160.594086 
z"/>
<path fill="#EBC34A" opacity="1.000000" stroke="none" 
	d="
M244.682922,295.229126 
	C245.070221,296.285461 245.012268,297.245178 244.649689,298.618347 
	C243.910461,299.096985 243.475830,299.162170 242.711273,299.166595 
	C241.937347,299.143127 241.493317,299.180420 240.707291,299.118500 
	C239.896729,299.064301 239.428192,299.109344 238.642883,299.073853 
	C237.891556,298.982208 237.456985,298.971069 236.791077,298.731171 
	C235.711792,298.427856 234.863800,298.353302 233.698807,298.168915 
	C232.940689,297.990906 232.499588,297.922729 231.800781,297.613037 
	C229.683105,296.979279 227.823120,296.587036 225.760559,296.101562 
	C225.557968,296.008362 225.117920,295.935852 224.916672,295.657898 
	C222.807938,294.585632 220.900452,293.791290 218.851349,292.912537 
	C218.709747,292.828156 218.380051,292.826904 218.206390,292.607605 
	C217.685791,292.275146 217.338852,292.162048 216.993027,292.022217 
	C216.994156,291.995544 216.949768,292.025269 216.807281,291.758545 
	C215.773758,291.034485 214.882721,290.577148 213.769150,289.936401 
	C213.034073,289.500519 212.521530,289.248108 211.907715,288.777832 
	C211.599960,288.283386 211.324249,288.139435 210.834641,287.887115 
	C210.194168,287.409821 209.698334,287.173492 209.069580,286.699066 
	C208.633499,286.273621 208.330368,286.086273 208.012939,285.687988 
	C207.998642,285.477020 207.974701,285.054810 207.959152,284.740204 
	C207.569778,283.983856 207.195969,283.542145 206.778595,283.209503 
	C206.735046,283.318604 206.512024,283.392517 206.663025,283.051819 
	C207.241287,282.121338 207.668549,281.531586 208.095825,280.941833 
	C212.698227,283.797699 216.997025,287.476379 221.975388,289.313354 
	C229.136856,291.955780 236.792801,293.258148 244.682922,295.229126 
z"/>
<path fill="#EBC34A" opacity="1.000000" stroke="none" 
	d="
M245.030167,286.256073 
	C244.830612,287.216736 244.631058,288.177368 244.305023,289.402893 
	C230.119247,289.498444 218.542542,283.814362 209.265808,273.427490 
	C210.518341,272.994751 211.422913,272.928802 212.632080,272.910339 
	C213.981644,273.573792 215.026627,274.189697 216.204987,275.059235 
	C216.835983,275.576019 217.333572,275.839233 217.999573,276.385254 
	C219.422485,277.488586 220.677017,278.309052 222.110992,279.339355 
	C222.804016,279.733612 223.317581,279.918060 224.051849,280.368103 
	C226.195770,281.380585 228.118988,282.127472 230.238632,282.961975 
	C230.435043,283.049591 230.863693,283.085327 231.101776,283.340698 
	C232.910812,283.992157 234.481781,284.388184 236.364014,284.880188 
	C237.107437,285.005463 237.539566,285.034790 238.276581,285.308838 
	C240.731049,285.787750 242.880600,286.021912 245.030167,286.256073 
z"/>
<path fill="#D6A736" opacity="1.000000" stroke="none" 
	d="
M301.987732,216.500076 
	C301.986481,216.965149 301.557037,216.998749 301.342102,217.011856 
	C301.086517,193.677994 301.045898,170.331009 301.044312,146.522064 
	C301.403290,150.706604 301.723236,155.353119 302.034180,160.454376 
	C302.013153,179.284409 302.001038,197.659714 301.987732,216.500076 
z"/>
<path fill="#DCB73C" opacity="1.000000" stroke="none" 
	d="
M207.970398,280.624695 
	C207.668549,281.531586 207.241287,282.121338 206.368378,282.992249 
	C204.758453,282.475983 203.594193,281.678558 202.267731,280.609467 
	C201.774796,280.080322 201.444077,279.822845 201.074799,279.392944 
	C201.036224,279.220490 200.792435,278.964661 200.747818,278.749207 
	C200.548782,278.249390 200.310013,278.097534 199.899567,277.843994 
	C199.627487,277.316223 199.372681,277.108673 198.972046,276.718536 
	C198.628433,276.199677 198.360596,275.949432 198.033966,275.487183 
	C197.975143,275.275177 197.691071,274.939178 197.661560,274.737183 
	C197.488785,274.277679 197.271667,274.125031 196.990570,274.045288 
	C197.000381,274.013306 196.933548,274.015442 196.871094,273.773743 
	C196.625229,273.230469 196.361984,273.038025 196.008728,272.977722 
	C195.998535,273.000702 196.046280,272.985016 196.008820,272.704285 
	C195.629074,272.007141 195.286804,271.590729 194.916534,270.892242 
	C194.892944,270.421204 194.897385,270.232239 194.901840,270.043274 
	C195.772690,270.026306 196.643555,270.009338 197.947906,269.945221 
	C201.535950,273.367920 204.690475,276.837738 207.970398,280.624695 
z"/>
<path fill="#D9A745" opacity="1.000000" stroke="none" 
	d="
M212.327469,272.862854 
	C211.422913,272.928802 210.518341,272.994751 209.158722,273.099609 
	C206.097565,269.416443 203.491470,265.694427 200.871552,261.616577 
	C201.489868,260.846649 202.122009,260.432495 203.364944,260.170349 
	C204.998230,261.840698 206.020721,263.359039 207.167023,265.080109 
	C207.472748,265.544006 207.683273,265.779663 207.977753,266.257935 
	C208.328445,266.720276 208.623749,266.914490 208.907349,267.112610 
	C208.895645,267.116547 208.876328,267.141846 208.916519,267.480469 
	C210.080292,269.500336 211.203888,271.181610 212.327469,272.862854 
z"/>
<path fill="#FDF7D8" opacity="1.000000" stroke="none" 
	d="
M294.674438,249.080933 
	C294.958466,249.635437 294.990662,250.423553 295.011444,251.605560 
	C294.826080,251.999344 294.652130,251.999252 294.217163,251.997803 
	C294.111603,251.102478 294.267120,250.208511 294.674438,249.080933 
z"/>
<path fill="#FDFAE6" opacity="1.000000" stroke="none" 
	d="
M193.572449,294.762421 
	C191.583527,291.692261 189.557922,288.673737 187.517532,285.665222 
	C185.585281,282.816132 183.637573,279.977539 181.420166,276.925781 
	C176.029083,266.362122 172.171661,255.681717 171.579758,243.962189 
	C170.269562,218.019806 179.879745,197.015091 199.766815,180.538483 
	C200.662231,179.796616 201.614395,179.123260 203.163696,178.204468 
	C205.192947,176.997849 206.598633,176.004791 208.004318,175.011719 
	C208.004318,175.011719 207.999054,175.000336 208.374146,174.996460 
	C211.162430,173.688034 213.575623,172.383484 215.988815,171.078934 
	C215.988815,171.078934 216.297699,170.879120 216.738129,170.859344 
	C217.439896,170.631607 217.701248,170.423630 217.962585,170.215668 
	C218.113846,170.143677 218.265091,170.071701 218.963287,169.981781 
	C219.998383,169.683334 220.486557,169.402847 220.974716,169.122375 
	C220.974716,169.122360 221.353882,168.921387 221.877213,168.934677 
	C222.922607,168.684433 223.444656,168.420898 223.966721,168.157379 
	C223.966721,168.157379 224.406143,167.985626 224.987000,167.983948 
	C226.373886,167.675827 227.179932,167.369370 227.985977,167.062912 
	C227.985977,167.062912 228.378098,166.884964 229.036926,166.931717 
	C233.121506,166.352249 236.547272,165.726028 239.973038,165.099808 
	C240.149399,165.031982 240.325760,164.964172 241.217316,164.859497 
	C246.736099,164.643845 251.539688,164.465042 256.343262,164.286255 
	C258.165924,164.325439 259.988617,164.364639 262.291687,164.587738 
	C262.772125,164.771667 262.953522,165.189896 262.956299,165.579956 
	C261.580078,166.978928 260.265900,168.730789 258.809998,168.857773 
	C254.397766,169.242569 249.914978,168.765381 245.489319,169.063675 
	C240.989441,169.367004 236.524780,170.193008 231.793304,170.862808 
	C231.369156,170.960144 231.196426,170.984802 230.608917,171.004715 
	C227.133347,171.975998 224.072540,172.952026 220.847870,174.028671 
	C220.683990,174.129303 220.299820,174.110916 219.945129,174.097107 
	C219.039566,174.457794 218.488678,174.832291 217.761078,175.378052 
	C217.133194,175.729370 216.682007,175.909439 215.903442,176.077667 
	C215.056976,176.392624 214.537903,176.719406 214.012573,177.021332 
	C214.006332,176.996490 214.042038,177.033554 213.727463,177.045090 
	C212.955917,177.426315 212.498947,177.796005 211.923523,178.376465 
	C211.577728,178.844345 211.292542,178.978149 210.785217,179.043182 
	C210.406570,179.195816 210.232681,179.342041 209.848572,179.699890 
	C209.404297,179.911713 209.210724,179.960068 208.812225,180.083572 
	C208.344086,180.307297 208.154053,180.520966 207.905167,180.930328 
	C207.773148,181.060928 207.406265,181.118591 207.110992,181.168549 
	C206.552399,181.502899 206.289093,181.787277 206.018616,182.037918 
	C206.011475,182.004196 206.074051,182.033173 205.843689,182.111099 
	C205.321136,182.356003 205.107895,182.593796 204.973755,182.940369 
	C204.973892,182.978348 204.901627,182.954926 204.580994,183.010742 
	C203.877838,183.534744 203.495316,184.002930 202.954376,184.680511 
	C202.214691,185.266373 201.633408,185.642838 200.825775,186.108032 
	C200.312988,186.377121 200.116653,186.628006 199.865005,187.155243 
	C199.463577,187.576675 199.207565,187.792282 198.743088,188.107544 
	C198.274399,188.392715 198.093536,188.636993 197.992828,188.971985 
	C197.993607,189.003937 197.931320,188.989548 197.733017,189.083405 
	C197.285919,189.345810 197.098694,189.566559 196.975266,189.908417 
	C196.977463,189.977295 196.839737,189.971634 196.668732,190.071655 
	C196.281219,190.335800 196.127930,190.546051 195.892731,191.031204 
	C195.486755,191.508270 195.225922,191.756531 194.752991,192.107742 
	C194.276031,192.402618 194.097183,192.655411 194.003906,192.990463 
	C194.003433,193.011826 193.961517,193.003494 193.745956,193.113007 
	C193.262848,193.429520 193.095810,193.698883 193.014938,194.014984 
	C193.000610,193.999390 193.033112,194.028351 192.789841,194.157990 
	C192.392166,194.612976 192.237747,194.938324 191.985352,195.525558 
	C191.519440,196.204758 191.151505,196.622086 190.604904,197.148376 
	C190.210419,197.449692 190.067902,197.686234 190.003265,197.984741 
	C190.007843,198.002502 189.971176,198.000946 189.750687,198.121948 
	C189.372223,198.532303 189.214249,198.821671 189.049988,199.071289 
	C189.043686,199.031555 189.122955,199.045517 188.857971,199.171967 
	C188.381683,199.826843 188.170380,200.355286 187.967651,200.930298 
	C187.976242,200.976852 187.881790,200.983795 187.680511,201.117050 
	C187.235519,201.484695 187.098007,201.770248 187.056549,202.065781 
	C187.046402,202.024597 187.126404,202.052780 186.852585,202.191986 
	C186.393768,202.895981 186.208786,203.460800 186.011230,204.013794 
	C185.998688,204.001999 186.028564,204.016327 185.778168,204.174011 
	C185.393082,204.695251 185.258377,205.058823 185.074341,205.763977 
	C183.464050,209.760437 181.569443,213.311783 180.397583,217.087448 
	C173.957794,237.836273 175.770828,257.610809 187.929459,276.391968 
	C191.778854,281.131958 195.557190,285.529358 199.455170,290.224091 
	C205.465271,294.632172 211.355728,298.742981 217.246185,302.853760 
	C215.855133,303.541199 214.464081,304.228607 213.068848,305.281403 
	C207.240417,301.832825 201.416168,298.018860 194.892761,294.080536 
	C193.943909,294.281281 193.694214,294.606415 193.444504,294.931519 
	C193.444504,294.931519 193.529312,294.818420 193.572449,294.762421 
z"/>
<path fill="#EBC34A" opacity="1.000000" stroke="none" 
	d="
M217.546814,302.883728 
	C211.355728,298.742981 205.465271,294.632172 199.763611,290.169983 
	C200.341812,289.135406 200.731216,288.452240 201.431091,287.824890 
	C202.159943,287.923126 202.578308,287.965546 203.174896,288.285095 
	C205.324265,290.034393 207.295425,291.506561 209.397583,293.271240 
	C211.325592,294.403961 213.122604,295.244141 214.943512,296.077911 
	C214.967392,296.071472 214.928406,296.065582 215.014709,296.291199 
	C215.101013,296.516785 215.511108,296.770691 215.511108,296.770691 
	C215.511108,296.770691 215.993103,296.752655 216.217407,296.849915 
	C216.441711,296.947144 216.922745,297.034851 217.026031,297.250671 
	C217.129333,297.466522 217.513672,297.749054 217.513672,297.749054 
	C217.513672,297.749054 217.990601,297.741974 218.263916,297.927307 
	C219.347000,298.415955 220.156769,298.719299 221.136627,299.265564 
	C221.866165,299.616028 222.425613,299.723572 223.208710,299.924072 
	C223.432358,300.017059 223.915375,300.053253 224.142029,300.330627 
	C226.928436,301.305908 229.488159,302.003815 232.400696,302.814453 
	C233.489273,302.981995 234.225067,303.036835 235.257782,303.334167 
	C237.374603,303.702423 239.194473,303.828125 241.361237,304.042664 
	C243.102341,304.316345 244.496536,304.501160 245.890747,304.686005 
	C245.646301,305.602539 245.401855,306.519043 244.612213,307.813965 
	C242.031342,308.212494 239.995697,308.232666 237.960037,308.252777 
	C236.671463,308.100128 235.382874,307.947479 233.539505,307.628174 
	C232.662354,307.410767 232.339981,307.359985 232.017624,307.309235 
	C231.319611,307.164062 230.621582,307.018921 229.312592,306.641907 
	C227.804626,306.313965 226.907639,306.217926 226.010635,306.121887 
	C226.010635,306.121887 225.590973,305.961334 225.247421,305.744171 
	C224.643372,305.292175 224.349609,305.238068 224.022598,305.364685 
	C223.591873,305.244141 223.161148,305.123566 222.223236,304.727661 
	C221.140381,304.307739 220.564697,304.163208 219.989014,304.018707 
	C219.275162,303.650360 218.561295,303.282013 217.546814,302.883728 
z"/>
<path fill="#FEF9E0" opacity="1.000000" stroke="none" 
	d="
M318.635590,210.307785 
	C318.926605,221.691818 318.938629,233.383377 319.010132,245.541183 
	C318.613922,248.821304 318.158264,251.635178 317.510193,254.779404 
	C317.219818,251.669998 316.967377,248.226379 317.053528,244.791245 
	C317.293976,235.205276 317.665894,225.622513 318.007812,216.039261 
	C318.072357,214.229431 318.237732,212.423203 318.635590,210.307785 
z"/>
<path fill="#FDFAE6" opacity="1.000000" stroke="none" 
	d="
M269.864380,308.865662 
	C264.662750,310.459076 259.257324,311.773102 253.425293,313.023590 
	C258.552643,311.502136 264.106598,310.044189 269.864380,308.865662 
z"/>
<path fill="#FDFAE6" opacity="1.000000" stroke="none" 
	d="
M245.430237,313.923767 
	C244.174072,313.845703 243.305786,313.706757 242.189545,313.353851 
	C244.811584,312.936462 247.681564,312.733032 250.814529,312.844574 
	C249.324371,313.393982 247.571243,313.628448 245.430237,313.923767 
z"/>
<path fill="#FEF9E0" opacity="1.000000" stroke="none" 
	d="
M314.595276,263.169006 
	C313.768127,266.159637 312.639679,269.326355 311.215271,272.667969 
	C312.044220,269.676910 313.169098,266.510986 314.595276,263.169006 
z"/>
<path fill="#FEF9E0" opacity="1.000000" stroke="none" 
	d="
M238.068405,308.604950 
	C239.995697,308.232666 242.031342,308.212494 244.501907,308.156250 
	C244.936813,308.120148 245.112442,308.417480 245.134460,308.588470 
	C242.829926,308.825348 240.503342,308.891205 238.068405,308.604950 
z"/>
<path fill="#FEF9E0" opacity="1.000000" stroke="none" 
	d="
M311.299286,259.858582 
	C311.420227,257.834015 311.856567,255.646896 312.531067,253.181427 
	C312.384369,255.167374 311.999542,257.431671 311.299286,259.858582 
z"/>
<path fill="#FEF9E0" opacity="1.000000" stroke="none" 
	d="
M248.038757,308.984741 
	C250.481949,308.432617 253.273285,308.054718 256.496765,307.738892 
	C254.081558,308.253632 251.234238,308.706299 248.038757,308.984741 
z"/>
<path fill="#FDFAE6" opacity="1.000000" stroke="none" 
	d="
M302.582642,285.014771 
	C302.131714,286.186157 301.339020,287.402008 300.230896,288.708618 
	C300.690613,287.552673 301.465729,286.305939 302.582642,285.014771 
z"/>
<path fill="#FDFAE6" opacity="1.000000" stroke="none" 
	d="
M294.497162,293.958618 
	C293.807007,294.968048 292.759125,295.990936 291.391357,297.057251 
	C292.094116,296.057800 293.116760,295.014923 294.497162,293.958618 
z"/>
<path fill="#FDFAE6" opacity="1.000000" stroke="none" 
	d="
M288.463043,298.966125 
	C287.750397,299.888336 286.667786,300.812836 285.274811,301.785767 
	C286.007294,300.878937 287.050201,299.923676 288.463043,298.966125 
z"/>
<path fill="#FDFAE6" opacity="1.000000" stroke="none" 
	d="
M299.427338,288.937073 
	C299.037109,289.834015 298.298035,290.756470 297.256409,291.779633 
	C297.662048,290.907745 298.370270,289.935150 299.427338,288.937073 
z"/>
<path fill="#FEF9E0" opacity="1.000000" stroke="none" 
	d="
M309.494263,264.937439 
	C309.528809,263.720490 309.928192,262.414337 310.584869,260.868164 
	C310.514465,262.034851 310.186768,263.441559 309.494263,264.937439 
z"/>
<path fill="#FDFAE6" opacity="1.000000" stroke="none" 
	d="
M220.936798,308.557190 
	C219.751541,308.488525 218.489044,308.057892 216.999817,307.372742 
	C218.135239,307.477234 219.497406,307.836212 220.936798,308.557190 
z"/>
<path fill="#FDFAE6" opacity="1.000000" stroke="none" 
	d="
M276.678406,306.025726 
	C275.928711,306.691284 274.800568,307.328339 273.338776,307.969757 
	C274.103424,307.315125 275.201691,306.656158 276.678406,306.025726 
z"/>
<path fill="#FEF9E0" opacity="1.000000" stroke="none" 
	d="
M275.209473,301.958405 
	C275.948151,301.247101 277.084381,300.576721 278.553162,299.876831 
	C277.792816,300.564606 276.699890,301.281952 275.209473,301.958405 
z"/>
<path fill="#FDFAE6" opacity="1.000000" stroke="none" 
	d="
M235.822571,312.494781 
	C235.121429,312.702576 234.263214,312.662933 233.174118,312.392212 
	C233.850662,312.189911 234.758072,312.218628 235.822571,312.494781 
z"/>
<path fill="#FDFAE6" opacity="1.000000" stroke="none" 
	d="
M305.640167,281.036774 
	C305.501099,281.875977 305.029053,282.764313 304.249512,283.749908 
	C304.397034,282.926758 304.852112,282.006317 305.640167,281.036774 
z"/>
<path fill="#FDFAE6" opacity="1.000000" stroke="none" 
	d="
M283.988342,302.107971 
	C283.489288,302.777802 282.600739,303.399445 281.384705,304.050507 
	C281.904449,303.406555 282.751648,302.733185 283.988342,302.107971 
z"/>
<path fill="#FEF9E0" opacity="1.000000" stroke="none" 
	d="
M318.608795,170.255234 
	C318.907959,171.397461 318.967957,172.784836 318.939270,174.581116 
	C318.690247,173.493469 318.529938,171.996918 318.608795,170.255234 
z"/>
<path fill="#FDFAE6" opacity="1.000000" stroke="none" 
	d="
M230.982147,311.478516 
	C230.465805,311.694885 229.898880,311.633026 229.138855,311.349945 
	C229.607697,311.152557 230.269638,311.176422 230.982147,311.478516 
z"/>
<path fill="#FEF9E0" opacity="1.000000" stroke="none" 
	d="
M318.673767,197.202332 
	C318.977966,198.057037 319.037689,199.132965 319.005798,200.605133 
	C318.752563,199.808762 318.590942,198.616135 318.673767,197.202332 
z"/>
<path fill="#FEF9E0" opacity="1.000000" stroke="none" 
	d="
M220.002655,304.285187 
	C220.564697,304.163208 221.140381,304.307739 221.887863,304.703796 
	C221.378540,304.820801 220.697433,304.686249 220.002655,304.285187 
z"/>
<path fill="#FEF9E0" opacity="1.000000" stroke="none" 
	d="
M226.242340,306.377014 
	C226.907639,306.217926 227.804626,306.313965 228.954895,306.647217 
	C228.296783,306.800323 227.385406,306.716217 226.242340,306.377014 
z"/>
<path fill="#FEF9E0" opacity="1.000000" stroke="none" 
	d="
M257.992706,307.309479 
	C258.732208,307.145905 259.471741,306.982330 260.589050,306.867584 
	C260.201813,307.283173 259.436737,307.649902 258.330475,307.839111 
	C257.989288,307.661591 257.992706,307.309479 257.992706,307.309479 
z"/>
<path fill="#FEF9E0" opacity="1.000000" stroke="none" 
	d="
M286.386322,295.023376 
	C286.502411,294.664673 286.918732,294.283875 287.628113,293.880341 
	C287.509644,294.238831 287.098114,294.620087 286.386322,295.023376 
z"/>
<path fill="#FDFAE6" opacity="1.000000" stroke="none" 
	d="
M261.227203,306.969788 
	C261.430847,306.695648 261.915222,306.450500 262.681396,306.250244 
	C262.478088,306.529694 261.993042,306.764252 261.227203,306.969788 
z"/>
<path fill="#FDFAE6" opacity="1.000000" stroke="none" 
	d="
M278.719116,305.029144 
	C278.563049,305.384186 278.099121,305.734741 277.333527,306.077820 
	C277.491669,305.721771 277.951447,305.373230 278.719116,305.029144 
z"/>
<path fill="#FDFAE6" opacity="1.000000" stroke="none" 
	d="
M280.759705,304.051025 
	C280.591003,304.391510 280.113403,304.719910 279.329742,305.041992 
	C279.499359,304.703430 279.975067,304.371155 280.759705,304.051025 
z"/>
<path fill="#FEF9E0" opacity="1.000000" stroke="none" 
	d="
M268.152161,304.949097 
	C268.347260,304.602448 268.864716,304.279266 269.698181,303.974609 
	C269.500946,304.319611 268.987732,304.646118 268.152161,304.949097 
z"/>
<path fill="#FEF9E0" opacity="1.000000" stroke="none" 
	d="
M309.607727,275.128571 
	C309.729614,275.547913 309.632568,276.134613 309.239746,276.788696 
	C309.092285,276.335999 309.240570,275.815979 309.607727,275.128571 
z"/>
<path fill="#FEF9E0" opacity="1.000000" stroke="none" 
	d="
M305.185059,273.824219 
	C305.101593,273.414429 305.289490,272.866974 305.730103,272.166016 
	C305.807404,272.570496 305.631897,273.128510 305.185059,273.824219 
z"/>
<path fill="#FEF9E0" opacity="1.000000" stroke="none" 
	d="
M302.124695,278.804016 
	C302.051056,278.378387 302.265625,277.840393 302.739105,277.156982 
	C302.803009,277.571594 302.607971,278.131622 302.124695,278.804016 
z"/>
<path fill="#FEF9E0" opacity="1.000000" stroke="none" 
	d="
M310.626465,273.126678 
	C310.706787,273.502136 310.567200,274.041565 310.171753,274.704498 
	C310.079468,274.315521 310.243042,273.803101 310.626465,273.126678 
z"/>
<path fill="#FEF9E0" opacity="1.000000" stroke="none" 
	d="
M316.569153,257.147491 
	C316.755310,257.447601 316.758423,257.885315 316.615784,258.620728 
	C316.442017,258.373993 316.414032,257.829529 316.569153,257.147491 
z"/>
<path fill="#FEF9E0" opacity="1.000000" stroke="none" 
	d="
M306.257324,271.813782 
	C306.111298,271.605804 306.221375,271.233582 306.570526,270.677917 
	C306.710876,270.879486 306.612152,271.264526 306.257324,271.813782 
z"/>
<path fill="#FEF9E0" opacity="1.000000" stroke="none" 
	d="
M315.715851,261.148865 
	C315.842560,261.345581 315.722137,261.704803 315.378510,262.245026 
	C315.259766,262.054504 315.364258,261.682922 315.715851,261.148865 
z"/>
<path fill="#FEF9E0" opacity="1.000000" stroke="none" 
	d="
M318.702576,162.093246 
	C318.894806,162.166489 318.938171,162.333237 318.916748,162.750214 
	C318.752502,162.729218 318.653076,162.457993 318.702576,162.093246 
z"/>
<path fill="#FEF9E0" opacity="1.000000" stroke="none" 
	d="
M316.445038,260.137817 
	C316.558929,260.300842 316.516907,260.582581 316.229828,260.916260 
	C316.010468,260.698547 316.111938,260.461334 316.445038,260.137817 
z"/>
<path fill="#FDFAE6" opacity="1.000000" stroke="none" 
	d="
M308.194885,266.995850 
	C308.287506,266.680878 308.380127,266.365875 308.682587,265.910767 
	C308.925415,265.971069 308.958374,266.171509 308.796997,266.680664 
	C308.602631,266.989349 308.194885,266.995850 308.194885,266.995850 
z"/>
<path fill="#FEF9E0" opacity="1.000000" stroke="none" 
	d="
M308.039185,267.207916 
	C308.194885,266.995850 308.602631,266.989349 308.806519,266.985168 
	C308.789154,267.253998 308.567871,267.526947 308.087585,267.871582 
	C307.846893,267.768860 307.865204,267.594421 308.039185,267.207916 
z"/>
<path fill="#FDFAE6" opacity="1.000000" stroke="none" 
	d="
M290.848724,297.127533 
	C290.904114,297.346558 290.709595,297.635071 290.249542,297.964142 
	C290.188965,297.735474 290.393890,297.466278 290.848724,297.127533 
z"/>
<path fill="#FEF9E0" opacity="1.000000" stroke="none" 
	d="
M298.217194,283.849976 
	C298.107147,283.642731 298.250275,283.308075 298.640137,282.836670 
	C298.748016,283.040802 298.609192,283.381683 298.217194,283.849976 
z"/>
<path fill="#FEF9E0" opacity="1.000000" stroke="none" 
	d="
M285.176453,295.895691 
	C285.130341,295.689209 285.322968,295.420502 285.770721,295.118103 
	C285.923889,295.416931 285.720367,295.666595 285.176453,295.895691 
z"/>
<path fill="#FEF9E0" opacity="1.000000" stroke="none" 
	d="
M284.021606,296.852539 
	C283.968689,296.617767 284.191620,296.329620 284.689148,295.989136 
	C284.741638,296.224243 284.519562,296.511719 284.021606,296.852539 
z"/>
<path fill="#FEF9E0" opacity="1.000000" stroke="none" 
	d="
M282.148438,297.910858 
	C281.969574,297.582153 282.168060,297.351318 282.761536,297.254700 
	C282.875610,297.547913 282.665344,297.754517 282.148438,297.910858 
z"/>
<path fill="#FEF9E0" opacity="1.000000" stroke="none" 
	d="
M280.876099,298.812561 
	C280.842712,298.563141 281.109741,298.284302 281.668427,297.964172 
	C281.698914,298.209625 281.437714,298.496399 280.876099,298.812561 
z"/>
<path fill="#FDFAE6" opacity="1.000000" stroke="none" 
	d="
M306.767273,280.091858 
	C306.932892,280.345123 306.755920,280.616638 306.225189,280.890808 
	C306.078705,280.642029 306.263123,280.380920 306.767273,280.091858 
z"/>
<path fill="#FEF9E0" opacity="1.000000" stroke="none" 
	d="
M292.308746,289.963196 
	C292.111145,289.703491 292.258270,289.458191 292.781158,289.275635 
	C292.911835,289.519196 292.744019,289.732300 292.308746,289.963196 
z"/>
<path fill="#FEF9E0" opacity="1.000000" stroke="none" 
	d="
M294.402802,287.916107 
	C294.172577,287.668488 294.299683,287.410553 294.816528,287.180298 
	C294.970886,287.410004 294.822174,287.642609 294.402802,287.916107 
z"/>
<path fill="#FEF9E0" opacity="1.000000" stroke="none" 
	d="
M291.273804,290.930634 
	C291.053223,290.643097 291.216888,290.372253 291.792053,290.156799 
	C291.945282,290.423523 291.763458,290.668579 291.273804,290.930634 
z"/>
<path fill="#FEF9E0" opacity="1.000000" stroke="none" 
	d="
M290.291931,291.927551 
	C290.080536,291.660675 290.232452,291.377899 290.773163,291.115479 
	C290.947601,291.381805 290.778717,291.640411 290.291931,291.927551 
z"/>
<path fill="#FEF9E0" opacity="1.000000" stroke="none" 
	d="
M289.214722,292.905579 
	C289.164520,292.709778 289.346924,292.442749 289.775299,292.124878 
	C289.933807,292.405884 289.742493,292.659332 289.214722,292.905579 
z"/>
<path fill="#FDFAE6" opacity="1.000000" stroke="none" 
	d="
M296.672882,292.065308 
	C296.891418,292.330078 296.747406,292.596558 296.204529,292.812866 
	C296.058624,292.561890 296.226837,292.329987 296.672882,292.065308 
z"/>
<path fill="#FEF9E0" opacity="1.000000" stroke="none" 
	d="
M296.234985,285.919220 
	C296.177765,285.726440 296.349518,285.450928 296.769257,285.117615 
	C296.937592,285.393402 296.753174,285.652283 296.234985,285.919220 
z"/>
<path fill="#FEF9E0" opacity="1.000000" stroke="none" 
	d="
M288.128235,293.850159 
	C288.066254,293.625732 288.256989,293.343628 288.712616,293.020752 
	C288.778656,293.250824 288.579773,293.521637 288.128235,293.850159 
z"/>
<path fill="#FEF9E0" opacity="1.000000" stroke="none" 
	d="
M297.283844,284.912842 
	C297.206848,284.724854 297.353638,284.432800 297.737183,284.052612 
	C297.942352,284.314362 297.786926,284.595764 297.283844,284.912842 
z"/>
<path fill="#FDFAE6" opacity="1.000000" stroke="none" 
	d="
M257.755127,307.279236 
	C257.992706,307.309479 257.989288,307.661591 257.986145,307.837769 
	C257.795624,308.004303 257.608307,307.994598 257.145386,307.922974 
	C256.926941,307.488953 257.142853,307.284943 257.755127,307.279236 
z"/>
<path fill="#FDFAE6" opacity="1.000000" stroke="none" 
	d="
M272.778137,308.039490 
	C272.935333,308.323700 272.767303,308.533356 272.249115,308.602814 
	C272.163269,308.341919 272.347931,308.176025 272.778137,308.039490 
z"/>
<path fill="#FDFAE6" opacity="1.000000" stroke="none" 
	d="
M223.817444,309.699646 
	C223.627335,309.841888 223.258362,309.748688 222.696548,309.431946 
	C222.882004,309.293701 223.260300,309.378937 223.817444,309.699646 
z"/>
<path fill="#FDFAE6" opacity="1.000000" stroke="none" 
	d="
M225.817413,310.507507 
	C225.968964,310.681946 225.487564,310.714813 225.487564,310.714813 
	C225.487564,310.714813 225.058441,310.494049 224.922394,310.293243 
	C225.111420,310.056335 225.404587,310.136536 225.817413,310.507507 
z"/>
<path fill="#FEF9E0" opacity="1.000000" stroke="none" 
	d="
M232.186493,307.490234 
	C232.339981,307.359985 232.662354,307.410767 233.150040,307.654205 
	C233.315369,307.846893 232.816406,307.862366 232.816406,307.862366 
	C232.816406,307.862366 232.355347,307.671265 232.186493,307.490234 
z"/>
<path fill="#FEF9E0" opacity="1.000000" stroke="none" 
	d="
M304.395081,274.884216 
	C304.132904,274.652740 304.235413,274.358490 304.723633,274.020935 
	C304.951355,274.252594 304.834839,274.533875 304.395081,274.884216 
z"/>
<path fill="#FEF9E0" opacity="1.000000" stroke="none" 
	d="
M279.116272,299.856079 
	C279.070068,299.629272 279.275238,299.354889 279.748901,299.064453 
	C279.800812,299.301788 279.584229,299.555145 279.116272,299.856079 
z"/>
<path fill="#FDFAE6" opacity="1.000000" stroke="none" 
	d="
M307.746857,279.010681 
	C307.661163,279.302643 307.575470,279.594604 307.242920,279.940063 
	C306.996094,279.993530 306.991333,279.501038 307.178925,279.254395 
	C307.366516,279.007782 307.746857,279.010681 307.746857,279.010681 
z"/>
<path fill="#FEF9E0" opacity="1.000000" stroke="none" 
	d="
M301.221069,279.903809 
	C301.113983,279.692230 301.243286,279.362213 301.635315,278.944611 
	C301.751160,279.166504 301.604279,279.475922 301.221069,279.903809 
z"/>
<path fill="#FEF9E0" opacity="1.000000" stroke="none" 
	d="
M307.793671,278.754272 
	C307.746857,279.010681 307.366516,279.007782 307.176208,279.008179 
	C307.051422,278.693329 307.202332,278.426392 307.640015,278.092163 
	C307.841125,278.150299 307.840820,278.324066 307.793671,278.754272 
z"/>
<path fill="#FEF9E0" opacity="1.000000" stroke="none" 
	d="
M224.166763,305.529877 
	C224.349609,305.238068 224.643372,305.292175 225.023224,305.729797 
	C224.834061,305.962799 224.556854,305.883636 224.166763,305.529877 
z"/>
<path fill="#FDFAE6" opacity="1.000000" stroke="none" 
	d="
M265.129822,305.965637 
	C264.942169,305.585632 265.162292,305.369385 265.747620,305.466675 
	C265.987671,305.652771 265.982147,306.013763 265.982147,306.013763 
	C265.792267,306.009857 265.602356,306.005951 265.129822,305.965637 
z"/>
<path fill="#FEF9E0" opacity="1.000000" stroke="none" 
	d="
M266.198853,305.927155 
	C265.982147,306.013763 265.987671,305.652771 265.988220,305.472290 
	C266.153351,305.224701 266.317963,305.157562 266.748047,305.116455 
	C266.893646,305.443207 266.694336,305.675903 266.198853,305.927155 
z"/>
<path fill="#FEF9E0" opacity="1.000000" stroke="none" 
	d="
M308.599792,277.045593 
	C308.785950,277.276337 308.650940,277.521210 308.170227,277.748291 
	C308.033905,277.529114 308.185272,277.305481 308.599792,277.045593 
z"/>
<path fill="#FDFAE6" opacity="1.000000" stroke="none" 
	d="
M215.838303,306.711792 
	C215.648346,306.838318 215.296814,306.729675 214.773224,306.392761 
	C214.959702,306.268585 215.318207,306.372620 215.838303,306.711792 
z"/>
<path fill="#FEF9E0" opacity="1.000000" stroke="none" 
	d="
M303.257355,276.844849 
	C303.128052,276.638794 303.248199,276.283508 303.613342,275.772186 
	C303.741180,275.975983 303.623993,276.335785 303.257355,276.844849 
z"/>
<path fill="#CB9632" opacity="1.000000" stroke="none" 
	d="
M285.946472,193.023071 
	C285.984467,208.945175 286.252716,224.872055 285.921875,240.786484 
	C285.821075,245.635559 284.070648,250.450348 282.994568,255.819366 
	C282.913391,256.357910 282.867950,256.728699 282.584106,256.900604 
	C281.880737,258.056519 281.461273,259.040527 281.017883,260.018188 
	C280.994019,260.011871 281.031891,260.044891 280.780945,260.202454 
	C280.318420,260.890747 280.106812,261.421448 279.942596,261.965729 
	C279.989960,261.979309 279.917419,261.912598 279.641724,262.066101 
	C278.865479,263.110260 278.364929,264.000946 277.893982,264.940674 
	C277.923645,264.989746 277.912750,264.875610 277.650085,264.947144 
	C277.228638,265.326782 277.069824,265.634888 276.958618,265.957123 
	C277.006195,265.971222 276.919312,265.921478 276.618958,266.054138 
	C274.508392,268.090637 272.698212,269.994446 270.926819,271.943359 
	C270.965637,271.988403 270.927216,271.875824 270.661469,271.932526 
	C270.202240,272.280579 270.008728,272.571899 269.885071,272.858765 
	C269.954865,272.854248 269.823517,272.806030 269.504089,272.878418 
	C267.765991,273.947052 266.347321,274.943298 264.958191,275.967255 
	C264.987732,275.994965 264.966309,275.916840 264.660370,275.919128 
	C263.874969,276.255920 263.395538,276.590454 262.959473,276.955444 
	C263.002808,276.985901 262.934601,276.904633 262.563538,276.949768 
	C257.767944,278.266449 253.343414,279.537994 248.532196,280.792664 
	C247.120850,280.742981 246.096176,280.710175 245.041611,280.323669 
	C245.035965,279.025085 245.060211,278.080231 245.477524,277.075317 
	C248.620285,276.431366 251.369995,275.847443 254.438049,275.251312 
	C255.168747,275.139801 255.581116,275.040466 256.298279,274.986633 
	C257.110199,274.735657 257.617310,274.439209 258.093414,274.161224 
	C258.062408,274.179657 258.135010,274.175842 258.498688,274.173523 
	C261.608856,272.478394 264.355347,270.785553 267.069031,269.050049 
	C267.036255,269.007416 267.061188,269.112061 267.343933,269.054840 
	C267.882874,268.720673 268.139069,268.443726 268.570068,268.107178 
	C268.744843,268.047546 268.979706,267.762543 269.201111,267.733337 
	C269.709686,267.537445 269.848724,267.288116 270.086487,266.850372 
	C270.547150,266.465881 270.761017,266.187225 271.214050,265.852112 
	C271.748627,265.597992 271.905548,265.321594 271.955811,264.981567 
	C271.987732,264.996613 271.967194,264.929108 272.204956,264.879547 
	C272.752747,264.628418 272.872833,264.346069 272.925537,263.837555 
	C273.048096,263.692230 273.135315,263.322144 273.397003,263.194244 
	C273.858215,262.742859 274.057739,262.419373 274.453369,261.986267 
	C274.879822,261.661438 275.008057,261.396790 275.334534,260.933380 
	C276.504608,259.231415 277.374268,257.678711 278.446411,255.918320 
	C278.833038,255.166504 279.017181,254.622406 279.454559,253.814514 
	C283.576416,243.945221 282.249268,233.911591 282.212463,223.515182 
	C282.449036,213.028061 282.617645,203.004440 283.182312,192.982178 
	C284.367706,192.996735 285.157104,193.009903 285.946472,193.023071 
z"/>
<path fill="#FFFFFF" opacity="1.000000" stroke="none" 
	d="
M282.786255,192.980804 
	C282.617645,203.004440 282.449036,213.028061 281.884216,223.787872 
	C280.995697,231.932892 280.529449,239.343643 279.996307,246.749557 
	C279.819977,249.198868 279.471222,251.635742 279.201324,254.078308 
	C279.017181,254.622406 278.833038,255.166504 278.045166,255.884354 
	C276.639557,256.050659 275.837677,256.043182 275.018768,256.020233 
	C275.001740,256.004761 274.966675,255.974960 274.970093,255.564362 
	C274.932983,254.056366 274.892456,252.958984 274.844971,251.847595 
	C274.838013,251.833588 274.809509,251.815460 274.809509,251.815460 
	C278.469513,249.089249 278.051178,244.919022 278.477997,241.106232 
	C278.638611,239.671707 278.021851,238.167572 278.020721,236.694962 
	C277.997406,206.589233 278.007843,176.483505 278.033325,146.377777 
	C278.034393,145.111526 278.245880,143.845428 278.359314,142.579269 
	C278.889709,142.471329 279.420105,142.363403 279.950500,142.255463 
	C280.304901,144.021027 280.954742,145.784622 280.966461,147.552475 
	C281.052643,160.525620 280.982086,173.499786 281.043915,186.473221 
	C281.052277,188.228500 281.522888,189.981567 282.172180,192.131668 
	C282.638733,192.678726 282.712524,192.829758 282.786255,192.980804 
z"/>
<path fill="#E1B837" opacity="1.000000" stroke="none" 
	d="
M283.182312,192.982178 
	C282.712524,192.829758 282.638733,192.678726 282.525208,192.278778 
	C282.600952,164.374466 282.719238,136.719070 282.829193,109.063644 
	C282.848206,104.275635 282.831879,99.487473 282.831879,94.699387 
	C283.413391,94.577835 283.994873,94.456276 284.576385,94.334717 
	C285.078278,96.261230 286.011169,98.186287 286.017639,100.114464 
	C286.120575,130.773911 286.104950,161.433746 286.031433,192.558289 
	C285.157104,193.009903 284.367706,192.996735 283.182312,192.982178 
z"/>
<path fill="#C78E2A" opacity="1.000000" stroke="none" 
	d="
M276.810608,200.077286 
	C276.870544,212.687332 277.115967,225.300705 276.889282,237.905594 
	C276.810547,242.283051 275.562500,246.639481 274.829041,251.410492 
	C274.809509,251.815460 274.838013,251.833588 274.574097,252.035568 
	C273.548187,253.829468 272.786194,255.421387 272.010742,257.007080 
	C271.997314,257.000854 272.011322,257.027527 271.780823,257.097778 
	C271.246704,257.372101 271.130188,257.653564 271.065491,258.162292 
	C270.930176,258.312103 270.852264,258.708252 270.569885,258.873840 
	C269.232758,260.382446 268.177979,261.725433 267.082214,263.061707 
	C267.041229,263.055023 267.049286,263.137695 266.806152,263.182556 
	C266.230469,263.376740 266.043243,263.632477 266.000671,263.997314 
	C266.000000,264.000000 265.993958,263.999908 265.732849,264.040283 
	C265.299042,264.378601 265.126343,264.676514 264.976532,264.987457 
	C264.999451,265.000458 264.971375,264.956024 264.649902,264.994812 
	C263.708313,265.597656 263.088257,266.161713 262.330505,266.724670 
	C262.192780,266.723602 262.003326,266.923553 261.702576,266.954773 
	C261.117950,267.259308 260.834045,267.532593 260.392639,267.814606 
	C260.235168,267.823334 260.014282,268.048615 259.734222,268.066833 
	C259.193604,268.342010 258.933044,268.598938 258.489227,268.866119 
	C258.305939,268.876343 258.006958,269.089417 257.724121,269.093842 
	C257.175629,269.349518 256.909943,269.600769 256.476746,269.843506 
	C256.309235,269.835022 256.033081,270.025482 255.621735,269.989685 
	C252.175049,270.409058 249.139725,270.864258 245.814972,271.226746 
	C245.323441,271.147736 245.121323,271.161438 244.893173,271.171814 
	C244.867157,271.168488 244.819427,271.146729 244.846252,270.771271 
	C246.238647,269.437866 247.604248,268.479919 249.353958,267.395691 
	C255.695480,266.165863 261.031647,263.929230 265.102051,258.784424 
	C265.935883,257.840118 266.542938,257.081665 267.428467,256.143097 
	C268.195221,255.017654 268.683502,254.072296 269.131287,253.073914 
	C269.090790,253.020905 269.120514,253.151001 269.355042,253.044830 
	C269.682953,252.621475 269.776337,252.304276 269.990112,251.836945 
	C270.110443,251.686798 270.146088,251.303650 270.410278,251.078064 
	C271.084015,249.561356 271.493561,248.270233 272.002838,246.798126 
	C272.102570,246.617142 272.103821,246.203888 272.369446,245.863617 
	C272.919647,236.019760 273.204224,226.516190 273.838776,217.160767 
	C274.609955,222.106918 275.031158,226.904892 275.452393,231.702881 
	C276.000244,225.886124 275.896667,220.093216 276.032593,214.305908 
	C276.144073,209.559570 276.540497,204.819901 276.810608,200.077286 
z"/>
<path fill="#D7A026" opacity="1.000000" stroke="none" 
	d="
M276.882782,199.607117 
	C276.540497,204.819901 276.144073,209.559570 276.032593,214.305908 
	C275.896667,220.093216 276.000244,225.886124 275.452393,231.702881 
	C275.031158,226.904892 274.609955,222.106918 273.733246,216.741180 
	C273.180481,214.447937 273.083221,212.722427 273.048401,210.666641 
	C273.066162,209.892578 273.021515,209.448792 273.065735,208.673828 
	C273.092804,207.893768 273.031006,207.444870 273.033813,206.666183 
	C273.059479,205.891937 273.020538,205.447495 272.981628,205.003052 
	C273.063202,195.971176 273.144775,186.939301 273.117249,177.447449 
	C273.046234,176.547211 273.084351,176.106979 273.160889,174.902679 
	C273.131378,171.753006 273.063416,169.367386 272.995422,166.981750 
	C273.050415,165.923889 273.105377,164.866028 273.240051,163.159409 
	C273.389374,162.325546 273.458984,162.140457 273.701935,161.640121 
	C273.783661,160.361938 273.692017,159.399002 273.600403,158.436050 
	C273.600403,158.436050 273.528503,158.026657 273.604614,157.831085 
	C274.307037,158.430313 274.933319,159.225143 275.607147,160.080276 
	C275.890198,159.502487 276.157928,158.955902 276.654602,158.243469 
	C276.907318,171.764084 276.931122,185.450531 276.882782,199.607117 
z"/>
<path fill="#FDFFFD" opacity="1.000000" stroke="none" 
	d="
M282.867950,256.728699 
	C282.867950,256.728699 282.913391,256.357910 283.011658,256.199280 
	C284.124176,255.198807 285.138397,254.356964 286.603912,253.140518 
	C286.776855,254.800171 286.916962,256.144501 287.118591,257.902283 
	C287.180145,258.315704 287.143372,258.682922 286.797180,258.992310 
	C286.352600,259.660675 286.254181,260.019653 286.155762,260.378632 
	C286.155792,260.378632 286.133881,260.716888 285.802155,260.973083 
	C285.358612,261.539703 285.246796,261.850067 285.135010,262.160431 
	C285.134979,262.160431 285.129303,262.020813 284.786011,262.084961 
	C284.021454,263.150970 283.600189,264.152802 283.178955,265.154663 
	C283.178955,265.154663 283.115112,265.143494 282.782990,265.147949 
	C282.012085,266.145935 281.573303,267.139496 281.134521,268.133057 
	C281.134521,268.133057 281.075378,267.985199 280.746765,268.048340 
	C279.608429,269.401123 278.798706,270.690765 277.988953,271.980377 
	C277.988953,271.980377 278.001221,272.000183 277.769043,272.082367 
	C277.259155,272.346588 277.074951,272.597321 276.984253,272.916748 
	C276.984253,272.916748 276.807098,273.140900 276.347168,273.199036 
	C275.572205,273.768005 275.257172,274.278809 274.942108,274.789581 
	C274.942108,274.789581 274.739868,275.062866 274.273499,275.147156 
	C273.571075,275.540649 273.335022,275.849854 273.098969,276.159058 
	C273.098969,276.159058 273.075378,276.017273 272.719299,276.031860 
	C271.580841,276.038116 270.798492,276.029785 270.006836,276.009827 
	C269.997528,275.998199 269.976837,275.976837 269.974518,275.580627 
	C269.922638,274.391602 269.873077,273.598816 269.823517,272.806030 
	C269.823517,272.806030 269.954865,272.854248 270.152039,272.803955 
	C270.541901,272.461090 270.734558,272.168457 270.927246,271.875824 
	C270.927216,271.875824 270.965637,271.988403 271.305206,271.979431 
	C275.063171,271.401794 276.997070,269.538696 276.919312,265.921478 
	C276.919312,265.921478 277.006195,265.971222 277.210114,265.872559 
	C277.580261,265.474457 277.746521,265.175049 277.912750,264.875610 
	C277.912750,264.875610 277.923645,264.989746 278.257263,264.932800 
	C279.033051,263.888092 279.475220,262.900330 279.917419,261.912598 
	C279.917419,261.912598 279.989960,261.979309 280.216064,261.835999 
	C280.638733,261.143463 280.835327,260.594177 281.031921,260.044891 
	C281.031891,260.044891 280.994019,260.011871 281.375122,259.948242 
	C282.126770,258.832642 282.497375,257.780670 282.867950,256.728699 
z"/>
<path fill="#FEFDF6" opacity="1.000000" stroke="none" 
	d="
M240.417450,369.368713 
	C235.926422,374.086212 230.434448,373.315063 225.170349,373.101440 
	C224.341492,373.067810 222.901825,371.524750 222.883377,370.655426 
	C222.708069,362.392365 222.778854,354.124084 222.778854,345.480469 
	C230.049789,345.986938 237.388535,344.307373 241.700699,351.386871 
	C245.319046,357.327362 244.801147,363.412445 240.417450,369.368713 
z"/>
<path fill="#DDB657" opacity="1.000000" stroke="none" 
	d="
M262.953522,165.189896 
	C262.953522,165.189896 262.772125,164.771667 262.680939,164.563446 
	C263.015717,163.318863 263.441681,162.282501 263.877502,160.943726 
	C263.955414,159.967804 264.023468,159.294296 264.282349,158.196442 
	C264.638367,157.453140 264.545776,157.208328 264.195404,157.037659 
	C264.154633,155.614014 264.113861,154.190369 264.374451,152.073227 
	C264.522736,149.279816 264.369659,147.179901 264.216614,145.079987 
	C264.177399,142.996902 264.138184,140.913818 264.477997,138.245605 
	C265.569244,138.686401 266.769257,139.651398 266.906342,140.749146 
	C267.411011,144.789948 267.563354,148.874710 267.848206,152.942947 
	C267.880615,157.667053 267.913055,162.391144 267.749817,167.667358 
	C267.372131,168.538345 267.461182,168.784073 267.821167,168.956665 
	C267.861481,170.052338 267.901825,171.148026 267.675171,172.823807 
	C267.274536,173.922073 267.140839,174.440247 267.007141,174.958405 
	C266.152130,176.223175 265.297119,177.487946 264.178741,178.982330 
	C263.915375,179.211975 263.863007,179.070114 263.916168,178.588531 
	C263.961304,176.732498 263.953278,175.358047 263.970856,173.649078 
	C263.910767,172.151474 263.825043,170.988373 263.814636,169.459595 
	C263.577789,167.792587 263.265656,166.491241 262.953522,165.189896 
z"/>
<path fill="#DDB657" opacity="1.000000" stroke="none" 
	d="
M255.858734,164.184723 
	C251.539688,164.465042 246.736099,164.643845 241.476410,164.744614 
	C245.804932,164.472122 250.589554,164.277649 255.858734,164.184723 
z"/>
<path fill="#FEF9E0" opacity="1.000000" stroke="none" 
	d="
M215.621429,171.078613 
	C213.575623,172.383484 211.162430,173.688034 208.379395,174.998138 
	C210.424377,173.695236 212.839218,172.386765 215.621429,171.078613 
z"/>
<path fill="#FEF9E0" opacity="1.000000" stroke="none" 
	d="
M239.548492,165.027725 
	C236.547272,165.726028 233.121506,166.352249 229.251251,166.952286 
	C232.245819,166.269287 235.684891,165.612457 239.548492,165.027725 
z"/>
<path fill="#FEF9E0" opacity="1.000000" stroke="none" 
	d="
M207.664032,175.073761 
	C206.598633,176.004791 205.192947,176.997849 203.425354,177.996063 
	C204.483551,177.046051 205.903656,176.090927 207.664032,175.073761 
z"/>
<path fill="#FEF9E0" opacity="1.000000" stroke="none" 
	d="
M227.645126,167.028259 
	C227.179932,167.369370 226.373886,167.675827 225.222305,167.967468 
	C225.685944,167.632980 226.495102,167.313309 227.645126,167.028259 
z"/>
<path fill="#FEF9E0" opacity="1.000000" stroke="none" 
	d="
M220.679199,169.107422 
	C220.486557,169.402847 219.998383,169.683334 219.213867,169.963409 
	C219.406235,169.672836 219.894958,169.382645 220.679199,169.107422 
z"/>
<path fill="#FEF9E0" opacity="1.000000" stroke="none" 
	d="
M223.662125,168.115540 
	C223.444656,168.420898 222.922607,168.684433 222.091766,168.931747 
	C222.307831,168.634903 222.832672,168.354294 223.662125,168.115540 
z"/>
<path fill="#FEF9E0" opacity="1.000000" stroke="none" 
	d="
M217.699677,170.213593 
	C217.701248,170.423630 217.439896,170.631607 216.921982,170.864639 
	C216.922531,170.663651 217.179642,170.437592 217.699677,170.213593 
z"/>
<path fill="#E5BD52" opacity="1.000000" stroke="none" 
	d="
M174.219131,366.727081 
	C174.097382,363.515472 174.250153,360.047821 174.671722,356.295410 
	C174.791565,359.497437 174.642609,362.984222 174.219131,366.727081 
z"/>
<path fill="#E5BD52" opacity="1.000000" stroke="none" 
	d="
M166.320740,375.969360 
	C166.856873,375.060181 167.720428,374.130066 168.938461,373.286987 
	C168.411346,374.232147 167.529739,375.090302 166.320740,375.969360 
z"/>
<path fill="#E5BD52" opacity="1.000000" stroke="none" 
	d="
M161.357697,378.028748 
	C162.218597,377.384949 163.429581,376.752563 165.018280,376.202148 
	C164.166580,376.869507 162.937180,377.454834 161.357697,378.028748 
z"/>
<path fill="#EBC452" opacity="1.000000" stroke="none" 
	d="
M209.182556,366.423889 
	C208.904953,364.857178 208.976196,363.221008 209.127594,361.169495 
	C209.315643,362.620911 209.423523,364.487701 209.182556,366.423889 
z"/>
<path fill="#E5BD52" opacity="1.000000" stroke="none" 
	d="
M171.622925,371.175720 
	C171.670380,370.540405 172.008484,369.813354 172.686996,369.050903 
	C172.656128,369.704987 172.284851,370.394470 171.622925,371.175720 
z"/>
<path fill="#E5BD52" opacity="1.000000" stroke="none" 
	d="
M169.662872,373.236481 
	C169.769958,372.602600 170.169510,371.906586 170.928070,371.269684 
	C170.843170,371.943970 170.399261,372.559174 169.662872,373.236481 
z"/>
<path fill="#E5BD52" opacity="1.000000" stroke="none" 
	d="
M181.040207,371.268524 
	C181.280609,371.223511 181.532227,371.454498 181.837723,371.940186 
	C181.611542,371.978149 181.331482,371.761353 181.040207,371.268524 
z"/>
<path fill="#DDB657" opacity="1.000000" stroke="none" 
	d="
M313.752594,240.513748 
	C313.407410,238.584305 313.202545,236.209549 312.996704,232.944763 
	C312.995758,224.176712 312.995758,216.298645 312.995758,208.420593 
	C312.536255,208.399414 312.076782,208.378235 311.617279,208.357056 
	C311.414001,208.912598 311.210724,209.468124 310.809998,210.035355 
	C310.612549,210.047043 310.217529,210.068359 310.217529,210.068359 
	C310.141266,185.958084 310.061920,161.847824 309.990997,137.737534 
	C309.975128,132.339996 309.988678,126.942360 309.988678,121.544769 
	C310.621460,121.398804 311.254242,121.252831 311.887024,121.106857 
	C312.576324,123.171440 313.861115,125.234428 313.866638,127.300835 
	C313.967041,164.889786 313.913635,202.479141 313.752594,240.513748 
M312.923462,184.099609 
	C312.923462,176.615128 312.923462,169.130630 312.923462,161.646149 
	C312.297119,161.643829 311.670776,161.641510 311.044434,161.639206 
	C311.044434,169.432419 311.044434,177.225647 311.013062,185.947891 
	C311.013062,190.424530 311.013062,194.901169 311.013062,199.377808 
	C311.625153,199.380310 312.237274,199.382812 312.849365,199.385315 
	C312.849365,194.603149 312.849365,189.820969 312.923462,184.099609 
z"/>
<path fill="#C78E2A" opacity="1.000000" stroke="none" 
	d="
M311.007446,210.023666 
	C311.210724,209.468124 311.414001,208.912598 311.617279,208.357056 
	C312.076782,208.378235 312.536255,208.399414 312.995758,208.420593 
	C312.995758,216.298645 312.995758,224.176712 312.980103,232.521942 
	C312.230347,230.566422 311.496185,228.143707 310.543640,225.416504 
	C310.269989,223.657501 310.214752,222.202972 310.466522,219.999695 
	C310.851501,216.175186 310.929474,213.099426 311.007446,210.023666 
z"/>
<path fill="#C89031" opacity="1.000000" stroke="none" 
	d="
M310.809998,210.035355 
	C310.929474,213.099426 310.851501,216.175186 310.553101,219.571426 
	C310.261902,216.914368 310.191071,213.936813 310.168884,210.513824 
	C310.217529,210.068359 310.612549,210.047043 310.809998,210.035355 
z"/>
<path fill="#FEFCF0" opacity="1.000000" stroke="none" 
	d="
M149.956451,344.775055 
	C154.244812,345.782684 158.520187,345.859802 162.040619,347.529083 
	C167.665649,350.196320 169.683594,355.971832 168.371262,362.307709 
	C166.984055,369.005066 163.492905,372.258545 156.705460,373.179443 
	C147.064819,374.487427 147.063385,374.487427 147.065048,364.837128 
	C147.065994,359.367218 146.911896,353.889038 147.191772,348.433289 
	C147.259979,347.104095 148.761978,345.848450 149.956451,344.775055 
z"/>
<path fill="#E5BD52" opacity="1.000000" stroke="none" 
	d="
M170.358276,345.283630 
	C170.725052,345.737976 170.826370,346.101685 170.938065,346.749481 
	C170.317612,346.573700 169.686768,346.113770 169.022430,345.335144 
	C169.356888,345.075256 169.724838,345.134094 170.358276,345.283630 
z"/>
<path fill="#E5BD52" opacity="1.000000" stroke="none" 
	d="
M170.986053,347.352234 
	C171.363617,347.453125 171.763123,347.859253 172.183655,348.565308 
	C171.805771,348.462646 171.406891,348.060089 170.986053,347.352234 
z"/>
<path fill="#E5BD52" opacity="1.000000" stroke="none" 
	d="
M167.085434,343.672241 
	C167.268600,343.378174 167.499741,343.429688 167.715134,343.894409 
	C167.530624,344.048950 167.341965,343.952362 167.085434,343.672241 
z"/>
<path fill="#FEFDF6" opacity="1.000000" stroke="none" 
	d="
M347.330444,375.046570 
	C346.927673,374.201904 346.851685,373.402588 346.592773,372.368591 
	C346.352600,371.938538 346.295349,371.743225 346.453918,370.985809 
	C346.741180,369.226990 346.812653,368.030243 346.884155,366.833496 
	C346.884155,366.833496 346.943085,366.895233 347.172607,366.817017 
	C347.575806,366.414093 347.749451,366.089355 347.923126,365.764618 
	C347.923126,365.764618 347.870056,365.373596 348.168274,365.041565 
	C348.295044,364.135773 348.123566,363.561951 347.952057,362.988159 
	C347.952057,362.988159 347.808105,362.651855 347.846313,362.235657 
	C347.816559,361.496368 347.645447,361.243134 347.371124,361.059723 
	C347.177124,360.324310 346.983124,359.588867 347.033051,358.361816 
	C347.515747,357.580933 347.754517,357.291687 347.993286,357.002441 
	C348.492706,356.739044 348.992126,356.475616 349.754639,356.106812 
	C350.457794,357.556824 350.897919,359.112244 351.749023,360.981049 
	C352.160004,361.294403 352.167419,361.640076 352.173889,361.914551 
	C352.411469,362.457520 352.642609,362.725983 353.102570,363.266663 
	C358.932617,365.853729 364.533844,368.168610 370.774475,370.747742 
	C369.297028,372.454620 368.181152,373.743774 366.664490,374.998627 
	C363.238708,373.627106 360.213715,372.289917 357.004364,370.757019 
	C356.543823,370.334473 356.267609,370.107666 355.762482,369.636810 
	C354.119293,369.841095 352.705017,370.289459 351.409485,371.052063 
	C351.647949,371.555634 351.767639,371.744934 351.781891,371.957092 
	C351.676483,371.979950 351.462524,372.007721 351.384308,372.147827 
	C351.265808,372.646729 351.423737,372.885773 351.886566,373.218872 
	C351.993317,373.432617 352.036987,373.908447 352.069336,374.129608 
	C352.214325,374.643555 352.412994,374.855743 352.831909,375.188507 
	C352.966095,375.389679 353.071228,375.861786 353.031891,376.148102 
	C352.979828,376.626648 352.967072,376.818909 352.954346,377.011169 
	C351.188629,376.371429 349.422913,375.731659 347.330444,375.046570 
z"/>
<path fill="#FFFFFF" opacity="1.000000" stroke="none" 
	d="
M323.016418,350.009155 
	C320.334564,348.292786 317.652679,346.576447 314.869385,344.795197 
	C317.669647,339.001160 322.403717,340.746338 325.734314,341.336456 
	C328.095490,341.754791 329.924561,345.176575 331.598328,347.632874 
	C328.475800,348.675049 325.746094,349.342102 323.016418,350.009155 
z"/>
<path fill="#FEFCE6" opacity="1.000000" stroke="none" 
	d="
M322.809753,350.284454 
	C325.746094,349.342102 328.475800,348.675049 331.602722,348.005829 
	C335.288208,349.144867 338.576416,350.286072 341.859131,351.802734 
	C339.497223,351.492737 337.177765,350.411621 334.778290,350.187439 
	C321.624176,348.958374 314.673981,358.717072 318.957001,372.630127 
	C314.326904,370.471252 313.033661,366.213867 315.461945,361.845459 
	C317.630432,357.944336 317.713501,352.738525 322.809753,350.284454 
z"/>
<path fill="#FEFCE6" opacity="1.000000" stroke="none" 
	d="
M347.844788,356.704926 
	C347.754517,357.291687 347.515747,357.580933 347.011841,357.980103 
	C346.141510,357.288147 345.536346,356.486267 344.885315,355.378021 
	C345.791687,355.516907 346.743988,355.962158 347.844788,356.704926 
z"/>
<path fill="#FEFCE6" opacity="1.000000" stroke="none" 
	d="
M343.889709,353.849884 
	C343.699524,353.896027 343.433258,353.692993 343.078064,353.247345 
	C343.263977,353.203400 343.538788,353.402039 343.889709,353.849884 
z"/>
<path fill="#FEFCE6" opacity="1.000000" stroke="none" 
	d="
M342.880310,352.776733 
	C342.674194,352.877472 342.352631,352.744720 341.939209,352.361755 
	C342.153198,352.255402 342.459015,352.399323 342.880310,352.776733 
z"/>
<path fill="#FEFCE6" opacity="1.000000" stroke="none" 
	d="
M329.296204,362.983093 
	C327.411743,362.886749 325.979340,362.790222 324.229218,362.672302 
	C324.791534,357.603271 327.381104,355.197601 331.969696,354.883057 
	C337.155121,354.527618 339.395660,356.382751 341.026215,362.982910 
	C337.130280,362.982910 333.439270,362.982910 329.296204,362.983093 
z"/>
<path fill="#FDFFFD" opacity="1.000000" stroke="none" 
	d="
M336.972839,367.163574 
	C337.724670,367.107452 338.476532,367.051331 339.607178,367.459991 
	C340.013916,368.978912 340.041809,370.033051 340.069702,371.087219 
	C340.069733,371.087219 340.004486,371.000946 339.676147,371.010254 
	C337.557373,372.295685 335.766937,373.571777 333.976501,374.847870 
	C332.895050,374.866211 331.813568,374.884552 330.223083,374.605652 
	C329.500519,374.123962 329.252747,374.027588 328.970734,374.019257 
	C328.970734,374.019257 329.000702,374.000580 328.963318,373.642487 
	C328.285339,371.780273 327.644745,370.276184 326.936493,368.613159 
	C330.722961,368.066254 333.847900,367.614899 336.972839,367.163574 
z"/>
<path fill="#FEFCE6" opacity="1.000000" stroke="none" 
	d="
M336.518982,367.078735 
	C333.847900,367.614899 330.722961,368.066254 326.936493,368.613159 
	C327.644745,370.276184 328.285339,371.780273 328.952637,373.656921 
	C327.189423,372.188232 325.399475,370.347015 323.609558,368.505829 
	C323.887543,368.001862 324.165497,367.497894 324.443481,366.993927 
	C328.317352,366.993927 332.191223,366.993927 336.518982,367.078735 
z"/>
<path fill="#FEFCE6" opacity="1.000000" stroke="none" 
	d="
M340.408020,370.966370 
	C340.041809,370.033051 340.013916,368.978912 339.979492,367.531067 
	C341.991211,367.017181 344.009491,366.896942 346.455963,366.805084 
	C346.812653,368.030243 346.741180,369.226990 346.427094,370.681671 
	C344.371796,370.908264 342.559052,370.876892 340.408020,370.966370 
z"/>
<path fill="#FEFCE6" opacity="1.000000" stroke="none" 
	d="
M334.331696,374.919312 
	C335.766937,373.571777 337.557373,372.295685 339.718079,371.044556 
	C338.287872,372.376617 336.487366,373.683685 334.331696,374.919312 
z"/>
<path fill="#FEFCE6" opacity="1.000000" stroke="none" 
	d="
M347.886627,363.323364 
	C348.123566,363.561951 348.295044,364.135773 348.235321,364.856018 
	C347.943146,364.554504 347.882172,364.106506 347.886627,363.323364 
z"/>
<path fill="#FEFCE6" opacity="1.000000" stroke="none" 
	d="
M347.340942,361.300903 
	C347.645447,361.243134 347.816559,361.496368 347.887146,362.057343 
	C347.574066,362.138519 347.381042,361.887451 347.340942,361.300903 
z"/>
<path fill="#FEFCE6" opacity="1.000000" stroke="none" 
	d="
M329.054199,374.229065 
	C329.252747,374.027588 329.500519,374.123962 329.839203,374.466278 
	C329.964386,374.624176 329.517365,374.681824 329.517365,374.681824 
	C329.517365,374.681824 329.137665,374.438904 329.054199,374.229065 
z"/>
<path fill="#FEFCE6" opacity="1.000000" stroke="none" 
	d="
M347.681519,365.892944 
	C347.749451,366.089355 347.575806,366.414093 347.133057,366.833008 
	C347.055969,366.625214 347.247955,366.323242 347.681519,365.892944 
z"/>
<path fill="#FDFFFD" opacity="1.000000" stroke="none" 
	d="
M246.154984,304.621948 
	C244.496536,304.501160 243.102341,304.316345 241.358093,303.646881 
	C241.021805,301.847412 241.035553,300.532593 241.049286,299.217712 
	C241.493317,299.180420 241.937347,299.143127 243.007538,299.407349 
	C246.416153,299.550568 249.198578,299.392303 251.981003,299.234039 
	C252.432297,299.188110 252.883606,299.142181 253.903076,299.341461 
	C255.660583,299.106262 256.849915,298.625854 258.039246,298.145447 
	C258.432800,297.992889 258.826385,297.840332 259.729614,297.998108 
	C263.233917,297.463684 267.013458,298.509460 268.800079,294.754578 
	C268.800079,294.754578 268.914642,294.798309 269.117676,294.792664 
	C269.548920,294.535492 269.777100,294.283936 270.005280,294.032379 
	C270.005280,294.032379 270.237457,293.816833 270.691986,293.811646 
	C271.435150,293.557098 271.723816,293.307739 272.012451,293.058350 
	C272.012451,293.058350 272.311310,292.859680 272.878876,292.898438 
	C274.265472,292.247864 275.084503,291.558563 275.903534,290.869263 
	C275.903534,290.869263 276.014862,290.925415 276.317383,290.951355 
	C277.408752,290.311035 278.197601,289.644806 278.986450,288.978546 
	C278.986450,288.978546 278.998810,289.001221 279.366455,289.010376 
	C280.490143,289.012329 281.246155,289.005066 282.010834,289.406616 
	C282.096375,290.631317 282.173248,291.447296 282.250122,292.263245 
	C282.250122,292.263245 282.134155,292.243835 281.908691,292.252686 
	C281.487183,292.559784 281.291107,292.858032 281.095032,293.156281 
	C281.095032,293.156281 281.052338,293.025024 280.723328,293.036499 
	C279.633057,293.748718 278.871796,294.449463 278.110535,295.150208 
	C278.110535,295.150208 277.996338,295.113770 277.666260,295.068756 
	C275.896301,296.021759 274.456360,297.019775 273.016449,298.017822 
	C273.016449,298.017822 273.000000,298.000000 272.807434,298.041565 
	C272.348022,298.184937 272.148834,298.362640 272.017365,298.616211 
	C271.642426,298.772003 271.267487,298.927826 270.247375,299.071655 
	C269.087250,299.418701 268.572205,299.777771 268.057159,300.136841 
	C268.057159,300.136841 268.010559,300.040070 267.657776,300.026733 
	C265.532379,300.692719 263.759766,301.372009 261.987152,302.051300 
	C261.713593,302.254333 261.406708,302.365601 260.518066,302.297668 
	C259.644409,302.436829 259.319214,302.663391 258.994049,302.889954 
	C258.574066,302.990265 258.154053,303.090546 257.158844,302.869019 
	C253.714447,303.063843 250.845276,303.580505 247.976105,304.097198 
	C247.457138,304.250763 246.938171,304.404327 246.154984,304.621948 
z"/>
<path fill="#FDFFFD" opacity="1.000000" stroke="none" 
	d="
M286.784821,282.526367 
	C286.784821,282.526367 286.877716,282.205994 287.209351,282.018127 
	C287.789459,281.656891 287.955261,281.424500 288.038361,281.133087 
	C288.038361,281.133087 288.043701,281.018341 288.301392,280.933289 
	C288.811005,280.659882 288.968079,280.411560 289.030365,280.103271 
	C289.030365,280.103271 289.007751,280.030365 289.262512,279.914703 
	C289.786285,279.583771 289.939453,279.304871 289.976868,278.962341 
	C289.976868,278.962341 290.000793,279.000000 290.234802,278.875153 
	C290.616180,278.420837 290.763550,278.091370 290.910919,277.761902 
	C290.914703,277.465698 291.029297,277.221497 291.793213,276.966736 
	C293.214294,276.597656 294.096893,276.291138 294.979492,275.984650 
	C294.979492,275.984650 295.001892,276.002380 295.011108,276.438812 
	C295.058075,278.019012 295.095825,279.162811 295.133545,280.306580 
	C295.095886,280.596924 294.954987,280.822845 294.147766,281.040649 
	C292.263489,282.019958 291.041351,283.216522 289.577789,283.746307 
	C288.884796,283.997131 287.730255,282.972900 286.784821,282.526367 
z"/>
<path fill="#FEF9E0" opacity="1.000000" stroke="none" 
	d="
M286.474609,282.639404 
	C287.730255,282.972900 288.884796,283.997131 289.577789,283.746307 
	C291.041351,283.216522 292.263489,282.019958 293.958313,281.118408 
	C291.857788,283.781860 289.383575,286.423859 286.493958,289.073486 
	C286.078522,289.081177 285.888336,288.887970 285.922943,288.537354 
	C285.032166,287.521301 284.106781,286.855865 283.104218,286.111694 
	C283.027039,286.032959 282.871490,285.876678 282.871490,285.876678 
	C283.969116,284.835266 285.066772,283.793854 286.474609,282.639404 
z"/>
<path fill="#FEF9E0" opacity="1.000000" stroke="none" 
	d="
M248.411621,304.164612 
	C250.845276,303.580505 253.714447,303.063843 256.833374,302.857025 
	C254.337814,303.521942 251.592468,303.876984 248.411621,304.164612 
z"/>
<path fill="#FEF9E0" opacity="1.000000" stroke="none" 
	d="
M295.423004,280.133209 
	C295.095825,279.162811 295.058075,278.019012 295.021301,276.448792 
	C295.846405,276.032410 296.670532,276.042511 297.906799,276.094574 
	C297.450134,277.410950 296.581299,278.685394 295.423004,280.133209 
z"/>
<path fill="#FEF9E0" opacity="1.000000" stroke="none" 
	d="
M273.351807,297.993469 
	C274.456360,297.019775 275.896301,296.021759 277.726074,295.070312 
	C276.639648,296.067627 275.163422,297.018372 273.351807,297.993469 
z"/>
<path fill="#FEF9E0" opacity="1.000000" stroke="none" 
	d="
M282.560577,292.200012 
	C282.173248,291.447296 282.096375,290.631317 282.009766,289.407684 
	C282.000000,289.000000 281.997070,289.002014 282.388123,289.008606 
	C283.815552,288.972809 284.851959,288.930389 285.888336,288.887970 
	C285.888336,288.887970 286.078522,289.081177 286.174103,289.178101 
	C285.136810,290.228943 284.003906,291.182861 282.560577,292.200012 
z"/>
<path fill="#FEF9E0" opacity="1.000000" stroke="none" 
	d="
M262.360657,302.087189 
	C263.759766,301.372009 265.532379,300.692719 267.702271,300.056824 
	C266.311096,300.774506 264.522644,301.448792 262.360657,302.087189 
z"/>
<path fill="#FEF9E0" opacity="1.000000" stroke="none" 
	d="
M301.329651,271.937042 
	C301.492889,270.817017 301.911926,269.480560 302.704742,268.099915 
	C302.580841,269.277374 302.083130,270.498993 301.329651,271.937042 
z"/>
<path fill="#FEF9E0" opacity="1.000000" stroke="none" 
	d="
M278.428802,295.122925 
	C278.871796,294.449463 279.633057,293.748718 280.767090,293.089844 
	C280.342255,293.786377 279.544647,294.441010 278.428802,295.122925 
z"/>
<path fill="#FEF9E0" opacity="1.000000" stroke="none" 
	d="
M268.380157,300.145569 
	C268.572205,299.777771 269.087250,299.418701 269.942078,299.093506 
	C269.755676,299.469696 269.229401,299.812012 268.380157,300.145569 
z"/>
<path fill="#FEF9E0" opacity="1.000000" stroke="none" 
	d="
M304.290436,265.875519 
	C304.183655,265.482239 304.320404,264.911377 304.701935,264.162476 
	C304.809174,264.555603 304.671570,265.126770 304.290436,265.875519 
z"/>
<path fill="#FEF9E0" opacity="1.000000" stroke="none" 
	d="
M303.315796,267.896179 
	C303.216553,267.486206 303.369690,266.908356 303.792725,266.177429 
	C303.897827,266.592377 303.733032,267.160339 303.315796,267.896179 
z"/>
<path fill="#FEF9E0" opacity="1.000000" stroke="none" 
	d="
M305.393829,263.094360 
	C305.273193,262.653656 305.410553,261.997620 305.841187,261.154846 
	C305.973602,261.605103 305.812714,262.242096 305.393829,263.094360 
z"/>
<path fill="#FEF9E0" opacity="1.000000" stroke="none" 
	d="
M306.446411,260.338867 
	C306.287048,260.147522 306.359528,259.764679 306.658203,259.188721 
	C306.815674,259.379486 306.746948,259.763458 306.446411,260.338867 
z"/>
<path fill="#FEF9E0" opacity="1.000000" stroke="none" 
	d="
M272.240295,298.644867 
	C272.148834,298.362640 272.348022,298.184937 272.816101,298.049500 
	C272.967010,298.348328 272.782288,298.567535 272.240295,298.644867 
z"/>
<path fill="#FEF9E0" opacity="1.000000" stroke="none" 
	d="
M281.361816,293.098755 
	C281.291107,292.858032 281.487183,292.559784 281.960938,292.225708 
	C282.035309,292.473633 281.831970,292.757416 281.361816,293.098755 
z"/>
<path fill="#FEF9E0" opacity="1.000000" stroke="none" 
	d="
M299.273499,275.012573 
	C299.041931,274.774445 299.163605,274.485901 299.609589,274.121948 
	C299.710358,274.300690 299.607971,274.605865 299.273499,275.012573 
z"/>
<path fill="#FEF9E0" opacity="1.000000" stroke="none" 
	d="
M259.299744,302.911011 
	C259.319214,302.663391 259.644409,302.436829 260.278809,302.233215 
	C260.260468,302.481476 259.932953,302.706757 259.299744,302.911011 
z"/>
<path fill="#FDFFFD" opacity="1.000000" stroke="none" 
	d="
M245.071518,280.677368 
	C246.096176,280.710175 247.120850,280.742981 248.756653,281.096375 
	C252.641251,280.935181 255.977875,280.694580 259.159393,279.861542 
	C260.578552,279.489960 261.685791,277.926941 262.934601,276.904633 
	C262.934601,276.904633 263.002808,276.985901 263.273560,276.973083 
	C264.018280,276.612427 264.492310,276.264618 264.966309,275.916840 
	C264.966309,275.916840 264.987732,275.994965 265.379456,275.997955 
	C267.173065,275.992889 268.574951,275.984863 269.976837,275.976837 
	C269.976837,275.976837 269.997528,275.998199 269.880493,276.301147 
	C269.505280,277.396454 269.247070,278.188812 268.988892,278.981171 
	C268.988892,278.981171 268.997864,279.002563 268.787689,279.033173 
	C268.313232,279.171539 268.121490,279.355072 268.002350,279.614441 
	C268.002350,279.614441 267.630310,279.927460 267.155151,280.025146 
	C266.358643,280.244110 266.128021,280.461639 265.988190,280.775360 
	C265.988190,280.775360 265.584534,280.986725 264.941803,280.995911 
	C256.068359,282.032104 247.835617,283.043854 239.611176,284.118988 
	C239.033371,284.194519 238.516769,284.738159 237.971695,285.064148 
	C237.539566,285.034790 237.107437,285.005463 236.257294,284.530518 
	C234.180756,283.751740 232.522232,283.418518 230.863693,283.085327 
	C230.863693,283.085327 230.435043,283.049591 230.176971,282.596710 
	C227.889633,281.463409 225.860382,280.782990 223.831146,280.102539 
	C223.317581,279.918060 222.804016,279.733612 222.141876,278.931854 
	C223.109161,277.453339 224.225021,276.592163 225.671967,275.799133 
	C227.664886,276.651825 229.326752,277.436340 231.133194,278.420197 
	C231.545212,278.850922 231.854111,278.941956 232.500443,278.920929 
	C233.176346,279.061188 233.556259,279.173187 234.041077,279.613342 
	C236.447006,280.244751 238.748032,280.548004 241.453796,280.840759 
	C242.929535,280.779266 244.000534,280.728333 245.071518,280.677368 
z"/>
<path fill="#FDF7D8" opacity="1.000000" stroke="none" 
	d="
M238.276581,285.308868 
	C238.516769,284.738159 239.033371,284.194519 239.611176,284.118988 
	C247.835617,283.043854 256.068359,282.032104 264.717102,281.033417 
	C258.726257,282.748779 252.317383,284.435791 245.469345,286.189453 
	C242.880600,286.021912 240.731049,285.787750 238.276581,285.308868 
z"/>
<path fill="#FDF7D8" opacity="1.000000" stroke="none" 
	d="
M278.286713,271.855774 
	C278.798706,270.690765 279.608429,269.401123 280.785156,268.119751 
	C280.296295,269.329071 279.440399,270.530090 278.286713,271.855774 
z"/>
<path fill="#FDF7D8" opacity="1.000000" stroke="none" 
	d="
M269.312195,278.932617 
	C269.247070,278.188812 269.505280,277.396454 269.889771,276.312805 
	C270.798492,276.029785 271.580841,276.038116 272.753235,276.095215 
	C271.973999,277.057343 270.804718,277.970673 269.312195,278.932617 
z"/>
<path fill="#FDF7D8" opacity="1.000000" stroke="none" 
	d="
M283.473114,265.033417 
	C283.600189,264.152802 284.021454,263.150970 284.813995,262.148987 
	C284.712646,263.069946 284.239990,263.991058 283.473114,265.033417 
z"/>
<path fill="#FDF7D8" opacity="1.000000" stroke="none" 
	d="
M281.427155,268.009460 
	C281.573303,267.139496 282.012085,266.145935 282.820557,265.161041 
	C282.700104,266.075073 282.209930,266.980469 281.427155,268.009460 
z"/>
<path fill="#FDF7D8" opacity="1.000000" stroke="none" 
	d="
M295.552612,240.900665 
	C295.860504,241.770859 295.929443,242.943924 295.779175,244.558624 
	C295.560028,245.000244 295.140442,245.006851 295.140442,245.006851 
	C295.198181,243.739075 295.255920,242.471298 295.552612,240.900665 
z"/>
<path fill="#FEF9E0" opacity="1.000000" stroke="none" 
	d="
M295.057312,245.376694 
	C295.140442,245.006851 295.560028,245.000244 295.769562,244.996704 
	C295.840790,245.854538 295.702484,246.715912 295.311035,247.790527 
	C295.029999,247.251343 295.002075,246.498947 295.057312,245.376694 
z"/>
<path fill="#FDF7D8" opacity="1.000000" stroke="none" 
	d="
M275.226105,274.707581 
	C275.257172,274.278809 275.572205,273.768005 276.207733,273.230103 
	C276.188873,273.677216 275.849487,274.151398 275.226105,274.707581 
z"/>
<path fill="#FDF7D8" opacity="1.000000" stroke="none" 
	d="
M277.232849,272.871185 
	C277.074951,272.597321 277.259155,272.346588 277.758087,272.078491 
	C277.813385,272.270142 277.647400,272.547882 277.232849,272.871185 
z"/>
<path fill="#FDF7D8" opacity="1.000000" stroke="none" 
	d="
M285.375122,262.052368 
	C285.246796,261.850067 285.358612,261.539703 285.690613,261.100708 
	C285.812286,261.296204 285.713745,261.620270 285.375122,262.052368 
z"/>
<path fill="#FDF7D8" opacity="1.000000" stroke="none" 
	d="
M286.391815,260.215942 
	C286.254181,260.019653 286.352600,259.660675 286.687866,259.140991 
	C286.825775,259.337921 286.726837,259.695557 286.391815,260.215942 
z"/>
<path fill="#FDF7D8" opacity="1.000000" stroke="none" 
	d="
M266.244537,280.785950 
	C266.128021,280.461639 266.358643,280.244110 266.922119,280.094421 
	C266.943115,280.309540 266.722015,280.553040 266.244537,280.785950 
z"/>
<path fill="#FDF7D8" opacity="1.000000" stroke="none" 
	d="
M273.381042,276.091064 
	C273.335022,275.849854 273.571075,275.540649 274.111511,275.198700 
	C274.165009,275.451660 273.914062,275.737396 273.381042,276.091064 
z"/>
<path fill="#FDF7D8" opacity="1.000000" stroke="none" 
	d="
M268.220917,279.625916 
	C268.121490,279.355072 268.313232,279.171539 268.777405,279.026245 
	C268.921509,279.307343 268.742218,279.523590 268.220917,279.625916 
z"/>
<path fill="#FEFDF6" opacity="1.000000" stroke="none" 
	d="
M187.177307,362.177795 
	C189.030960,359.518494 190.603119,356.292877 193.091187,355.247711 
	C196.679520,353.740417 200.194626,355.861481 202.374176,359.066925 
	C203.951126,361.386139 202.580521,362.914856 200.086212,362.944611 
	C195.827835,362.995422 191.565628,362.722595 187.177307,362.177795 
z"/>
<path fill="#E5BD52" opacity="1.000000" stroke="none" 
	d="
M185.890594,376.798431 
	C185.675354,376.888275 185.358475,376.736450 184.971710,376.317322 
	C185.197525,376.218903 185.493225,376.387848 185.890594,376.798431 
z"/>
<path fill="#FFFFFF" opacity="1.000000" stroke="none" 
	d="
M375.826050,357.020966 
	C376.189636,356.560638 376.553223,356.100281 376.934692,355.328522 
	C377.911316,356.145874 378.870117,357.274689 379.828918,358.403503 
	C378.814148,358.934753 377.799408,359.465973 376.402466,359.997986 
	C375.988434,359.775330 375.956635,359.551880 375.844849,358.724884 
	C375.785278,357.754547 375.805664,357.387756 375.826050,357.020966 
z"/>
<path fill="#FFFFFF" opacity="1.000000" stroke="none" 
	d="
M377.161560,377.210693 
	C376.963104,376.952271 376.978699,376.476105 376.987549,376.238068 
	C377.825867,376.439667 378.655304,376.879333 379.484772,377.318970 
	C378.776520,377.369019 378.068298,377.419037 377.161560,377.210693 
z"/>
<path fill="#E5BD52" opacity="1.000000" stroke="none" 
	d="
M267.909790,152.483078 
	C267.563354,148.874710 267.411011,144.789948 266.906342,140.749146 
	C266.769257,139.651398 265.569244,138.686401 264.548584,137.804321 
	C264.218597,117.342201 264.197083,96.736237 264.207825,75.684258 
	C268.365448,76.029243 267.986328,79.302849 267.988861,82.241585 
	C268.009003,105.502098 267.983124,128.762665 267.909790,152.483078 
z"/>
<path fill="#CB9632" opacity="1.000000" stroke="none" 
	d="
M263.915161,220.160797 
	C265.008240,220.317047 266.101349,220.473297 267.446686,220.793884 
	C267.779694,227.331924 267.860474,233.705612 267.898224,240.891602 
	C267.782593,242.431824 267.710022,243.159729 267.433533,244.137787 
	C267.115112,245.233093 267.000549,246.078278 266.906433,246.967804 
	C266.926941,247.012146 266.914185,246.915924 266.656769,247.133224 
	C264.266205,250.567017 262.133057,253.783508 259.651337,257.086060 
	C254.459763,258.895996 249.616760,260.619904 244.804886,261.988373 
	C245.204544,260.559723 245.573090,259.486542 246.341171,258.337952 
	C258.021851,256.213989 263.899780,249.344986 263.986542,237.983658 
	C264.031921,232.043228 263.943268,226.101791 263.915161,220.160797 
z"/>
<path fill="#FDFFFD" opacity="1.000000" stroke="none" 
	d="
M267.637421,243.887650 
	C267.710022,243.159729 267.782593,242.431824 267.969513,241.355881 
	C268.711975,239.091217 269.853180,237.182693 269.883759,235.256561 
	C270.062042,224.015411 269.969360,212.770111 270.011749,201.526367 
	C270.014343,200.838364 270.389832,200.151779 271.444733,199.502289 
	C271.773163,201.163315 272.101593,202.824356 272.705811,204.744217 
	C273.020538,205.447495 273.059479,205.891937 272.810974,206.812958 
	C272.674622,207.861359 272.825745,208.433197 272.976837,209.005020 
	C273.021515,209.448792 273.066162,209.892578 272.755585,210.963989 
	C272.301483,223.129059 272.202667,234.666473 272.103821,246.203888 
	C272.103821,246.203888 272.102570,246.617142 271.639587,246.884125 
	C270.833099,248.535294 270.489594,249.919464 270.146088,251.303650 
	C270.146088,251.303650 270.110443,251.686798 269.775116,251.962402 
	C269.178253,252.488052 269.071838,252.792389 269.120514,253.151001 
	C269.120514,253.151001 269.090790,253.020905 268.748962,253.084839 
	C267.988098,254.206924 267.569061,255.265045 267.150024,256.323181 
	C266.542938,257.081665 265.935883,257.840118 264.736633,258.785675 
	C263.100800,258.318726 262.057129,257.664703 261.352173,256.953918 
	C264.565857,254.163437 267.563293,251.493820 266.914185,246.915924 
	C266.914185,246.915924 266.926941,247.012146 267.256470,246.946045 
	C267.603119,245.882507 267.620270,244.885086 267.637421,243.887650 
z"/>
<path fill="#FDFFFD" opacity="1.000000" stroke="none" 
	d="
M272.010681,191.223068 
	C271.756866,193.443878 271.502838,195.250778 271.248810,197.057693 
	C270.810974,196.955521 270.373169,196.853348 269.935364,196.751175 
	C269.935364,193.664444 269.935364,190.577698 269.935364,187.490967 
	C270.435394,187.457535 270.935425,187.424103 271.435455,187.390671 
	C271.627136,188.530167 271.818787,189.669678 272.010681,191.223068 
z"/>
<path fill="#E5BD52" opacity="1.000000" stroke="none" 
	d="
M267.198425,174.981110 
	C267.140839,174.440247 267.274536,173.922073 267.617798,173.231979 
	C267.861328,173.501068 267.895264,173.942093 267.851562,174.707718 
	C267.773895,175.032318 267.389679,175.003799 267.198425,174.981110 
z"/>
<path fill="#E5BD52" opacity="1.000000" stroke="none" 
	d="
M267.862976,168.728943 
	C267.461182,168.784073 267.372131,168.538345 267.686584,168.132812 
	C267.819061,168.046127 267.904755,168.501205 267.862976,168.728943 
z"/>
<path fill="#FDF7D8" opacity="1.000000" stroke="none" 
	d="
M272.959106,151.630402 
	C272.721558,151.129517 272.570435,150.258484 272.645111,149.194580 
	C272.929138,149.754578 272.987366,150.507416 272.959106,151.630402 
z"/>
<path fill="#E1B837" opacity="1.000000" stroke="none" 
	d="
M291.968384,169.240479 
	C292.203888,169.160477 292.387329,169.322678 292.520142,169.519119 
	C292.550873,169.564560 292.408569,169.726990 292.177734,169.923645 
	C291.972351,169.839508 291.935120,169.667328 291.968384,169.240479 
z"/>
<path fill="#D6A736" opacity="1.000000" stroke="none" 
	d="
M193.742294,294.892822 
	C193.694214,294.606415 193.943909,294.281281 194.556732,294.021820 
	C194.626602,294.343018 194.333344,294.598572 193.742294,294.892822 
z"/>
<path fill="#DEB01D" opacity="1.000000" stroke="none" 
	d="
M261.547394,376.911407 
	C256.793854,369.029388 258.042389,360.500214 258.028564,351.681885 
	C258.470734,351.178986 258.900391,351.127014 259.650024,351.141571 
	C260.322388,355.458191 260.903687,359.704224 260.979919,363.959259 
	C261.142120,373.013977 262.201111,374.601715 270.405426,376.707306 
	C270.378265,377.130737 270.351135,377.554199 270.323975,377.977631 
	C267.529968,377.643738 264.735962,377.309845 261.547394,376.911407 
z"/>
<path fill="#DEB01D" opacity="1.000000" stroke="none" 
	d="
M276.914032,376.071777 
	C277.818909,368.660767 278.799774,361.195587 279.857513,353.145508 
	C280.347931,354.213867 280.957825,354.933472 280.968292,355.661713 
	C281.051575,361.465942 280.990845,367.272003 281.040161,373.076996 
	C281.060791,375.505585 280.455261,377.044617 277.230316,376.137329 
	C276.868347,376.160309 276.990021,376.017578 276.914032,376.071777 
z"/>
<path fill="#E1B530" opacity="1.000000" stroke="none" 
	d="
M352.036987,373.908447 
	C352.036987,373.908447 351.993317,373.432617 351.892303,372.942505 
	C351.681671,372.304138 351.572083,372.155945 351.462524,372.007721 
	C351.462524,372.007721 351.676483,371.979950 352.209961,371.972107 
	C354.162720,371.969513 355.582031,371.974792 357.212158,372.252136 
	C361.096161,377.434662 363.050903,377.943268 367.065277,375.032928 
	C368.181152,373.743774 369.297028,372.454620 370.774475,370.747742 
	C364.533844,368.168610 358.932617,365.853729 353.086304,362.997498 
	C352.616638,362.184143 352.392029,361.912109 352.167419,361.640076 
	C352.167419,361.640076 352.160004,361.294403 352.065277,361.149719 
	C352.902893,358.299347 353.835236,355.593597 355.520813,352.923645 
	C356.957947,353.730957 357.641846,354.502563 358.325714,355.274170 
	C358.325714,355.274170 358.244080,355.267578 357.895966,355.301086 
	C355.152679,357.959717 354.919037,360.033905 358.994232,361.009979 
	C359.537781,361.184265 360.081360,361.358551 361.012146,362.034302 
	C363.258850,362.723602 365.118347,362.911499 366.977844,363.099365 
	C367.433167,363.123474 367.888519,363.147583 368.722107,363.547119 
	C369.729095,363.963257 370.357849,364.003937 370.986603,364.044586 
	C372.255890,364.793091 373.525146,365.541565 374.827118,366.849670 
	C375.259766,367.938477 375.659729,368.467743 376.059692,368.996979 
	C376.122681,370.066498 376.185699,371.136017 375.901947,372.756470 
	C375.450897,373.685364 375.346588,374.063354 375.242310,374.441315 
	C375.145721,374.781403 375.049103,375.121521 374.549774,375.738983 
	C373.797028,376.393555 373.447052,376.770752 373.097046,377.147949 
	C373.097046,377.147949 373.008972,377.007874 372.714905,377.036194 
	C371.605988,377.643097 370.791168,378.221680 369.976379,378.800293 
	C369.976379,378.800293 369.563660,378.983398 368.882233,378.998474 
	C364.928650,378.887573 361.656464,378.761658 358.255493,378.585510 
	C358.126709,378.535278 357.925690,378.345428 357.795959,378.016785 
	C356.422821,377.137634 355.179413,376.587158 353.721069,375.986633 
	C353.506165,375.936554 353.071228,375.861786 353.071228,375.861786 
	C353.071228,375.861786 352.966095,375.389679 352.828552,374.956665 
	C352.584259,374.200348 352.366272,373.995300 352.036987,373.908447 
z"/>
<path fill="#DCB73C" opacity="1.000000" stroke="none" 
	d="
M358.692688,355.212952 
	C357.641846,354.502563 356.957947,353.730957 355.869141,352.869202 
	C362.349274,347.509216 370.969574,349.161591 375.578247,356.803986 
	C375.805664,357.387756 375.785278,357.754547 375.767029,358.395386 
	C373.089844,359.401031 370.923370,359.551910 368.932770,356.676971 
	C366.459137,353.104462 362.662506,353.737091 358.692688,355.212952 
z"/>
<path fill="#DCB73C" opacity="1.000000" stroke="none" 
	d="
M358.822784,360.670532 
	C354.919037,360.033905 355.152679,357.959717 357.905609,355.261292 
	C358.392700,356.902374 358.522034,358.616730 358.822784,360.670532 
z"/>
<path fill="#DDB657" opacity="1.000000" stroke="none" 
	d="
M358.384308,378.635742 
	C361.656464,378.761658 364.928650,378.887573 368.661682,379.046570 
	C366.686432,379.399689 364.250336,379.719757 361.382874,380.095947 
	C360.543945,380.048553 360.136353,379.945099 359.347595,379.596008 
	C358.799133,379.090424 358.605072,378.852203 358.384308,378.635742 
z"/>
<path fill="#DCB73C" opacity="1.000000" stroke="none" 
	d="
M366.635010,362.937042 
	C365.118347,362.911499 363.258850,362.723602 361.202057,362.251953 
	C362.767242,362.237000 364.529694,362.505859 366.635010,362.937042 
z"/>
<path fill="#DDB657" opacity="1.000000" stroke="none" 
	d="
M370.294434,378.842041 
	C370.791168,378.221680 371.605988,377.643097 372.768616,377.099182 
	C372.281769,377.717163 371.447113,378.300507 370.294434,378.842041 
z"/>
<path fill="#DCB73C" opacity="1.000000" stroke="none" 
	d="
M370.799866,363.832397 
	C370.357849,364.003937 369.729095,363.963257 369.042786,363.670654 
	C369.527863,363.485901 370.070496,363.553040 370.799866,363.832397 
z"/>
<path fill="#DCB73C" opacity="1.000000" stroke="none" 
	d="
M376.058746,368.686768 
	C375.659729,368.467743 375.259766,367.938477 374.961487,367.149414 
	C375.394684,367.385223 375.726227,367.880890 376.058746,368.686768 
z"/>
<path fill="#DDB657" opacity="1.000000" stroke="none" 
	d="
M373.376343,377.109436 
	C373.447052,376.770752 373.797028,376.393555 374.413086,375.968292 
	C374.337952,376.303772 373.996765,376.687317 373.376343,377.109436 
z"/>
<path fill="#DDB657" opacity="1.000000" stroke="none" 
	d="
M375.464355,374.253784 
	C375.346588,374.063354 375.450897,373.685364 375.814636,373.151550 
	C375.944855,373.352539 375.815613,373.709381 375.464355,374.253784 
z"/>
<path fill="#E5BD52" opacity="1.000000" stroke="none" 
	d="
M264.144470,145.502319 
	C264.369659,147.179901 264.522736,149.279816 264.438354,151.649689 
	C264.158020,149.921310 264.115173,147.922989 264.144470,145.502319 
z"/>
<path fill="#E5BD52" opacity="1.000000" stroke="none" 
	d="
M264.153320,157.268585 
	C264.545776,157.208328 264.638367,157.453140 264.347290,157.863983 
	C264.221436,157.955872 264.111206,157.499527 264.153320,157.268585 
z"/>
<path fill="#FDF7D8" opacity="1.000000" stroke="none" 
	d="
M272.709686,167.249146 
	C273.063416,169.367386 273.131378,171.753006 273.097046,174.575943 
	C272.804474,172.514343 272.614227,170.015457 272.709686,167.249146 
z"/>
<path fill="#FDF7D8" opacity="1.000000" stroke="none" 
	d="
M273.528564,161.955353 
	C273.458984,162.140457 273.389374,162.325546 273.170105,162.767044 
	C272.813965,161.825607 272.607422,160.627762 272.726074,159.568329 
	C273.210358,160.456253 273.369446,161.205811 273.528564,161.955353 
z"/>
<path fill="#EBC34A" opacity="1.000000" stroke="none" 
	d="
M273.701935,161.640121 
	C273.369446,161.205811 273.210358,160.456253 272.962219,159.329346 
	C272.873230,158.951981 273.221771,158.671005 273.411072,158.553528 
	C273.692017,159.399002 273.783661,160.361938 273.701935,161.640121 
z"/>
<path fill="#EBC452" opacity="1.000000" stroke="none" 
	d="
M304.197937,194.028290 
	C304.639313,200.997574 304.722473,208.117767 304.416626,215.676422 
	C303.690643,216.057419 303.353668,215.999954 303.016693,215.942490 
	C303.291046,208.688065 303.565399,201.433640 304.197937,194.028290 
z"/>
<path fill="#C78E2A" opacity="1.000000" stroke="none" 
	d="
M303.029602,216.215729 
	C303.353668,215.999954 303.690643,216.057419 304.282104,216.136902 
	C304.557922,216.567673 304.579254,216.976425 304.472778,217.666779 
	C303.910797,217.461929 303.476624,216.975449 303.029602,216.215729 
z"/>
<path fill="#FDF7D8" opacity="1.000000" stroke="none" 
	d="
M358.255493,378.585510 
	C358.605072,378.852203 358.799133,379.090424 359.032227,379.571289 
	C357.125153,381.949188 356.000885,380.691650 355.024353,378.379608 
	C355.010345,377.975891 355.135803,377.837036 355.483704,377.905396 
	C356.529663,378.097626 357.227661,378.221527 357.925690,378.345428 
	C357.925690,378.345428 358.126709,378.535278 358.255493,378.585510 
z"/>
<path fill="#FDF7D8" opacity="1.000000" stroke="none" 
	d="
M353.031891,376.148102 
	C353.071228,375.861786 353.506165,375.936554 353.732880,376.225037 
	C353.959595,376.513550 354.014191,376.988098 353.974915,377.030884 
	C353.935638,377.073669 353.860229,377.162018 353.860229,377.162018 
	C353.860229,377.162018 353.410248,377.073242 353.182312,377.042206 
	C352.967072,376.818909 352.979828,376.626648 353.031891,376.148102 
z"/>
<path fill="#FDF7D8" opacity="1.000000" stroke="none" 
	d="
M354.944489,378.042358 
	C354.588654,377.975372 354.339691,377.787628 353.995972,377.353760 
	C353.860229,377.162018 353.935638,377.073669 354.199249,377.118530 
	C354.755188,377.319977 354.979492,377.544525 355.135803,377.837036 
	C355.135803,377.837036 355.010345,377.975891 354.944489,378.042358 
z"/>
<path fill="#FEF9E0" opacity="1.000000" stroke="none" 
	d="
M297.107697,269.801575 
	C298.619659,265.004211 300.501251,260.238312 302.612183,255.197754 
	C303.175079,255.931992 304.078430,257.212341 303.748413,257.905151 
	C301.818024,261.957336 299.598938,265.871979 297.107697,269.801575 
z"/>
<path fill="#FEF9E0" opacity="1.000000" stroke="none" 
	d="
M294.982819,275.595886 
	C294.096893,276.291138 293.214294,276.597656 291.976257,276.869110 
	C292.502625,275.593506 293.384369,274.352936 294.571289,272.993561 
	C294.913055,273.652222 294.949615,274.429688 294.982819,275.595886 
z"/>
<path fill="#FEF9E0" opacity="1.000000" stroke="none" 
	d="
M295.226501,272.874268 
	C295.302002,271.945862 295.749817,271.008331 296.479187,269.928986 
	C296.373444,270.813171 295.986145,271.839142 295.226501,272.874268 
z"/>
<path fill="#FDFAE9" opacity="1.000000" stroke="none" 
	d="
M263.945251,173.983582 
	C263.953278,175.358047 263.961304,176.732498 263.945679,178.518936 
	C255.884216,179.078247 247.846390,179.225586 239.125320,179.383179 
	C238.026840,179.617188 237.611572,179.840958 237.196304,180.064728 
	C228.503952,180.968124 220.539566,183.800079 213.251801,188.693115 
	C199.932297,197.635895 191.770493,210.155914 186.891525,225.952621 
	C186.325760,227.888916 186.231842,229.231262 186.137939,230.573624 
	C186.202408,231.188400 186.266891,231.803177 185.899109,232.949707 
	C183.264313,239.901932 184.139069,246.054031 187.084167,252.025574 
	C187.084167,252.025574 187.248367,252.280197 187.165070,252.767609 
	C187.367493,253.832352 187.653214,254.409668 187.938934,254.986969 
	C188.039337,255.325623 188.139725,255.664276 188.219330,256.563965 
	C188.453888,257.415710 188.709198,257.706360 188.964508,257.997009 
	C188.964523,257.997009 189.144943,258.364136 189.086365,258.935303 
	C189.397720,260.324982 189.767639,261.143524 190.137558,261.962067 
	C190.137558,261.962067 190.052307,261.961731 190.060547,262.242310 
	C190.418304,263.001373 190.767792,263.479828 191.117294,263.958282 
	C191.117294,263.958282 191.030106,263.981873 191.046738,264.259338 
	C191.410217,265.012268 191.757065,265.487762 192.103912,265.963226 
	C192.103928,265.963226 192.019699,265.981873 192.038940,266.258179 
	C192.389511,267.013855 192.720856,267.493195 193.052185,267.972565 
	C193.052185,267.972565 192.998062,267.995789 193.028168,268.293060 
	C193.437485,269.041534 193.816681,269.492706 194.195892,269.943909 
	C194.195892,269.943909 194.552002,269.972473 194.726929,270.007874 
	C194.897385,270.232239 194.892944,270.421204 194.957230,271.171936 
	C195.366058,272.150818 195.706177,272.567902 196.046295,272.985016 
	C196.046280,272.985016 195.998535,273.000702 196.095749,273.204865 
	C196.371552,273.694519 196.618423,273.896667 196.933548,274.015442 
	C196.933548,274.015442 197.000381,274.013306 197.067291,274.221130 
	C197.267365,274.656342 197.452988,274.826416 197.691071,274.939178 
	C197.691071,274.939178 197.975143,275.275177 198.092880,275.767151 
	C198.489685,276.501831 198.768753,276.744476 199.047821,276.987152 
	C199.372681,277.108673 199.627487,277.316223 199.986954,278.030853 
	C200.317352,278.690002 200.527618,278.860901 200.792435,278.964661 
	C200.792435,278.964661 201.036224,279.220490 201.163452,279.723450 
	C201.670441,280.444672 202.050186,280.662903 202.429932,280.881134 
	C203.594193,281.678558 204.758453,282.475983 206.217377,283.332947 
	C206.512024,283.392517 206.735046,283.318604 206.689636,283.523376 
	C207.087708,284.170380 207.531204,284.612610 207.974701,285.054810 
	C207.974701,285.054810 207.998642,285.477020 208.125397,285.942688 
	C208.568939,286.584625 208.885727,286.760895 209.202515,286.937164 
	C209.698334,287.173492 210.194168,287.409821 210.957703,288.083252 
	C211.435104,288.763641 211.696304,288.922089 212.009003,288.995667 
	C212.521530,289.248108 213.034073,289.500519 213.794586,290.221619 
	C214.367310,291.133606 214.692093,291.576874 215.001953,292.269440 
	C214.987061,292.518738 214.953537,293.017090 214.544708,293.014313 
	C212.512772,293.000610 210.889694,292.989655 209.266602,292.978699 
	C207.295425,291.506561 205.324265,290.034393 203.134155,287.957458 
	C202.249130,286.920532 201.583069,286.488403 200.942993,286.027466 
	C200.969009,285.998627 200.961090,286.075867 200.864258,285.835632 
	C200.481583,285.415680 200.195770,285.235962 199.948639,285.033875 
	C199.987320,285.011566 199.944489,285.089966 199.852173,284.844604 
	C199.474152,284.411926 199.188431,284.224640 198.625336,283.888123 
	C197.572525,282.765839 196.797073,281.792725 195.931427,280.657959 
	C195.693588,280.290649 195.502350,280.140503 195.034454,279.836609 
	C194.157135,278.578278 193.512833,277.529266 192.820435,276.169342 
	C192.464264,275.597961 192.156204,275.337524 191.899597,275.096069 
	C191.951050,275.115082 191.890045,275.206268 191.821625,274.932495 
	C191.464111,274.431183 191.175018,274.203583 190.655548,273.730133 
	C170.710938,243.688644 181.573059,200.703552 213.122025,182.439621 
	C229.119064,173.178818 246.416122,173.245529 263.945251,173.983582 
z"/>
<path fill="#FEF9E0" opacity="1.000000" stroke="none" 
	d="
M187.109711,251.591019 
	C184.139069,246.054031 183.264313,239.901932 185.757660,233.235229 
	C186.410721,239.044815 186.772995,245.100647 187.109711,251.591019 
z"/>
<path fill="#FEF9E0" opacity="1.000000" stroke="none" 
	d="
M186.405289,230.267090 
	C186.231842,229.231262 186.325760,227.888916 186.709381,226.271408 
	C186.890274,227.317703 186.781464,228.639130 186.405289,230.267090 
z"/>
<path fill="#FEF9E0" opacity="1.000000" stroke="none" 
	d="
M190.142578,261.615234 
	C189.767639,261.143524 189.397720,260.324982 189.057709,259.137817 
	C189.440964,259.602264 189.794281,260.435333 190.142578,261.615234 
z"/>
<path fill="#D9A745" opacity="1.000000" stroke="none" 
	d="
M237.421326,180.064362 
	C237.611572,179.840958 238.026840,179.617188 238.668869,179.519745 
	C238.479218,179.785370 238.062775,179.924683 237.421326,180.064362 
z"/>
<path fill="#FEF9E0" opacity="1.000000" stroke="none" 
	d="
M191.119751,263.650665 
	C190.767792,263.479828 190.418304,263.001373 190.080109,262.204468 
	C190.435028,262.371674 190.778610,262.857361 191.119751,263.650665 
z"/>
<path fill="#FEF9E0" opacity="1.000000" stroke="none" 
	d="
M192.087616,265.661865 
	C191.757065,265.487762 191.410217,265.012268 191.071320,264.221436 
	C191.409943,264.390900 191.740646,264.875671 192.087616,265.661865 
z"/>
<path fill="#FEF9E0" opacity="1.000000" stroke="none" 
	d="
M187.964935,254.656998 
	C187.653214,254.409668 187.367493,253.832352 187.112961,252.909866 
	C187.426422,253.152145 187.708694,253.739578 187.964935,254.656998 
z"/>
<path fill="#FEF9E0" opacity="1.000000" stroke="none" 
	d="
M193.044769,267.669250 
	C192.720856,267.493195 192.389511,267.013855 192.064453,266.223389 
	C192.392944,266.396851 192.715149,266.881378 193.044769,267.669250 
z"/>
<path fill="#FEF9E0" opacity="1.000000" stroke="none" 
	d="
M194.170166,269.640625 
	C193.816681,269.492706 193.437485,269.041534 193.050385,268.273376 
	C193.409821,268.416748 193.777130,268.877075 194.170166,269.640625 
z"/>
<path fill="#FEF9E0" opacity="1.000000" stroke="none" 
	d="
M188.953445,257.695496 
	C188.709198,257.706360 188.453888,257.415710 188.165710,256.826599 
	C188.402695,256.816803 188.672531,257.105377 188.953445,257.695496 
z"/>
<path fill="#FDFFFD" opacity="1.000000" stroke="none" 
	d="
M251.987885,196.975967 
	C251.253433,197.069260 250.518982,197.162552 249.118896,197.321869 
	C248.007080,197.465942 247.560883,197.543976 246.761749,197.458557 
	C245.635345,197.270660 244.861877,197.246201 243.850388,196.907074 
	C238.512970,197.779755 233.237411,198.495438 228.373123,200.312271 
	C224.848679,201.628693 219.983978,201.908371 219.050751,207.081589 
	C219.009293,207.043793 219.116577,207.076691 218.857025,207.140228 
	C218.415115,207.495941 218.232742,207.788132 218.028503,208.043945 
	C218.006638,208.007568 218.078949,208.052032 217.830017,208.117157 
	C217.387024,208.454483 217.192978,208.726685 216.999466,208.999451 
	C217.000000,209.000000 216.999008,208.998840 216.623932,208.994400 
	C212.819351,209.854431 210.586319,211.611801 211.350677,215.709442 
	C211.376007,215.831635 211.199692,216.008301 210.917313,216.132309 
	C210.474167,216.619522 210.313400,216.982727 210.128448,217.529312 
	C210.104279,217.712692 209.859680,217.990265 209.642059,218.118469 
	C209.306732,218.553436 209.189026,218.860214 209.043228,219.386169 
	C209.015152,219.605331 208.793015,219.987366 208.594391,220.122375 
	C208.156021,220.509811 208.066223,220.807465 208.101135,221.105774 
	C208.075928,221.061157 208.175934,221.083572 207.867447,221.231277 
	C207.421661,222.020798 207.284363,222.662628 207.157715,223.486908 
	C207.168365,223.669342 206.981186,223.983261 206.661499,224.157211 
	C201.357224,233.366638 202.602692,242.910919 203.926346,252.561829 
	C200.184097,246.405167 200.313797,239.491394 199.842987,232.705231 
	C199.836945,232.321655 199.830917,231.938095 200.275116,231.214813 
	C202.776093,225.209473 204.826859,219.543839 206.877625,213.878204 
	C206.877640,213.878220 206.898438,214.010864 207.214935,213.960999 
	C207.979111,212.925858 208.426788,211.940582 208.874451,210.955322 
	C209.103653,210.404663 209.332870,209.854004 210.047592,209.078918 
	C210.802673,208.671066 210.969528,208.419510 211.033707,208.099823 
	C211.033707,208.099823 211.018372,208.026108 211.271576,207.934555 
	C211.794571,207.651688 211.961868,207.393860 212.026703,207.069519 
	C212.026703,207.069519 212.002258,207.006561 212.400482,207.016754 
	C214.150360,205.576065 215.501984,204.125168 216.853622,202.674255 
	C217.373337,202.164276 217.893036,201.654297 218.905182,201.017578 
	C219.694077,200.755432 219.900635,200.535568 220.017303,200.231262 
	C220.017303,200.231262 220.304459,199.967697 220.769470,199.889267 
	C221.491043,199.557510 221.747604,199.304184 222.004150,199.050842 
	C222.004150,199.050842 222.273193,198.843063 222.854797,198.876297 
	C226.635605,197.324707 229.834793,195.739883 233.033966,194.155060 
	C233.387695,194.042648 233.741409,193.930222 234.870178,193.884430 
	C243.137802,193.959229 250.630432,193.990829 258.122833,193.960785 
	C260.268433,193.952194 262.284607,193.930679 261.980499,196.975296 
	C260.273407,197.010529 258.566315,197.045761 256.219879,196.769501 
	C254.382980,196.630661 253.185425,196.803314 251.987885,196.975967 
z"/>
<path fill="#FDF7D8" opacity="1.000000" stroke="none" 
	d="
M199.567627,233.042358 
	C200.313797,239.491394 200.184097,246.405167 203.926346,252.561829 
	C202.602692,242.910919 201.357224,233.366638 206.663605,224.557312 
	C206.303375,231.770264 205.533768,238.750763 205.030167,245.750381 
	C204.951202,246.848175 206.079895,248.032852 206.685516,249.166656 
	C206.720886,249.155975 206.758591,249.090668 206.562897,249.415253 
	C206.487778,250.227051 206.608368,250.714249 206.717773,251.459961 
	C206.944809,252.163803 207.183014,252.609116 207.482330,253.387268 
	C207.720825,254.132858 207.898209,254.545609 208.073593,255.310760 
	C208.768906,257.383820 209.466217,259.104431 209.819000,260.836639 
	C208.578857,260.045227 207.683228,259.242249 206.465942,258.150909 
	C206.693695,260.804596 206.868454,262.840973 207.043213,264.877380 
	C206.020721,263.359039 204.998230,261.840698 203.753906,260.120178 
	C199.717209,251.455505 198.286057,242.612183 199.567627,233.042358 
z"/>
<path fill="#FDF7D8" opacity="1.000000" stroke="none" 
	d="
M262.374817,196.993561 
	C262.284607,193.930679 260.268433,193.952194 258.122833,193.960785 
	C250.630432,193.990829 243.137802,193.959229 235.149902,193.889771 
	C244.064621,193.237473 253.474686,192.646454 263.335815,192.084106 
	C263.447601,193.745804 263.108368,195.378815 262.374817,196.993561 
z"/>
<path fill="#FDF7D8" opacity="1.000000" stroke="none" 
	d="
M206.574158,214.041794 
	C204.826859,219.543839 202.776093,225.209473 200.362778,230.939758 
	C202.090363,225.404739 204.180527,219.805069 206.574158,214.041794 
z"/>
<path fill="#FDF7D8" opacity="1.000000" stroke="none" 
	d="
M232.667725,194.127716 
	C229.834793,195.739883 226.635605,197.324707 223.023544,198.855896 
	C225.840942,197.234955 229.071198,195.667664 232.667725,194.127716 
z"/>
<path fill="#FDF7D8" opacity="1.000000" stroke="none" 
	d="
M216.532455,202.771179 
	C215.501984,204.125168 214.150360,205.576065 212.430756,207.031738 
	C213.445618,205.647034 214.828461,204.257584 216.532455,202.771179 
z"/>
<path fill="#FDF7D8" opacity="1.000000" stroke="none" 
	d="
M208.595932,211.095703 
	C208.426788,211.940582 207.979111,212.925858 207.191681,213.897369 
	C207.340439,213.001099 207.828918,212.118591 208.595932,211.095703 
z"/>
<path fill="#FDF7D8" opacity="1.000000" stroke="none" 
	d="
M211.784668,207.129318 
	C211.961868,207.393860 211.794571,207.651688 211.309189,207.936157 
	C211.071228,207.657272 211.220917,207.377213 211.784668,207.129318 
z"/>
<path fill="#FDF7D8" opacity="1.000000" stroke="none" 
	d="
M219.773590,200.240997 
	C219.900635,200.535568 219.694077,200.755432 219.170837,200.937347 
	C218.999603,200.627808 219.194870,200.383438 219.773590,200.240997 
z"/>
<path fill="#FDF7D8" opacity="1.000000" stroke="none" 
	d="
M221.724640,199.071030 
	C221.747604,199.304184 221.491043,199.557510 220.957642,199.838501 
	C220.935562,199.607849 221.190338,199.349533 221.724640,199.071030 
z"/>
<path fill="#FDF7D8" opacity="1.000000" stroke="none" 
	d="
M210.803284,208.163895 
	C210.969528,208.419510 210.802673,208.671066 210.319901,208.943634 
	C210.132401,208.689377 210.287781,208.421097 210.803284,208.163895 
z"/>
<path fill="#FEF9E0" opacity="1.000000" stroke="none" 
	d="
M268.404327,294.778839 
	C267.013458,298.509460 263.233917,297.463684 260.053009,298.005127 
	C262.580719,296.735535 265.294647,295.769318 268.404327,294.778839 
z"/>
<path fill="#FEF9E0" opacity="1.000000" stroke="none" 
	d="
M251.549133,299.168304 
	C249.198578,299.392303 246.416153,299.550568 243.337463,299.468079 
	C243.475830,299.162170 243.910461,299.096985 244.671768,298.979797 
	C247.038040,298.986053 249.077652,299.044312 251.549133,299.168304 
z"/>
<path fill="#FEF9E0" opacity="1.000000" stroke="none" 
	d="
M257.677673,298.139526 
	C256.849915,298.625854 255.660583,299.106262 254.244049,299.340576 
	C255.116592,298.774170 256.216370,298.453857 257.677673,298.139526 
z"/>
<path fill="#FEF9E0" opacity="1.000000" stroke="none" 
	d="
M282.001099,288.998901 
	C281.246155,289.005066 280.490143,289.012329 279.356445,289.001526 
	C280.080292,288.027008 281.181824,287.070526 282.577423,285.995361 
	C282.871490,285.876678 283.027039,286.032959 283.015991,286.402069 
	C282.669006,287.514801 282.333038,288.258392 281.997070,289.002014 
	C281.997070,289.002014 282.000000,289.000000 282.001099,288.998901 
z"/>
<path fill="#FEF9E0" opacity="1.000000" stroke="none" 
	d="
M275.576233,290.889648 
	C275.084503,291.558563 274.265472,292.247864 273.058289,292.895447 
	C273.529694,292.205841 274.389313,291.557922 275.576233,290.889648 
z"/>
<path fill="#FEF9E0" opacity="1.000000" stroke="none" 
	d="
M278.668091,288.998535 
	C278.197601,289.644806 277.408752,290.311035 276.257233,290.936371 
	C276.712952,290.269836 277.531342,289.644196 278.668091,288.998535 
z"/>
<path fill="#FEF9E0" opacity="1.000000" stroke="none" 
	d="
M287.800659,281.166046 
	C287.955261,281.424500 287.789459,281.656891 287.342072,281.917175 
	C287.099792,281.640076 287.239716,281.371735 287.800659,281.166046 
z"/>
<path fill="#FEF9E0" opacity="1.000000" stroke="none" 
	d="
M288.812744,280.181580 
	C288.968079,280.411560 288.811005,280.659882 288.357452,280.945862 
	C288.175079,280.710968 288.321533,280.449768 288.812744,280.181580 
z"/>
<path fill="#FEF9E0" opacity="1.000000" stroke="none" 
	d="
M271.715210,293.054565 
	C271.723816,293.307739 271.435150,293.557098 270.850311,293.816650 
	C270.842072,293.568146 271.130005,293.309479 271.715210,293.054565 
z"/>
<path fill="#FEF9E0" opacity="1.000000" stroke="none" 
	d="
M269.735718,294.042175 
	C269.777100,294.283936 269.548920,294.535492 269.058105,294.807251 
	C269.019073,294.568939 269.242584,294.310486 269.735718,294.042175 
z"/>
<path fill="#FEF9E0" opacity="1.000000" stroke="none" 
	d="
M289.727051,279.034912 
	C289.939453,279.304871 289.786285,279.583771 289.300354,279.909546 
	C289.214661,279.715881 289.345917,279.411682 289.727051,279.034912 
z"/>
<path fill="#FEF9E0" opacity="1.000000" stroke="none" 
	d="
M290.668823,277.889526 
	C290.763550,278.091370 290.616180,278.420837 290.215057,278.864807 
	C290.116455,278.658630 290.271576,278.337891 290.668823,277.889526 
z"/>
<path fill="#FEFDF6" opacity="1.000000" stroke="none" 
	d="
M263.496704,220.112885 
	C263.943268,226.101791 264.031921,232.043228 263.986542,237.983658 
	C263.899780,249.344986 258.021851,256.213989 245.963013,258.206390 
	C243.105545,257.859497 241.025803,257.568787 238.606201,257.139862 
	C235.651443,254.970734 233.036545,252.939819 230.317017,250.711945 
	C229.793182,250.310349 229.373978,250.105713 228.903198,249.700607 
	C228.851608,249.500137 228.488983,249.300369 228.396973,249.000946 
	C225.167313,241.903839 224.566177,234.912018 229.696136,229.112442 
	C233.040436,225.331604 237.856995,222.853012 242.258759,219.848175 
	C242.660812,219.847626 242.818130,219.789963 243.438477,219.798477 
	C248.624588,219.781265 253.347641,219.697876 258.070709,219.614502 
	C259.739868,219.764648 261.409058,219.914810 263.496704,220.112885 
z"/>
<path fill="#FEFCE6" opacity="1.000000" stroke="none" 
	d="
M261.013428,257.010712 
	C262.057129,257.664703 263.100800,258.318726 264.509888,258.971497 
	C261.031647,263.929230 255.695480,266.165863 248.911072,267.289734 
	C244.447495,267.096497 240.810944,266.882904 236.876648,266.476257 
	C233.330673,264.514130 230.082428,262.745026 226.706482,260.676056 
	C220.469162,256.613281 220.065109,249.751663 217.708374,243.565109 
	C217.583954,242.366165 217.420654,241.573746 217.409592,240.478897 
	C217.459244,239.528030 217.356689,238.879623 217.395966,237.814087 
	C218.424484,226.829178 223.291870,218.504547 232.883713,214.051376 
	C237.802673,211.767624 243.683487,211.555557 249.538391,210.552155 
	C252.248978,210.595383 254.550781,210.493698 257.223938,210.501389 
	C258.341187,210.471237 259.087097,210.331711 259.833008,210.192200 
	C260.954163,210.413757 262.075287,210.635315 263.637146,211.280945 
	C264.153046,212.156372 264.228210,212.607712 264.303375,213.059067 
	C263.108276,214.020172 261.913147,214.981277 259.874268,215.956512 
	C254.039597,215.987686 249.020721,215.673309 244.063812,216.092194 
	C234.372925,216.911087 227.553574,222.131882 224.304718,231.223633 
	C221.638565,238.684769 221.550705,246.132248 227.848999,252.633194 
	C230.810577,255.370178 233.765717,257.765625 236.896423,260.330933 
	C237.071976,260.500793 237.505722,260.720032 237.505722,260.720032 
	C237.505722,260.720032 237.990036,260.680725 238.302216,260.808197 
	C239.057373,261.002136 239.500366,261.068634 240.218079,261.367981 
	C241.919800,261.848511 243.346786,262.096191 244.773773,262.343872 
	C249.616760,260.619904 254.459763,258.895996 259.904724,257.087097 
	C260.675629,257.005005 260.844543,257.007874 261.013428,257.010712 
z"/>
<path fill="#C78920" opacity="1.000000" stroke="none" 
	d="
M206.650146,249.177353 
	C206.079895,248.032852 204.951202,246.848175 205.030167,245.750381 
	C205.533768,238.750763 206.303375,231.770264 206.983292,224.383362 
	C206.981186,223.983261 207.168365,223.669342 207.402771,223.279205 
	C207.816757,222.287231 207.996338,221.685394 208.175934,221.083572 
	C208.175934,221.083572 208.075928,221.061157 208.332855,221.002533 
	C208.657532,220.625076 208.725281,220.306213 208.793015,219.987366 
	C208.793015,219.987366 209.015152,219.605331 209.271729,219.262421 
	C209.638763,218.609756 209.749222,218.300003 209.859680,217.990265 
	C209.859680,217.990265 210.104279,217.712692 210.401520,217.392624 
	C210.865723,216.717804 211.032715,216.363052 211.199707,216.008301 
	C211.199692,216.008301 211.376007,215.831635 211.632462,215.512512 
	C213.592285,213.128540 215.295654,211.063690 216.999008,208.998840 
	C216.999008,208.998840 217.000000,209.000000 217.256683,208.939941 
	C217.701904,208.603928 217.890442,208.327988 218.078949,208.052032 
	C218.078949,208.052032 218.006638,208.007568 218.289368,207.983749 
	C218.753601,207.665512 218.935089,207.371109 219.116577,207.076691 
	C219.116577,207.076691 219.009293,207.043793 219.401215,207.028854 
	C226.980927,201.841125 234.736938,198.354279 244.188110,198.965851 
	C245.386612,198.558517 246.250641,198.090256 247.114685,197.622009 
	C247.560883,197.543976 248.007080,197.465942 248.785568,197.482086 
	C252.119507,197.750778 255.121155,197.925278 258.817078,198.354477 
	C260.674133,198.729996 261.836945,198.850830 262.999725,198.971664 
	C263.006348,199.147171 263.012939,199.322678 262.549622,199.750092 
	C256.479950,200.004257 250.864822,199.762024 245.286942,200.112488 
	C242.524323,200.286072 239.830231,201.550522 236.853943,202.587402 
	C230.664261,204.651993 224.564774,206.175552 220.747772,212.071609 
	C220.214462,212.324875 220.034058,212.586044 219.720673,213.008774 
	C219.317245,213.392395 219.167358,213.697754 219.007721,214.001450 
	C218.997986,213.999786 219.007248,214.017670 218.723770,214.152069 
	C217.910797,215.155472 217.381302,216.024475 216.894958,216.926666 
	C216.938126,216.959854 216.909836,216.854691 216.641327,216.950195 
	C216.206802,217.368332 216.040802,217.690964 215.778931,218.091278 
	C215.683060,218.168945 215.714905,218.413666 215.423828,218.601715 
	C214.677521,219.818146 214.222305,220.846527 213.817627,221.888229 
	C213.868134,221.901550 213.843719,221.799973 213.596924,221.909546 
	C213.232742,222.347382 213.115326,222.675659 212.805511,223.254852 
	C212.278061,224.468918 211.943024,225.432037 211.390106,226.717529 
	C210.486313,230.104645 209.552963,233.139877 209.182816,236.242279 
	C208.799591,239.454208 208.962738,242.731339 208.545532,246.070862 
	C207.686188,247.166962 207.168167,248.172150 206.650146,249.177353 
z"/>
<path fill="#C78E2A" opacity="1.000000" stroke="none" 
	d="
M249.129608,210.407257 
	C243.683487,211.555557 237.802673,211.767624 232.883713,214.051376 
	C223.291870,218.504547 218.424484,226.829178 217.299561,238.011551 
	C217.126678,239.344528 217.192032,240.062943 217.257370,240.781342 
	C217.420654,241.573746 217.583954,242.366165 217.397095,243.643631 
	C216.246552,244.830643 215.446152,245.532578 214.380920,245.894684 
	C214.345444,240.358231 214.574814,235.161621 214.915939,229.758011 
	C215.027679,229.551010 215.037521,229.080658 215.273499,228.840942 
	C215.658707,227.731171 215.807938,226.861084 216.052856,225.848938 
	C216.148529,225.706848 216.150024,225.364273 216.399414,225.216171 
	C216.766403,224.711868 216.883987,224.355652 217.098633,223.879501 
	C217.195679,223.759552 217.188828,223.451035 217.442657,223.295746 
	C217.850372,222.787079 218.004257,222.433731 218.110687,222.072662 
	C218.063263,222.064972 218.108063,222.149994 218.345566,222.041458 
	C218.704193,221.616730 218.825333,221.300537 219.180756,220.802338 
	C219.962433,219.799088 220.509811,218.977859 221.293701,218.040909 
	C221.683594,217.615463 221.837006,217.305756 221.995209,216.998322 
	C222.000000,217.000610 221.996017,216.990982 222.250763,216.928955 
	C222.681274,216.580475 222.857025,216.294022 223.249634,215.918152 
	C223.744324,215.655289 223.933517,215.412704 224.296188,215.030518 
	C224.739410,214.657944 224.920517,214.355850 225.061920,214.043152 
	C225.022202,214.032516 225.049896,214.109924 225.324173,214.070114 
	C225.975327,213.671402 226.352219,213.312500 226.891022,212.845459 
	C227.052917,212.737289 227.218262,212.384705 227.567032,212.284729 
	C236.841904,206.159012 246.934647,206.655121 256.997223,206.982574 
	C254.720749,207.517197 252.444290,208.051819 250.145813,208.591614 
	C253.279404,208.916061 256.415802,209.240799 259.692627,209.878860 
	C259.087097,210.331711 258.341187,210.471237 256.909729,210.344238 
	C253.859329,210.187546 251.494461,210.297394 249.129608,210.407257 
z"/>
<path fill="#FDF7D8" opacity="1.000000" stroke="none" 
	d="
M257.425842,206.905533 
	C246.934647,206.655121 236.841904,206.159012 227.479141,211.915573 
	C224.779526,207.566498 223.057907,212.867538 220.980103,211.996216 
	C224.564774,206.175552 230.664261,204.651993 237.286041,202.521942 
	C246.312576,201.811890 254.655823,201.433258 262.999054,201.054626 
	C263.418396,202.680435 263.837708,204.306274 264.488190,206.828476 
	C261.687500,206.828476 259.770966,206.828476 257.425842,206.905533 
z"/>
<path fill="#CB9632" opacity="1.000000" stroke="none" 
	d="
M227.842560,252.291687 
	C221.550705,246.132248 221.638565,238.684769 224.304718,231.223633 
	C227.553574,222.131882 234.372925,216.911087 244.063812,216.092194 
	C249.020721,215.673309 254.039597,215.987686 259.491058,216.034729 
	C259.428772,217.157898 258.905884,218.216980 258.226868,219.445282 
	C253.347641,219.697876 248.624588,219.781265 243.340988,219.667419 
	C242.434052,219.317978 242.178574,219.424927 242.014023,219.791061 
	C237.856995,222.853012 233.040436,225.331604 229.696136,229.112442 
	C224.566177,234.912018 225.167313,241.903839 228.236908,249.491669 
	C228.060089,250.951752 227.951324,251.621719 227.842560,252.291687 
z"/>
<path fill="#C89031" opacity="1.000000" stroke="none" 
	d="
M263.009399,200.797333 
	C254.655823,201.433258 246.312576,201.811890 237.537231,202.255981 
	C239.830231,201.550522 242.524323,200.286072 245.286942,200.112488 
	C250.864822,199.762024 256.479950,200.004257 262.550659,200.013519 
	C263.021027,200.196701 263.020416,200.368362 263.009399,200.797333 
z"/>
<path fill="#CB9632" opacity="1.000000" stroke="none" 
	d="
M264.524292,213.269043 
	C264.228210,212.607712 264.153046,212.156372 264.062500,211.362793 
	C264.973663,211.951294 265.900146,212.882034 266.826660,213.812759 
	C266.132843,213.701523 265.438995,213.590271 264.524292,213.269043 
z"/>
<path fill="#C89031" opacity="1.000000" stroke="none" 
	d="
M262.766052,198.705170 
	C261.836945,198.850830 260.674133,198.729996 259.257660,198.370651 
	C260.180115,198.234314 261.356262,198.336487 262.766052,198.705170 
z"/>
<path fill="#FDF7D8" opacity="1.000000" stroke="none" 
	d="
M252.384903,197.062759 
	C253.185425,196.803314 254.382980,196.630661 255.795929,196.713150 
	C254.934875,197.028717 253.858398,197.089142 252.384903,197.062759 
z"/>
<path fill="#FDFFFD" opacity="1.000000" stroke="none" 
	d="
M214.953537,293.017090 
	C214.953537,293.017090 214.987061,292.518738 215.324081,292.274933 
	C216.090652,292.029175 216.520218,292.027222 216.949768,292.025269 
	C216.949768,292.025269 216.994156,291.995544 217.150421,292.259247 
	C217.664474,292.624268 218.022263,292.725586 218.380051,292.826904 
	C218.380051,292.826904 218.709747,292.828156 218.907440,293.273987 
	C221.109390,294.458466 223.113647,295.197174 225.117920,295.935852 
	C225.117920,295.935852 225.557968,296.008362 225.841782,296.431213 
	C228.103226,297.187592 230.080856,297.521088 232.058487,297.854584 
	C232.499588,297.922729 232.940689,297.990906 233.850876,298.408997 
	C235.220779,298.825897 236.121582,298.892883 237.022400,298.959900 
	C237.456985,298.971069 237.891556,298.982208 238.641907,299.433350 
	C237.625412,300.946136 236.293137,302.018921 234.960846,303.091675 
	C234.225067,303.036835 233.489273,302.981995 232.300644,302.479187 
	C229.203659,301.371887 226.559509,300.712555 223.915375,300.053253 
	C223.915375,300.053253 223.432358,300.017059 223.029694,299.699188 
	C222.073517,299.261749 221.520035,299.142181 220.966537,299.022614 
	C220.156769,298.719299 219.347000,298.415955 218.136810,297.744507 
	C217.508850,297.158600 217.237640,297.044739 216.922745,297.034851 
	C216.922745,297.034851 216.441711,296.947144 216.090469,296.668396 
	C215.513367,296.170593 215.243088,296.062592 214.928406,296.065582 
	C214.928406,296.065582 214.967392,296.071472 214.973969,295.694397 
	C214.971542,294.550568 214.962540,293.783844 214.953537,293.017090 
z"/>
<path fill="#FEF9E0" opacity="1.000000" stroke="none" 
	d="
M235.257797,303.334167 
	C236.293137,302.018921 237.625412,300.946136 238.958664,299.513855 
	C239.428192,299.109344 239.896729,299.064301 240.707275,299.118530 
	C241.035553,300.532593 241.021805,301.847412 241.011200,303.558044 
	C239.194473,303.828125 237.374603,303.702423 235.257797,303.334167 
z"/>
<path fill="#FEF9E0" opacity="1.000000" stroke="none" 
	d="
M224.916656,295.657928 
	C223.113647,295.197174 221.109390,294.458466 219.049042,293.358337 
	C220.900452,293.791290 222.807938,294.585632 224.916656,295.657928 
z"/>
<path fill="#FEF9E0" opacity="1.000000" stroke="none" 
	d="
M231.800781,297.613037 
	C230.080856,297.521088 228.103226,297.187592 226.044373,296.524445 
	C227.823120,296.587036 229.683105,296.979279 231.800781,297.613037 
z"/>
<path fill="#FEF9E0" opacity="1.000000" stroke="none" 
	d="
M236.791077,298.731140 
	C236.121582,298.892883 235.220779,298.825897 234.167892,298.518829 
	C234.863800,298.353302 235.711792,298.427856 236.791077,298.731140 
z"/>
<path fill="#FEF9E0" opacity="1.000000" stroke="none" 
	d="
M216.807281,291.758545 
	C216.520218,292.027222 216.090652,292.029175 215.338989,292.025635 
	C214.692093,291.576874 214.367310,291.133606 214.017120,290.405090 
	C214.882721,290.577148 215.773758,291.034485 216.807281,291.758545 
z"/>
<path fill="#DCB73C" opacity="1.000000" stroke="none" 
	d="
M207.959152,284.740204 
	C207.531204,284.612610 207.087708,284.170380 206.733185,283.414307 
	C207.195969,283.542145 207.569778,283.983856 207.959152,284.740204 
z"/>
<path fill="#FEF9E0" opacity="1.000000" stroke="none" 
	d="
M211.907715,288.777832 
	C211.696304,288.922089 211.435104,288.763641 211.102341,288.324219 
	C211.324249,288.139435 211.599960,288.283386 211.907715,288.777832 
z"/>
<path fill="#FEF9E0" opacity="1.000000" stroke="none" 
	d="
M218.206390,292.607605 
	C218.022263,292.725586 217.664474,292.624268 217.149292,292.285950 
	C217.338852,292.162048 217.685791,292.275146 218.206390,292.607605 
z"/>
<path fill="#FEF9E0" opacity="1.000000" stroke="none" 
	d="
M209.069580,286.699066 
	C208.885727,286.760895 208.568939,286.584625 208.139679,286.153625 
	C208.330368,286.086273 208.633499,286.273621 209.069580,286.699066 
z"/>
<path fill="#FDF7D8" opacity="1.000000" stroke="none" 
	d="
M212.632080,272.910339 
	C211.203888,271.181610 210.080292,269.500336 209.273499,267.463257 
	C210.526306,267.709106 211.495865,268.264954 212.392044,268.921051 
	C215.116592,270.915833 214.854126,268.766052 215.250427,267.204437 
	C216.152634,268.125336 216.761734,268.944366 217.048630,269.993652 
	C216.508148,271.751099 216.289871,273.278381 216.071594,274.805634 
	C215.026627,274.189697 213.981644,273.573792 212.632080,272.910339 
z"/>
<path fill="#FDFFFD" opacity="1.000000" stroke="none" 
	d="
M216.204987,275.059235 
	C216.289871,273.278381 216.508148,271.751099 217.319717,270.091644 
	C218.622147,270.608429 219.331268,271.257385 220.126648,272.159515 
	C220.517334,272.575928 220.821747,272.739166 221.131866,272.889313 
	C221.137558,272.876190 221.163803,272.856720 221.108063,273.246063 
	C219.978607,274.457764 218.904892,275.280090 217.831177,276.102417 
	C217.333572,275.839233 216.835983,275.576019 216.204987,275.059235 
z"/>
<path fill="#FDF7D8" opacity="1.000000" stroke="none" 
	d="
M217.999573,276.385254 
	C218.904892,275.280090 219.978607,274.457764 221.410522,273.264557 
	C222.163147,272.985352 222.557556,273.076965 223.096588,273.424896 
	C223.941086,274.364441 224.640976,275.047699 225.340881,275.730957 
	C224.225021,276.592163 223.109161,277.453339 221.962418,278.722046 
	C220.677017,278.309052 219.422485,277.488586 217.999573,276.385254 
z"/>
<path fill="#FDF7D8" opacity="1.000000" stroke="none" 
	d="
M224.051849,280.368103 
	C225.860382,280.782990 227.889633,281.463409 229.980560,282.509094 
	C228.118988,282.127472 226.195770,281.380585 224.051849,280.368103 
z"/>
<path fill="#FDF7D8" opacity="1.000000" stroke="none" 
	d="
M231.101776,283.340698 
	C232.522232,283.418518 234.180756,283.751740 235.946014,284.434601 
	C234.481781,284.388184 232.910812,283.992157 231.101776,283.340698 
z"/>
<path fill="#FEF9E0" opacity="1.000000" stroke="none" 
	d="
M196.008820,272.704285 
	C195.706177,272.567902 195.366058,272.150818 194.985229,271.454041 
	C195.286804,271.590729 195.629074,272.007141 196.008820,272.704285 
z"/>
<path fill="#FEF9E0" opacity="1.000000" stroke="none" 
	d="
M196.871094,273.773743 
	C196.618423,273.896667 196.371552,273.694519 196.105927,273.181885 
	C196.361984,273.038025 196.625229,273.230469 196.871094,273.773743 
z"/>
<path fill="#FEF9E0" opacity="1.000000" stroke="none" 
	d="
M202.267731,280.609467 
	C202.050186,280.662903 201.670441,280.444672 201.202026,279.895905 
	C201.444077,279.822845 201.774796,280.080322 202.267731,280.609467 
z"/>
<path fill="#FEF9E0" opacity="1.000000" stroke="none" 
	d="
M198.972046,276.718536 
	C198.768753,276.744476 198.489685,276.501831 198.151703,275.979156 
	C198.360596,275.949432 198.628433,276.199677 198.972046,276.718536 
z"/>
<path fill="#FEF9E0" opacity="1.000000" stroke="none" 
	d="
M197.661560,274.737183 
	C197.452988,274.826416 197.267365,274.656342 197.057465,274.253113 
	C197.271667,274.125031 197.488785,274.277679 197.661560,274.737183 
z"/>
<path fill="#FEF9E0" opacity="1.000000" stroke="none" 
	d="
M200.747818,278.749207 
	C200.527618,278.860901 200.317352,278.690002 200.074280,278.265076 
	C200.310013,278.097534 200.548782,278.249390 200.747818,278.749207 
z"/>
<path fill="#FDFFFD" opacity="1.000000" stroke="none" 
	d="
M214.957321,267.102600 
	C214.854126,268.766052 215.116592,270.915833 212.392044,268.921051 
	C211.495865,268.264954 210.526306,267.709106 209.233307,267.124634 
	C208.876328,267.141846 208.895645,267.116547 208.836639,266.854248 
	C208.492538,266.391235 208.207458,266.190491 207.922379,265.989777 
	C207.683273,265.779663 207.472748,265.544006 207.167023,265.080109 
	C206.868454,262.840973 206.693695,260.804596 206.465942,258.150909 
	C207.683228,259.242249 208.578857,260.045227 209.803284,260.846313 
	C210.132111,260.844421 210.132675,260.874329 210.084747,261.245789 
	C211.020248,262.747467 212.003662,263.877716 212.993546,265.003967 
	C213.000000,265.000000 212.993454,265.013245 213.084488,265.242615 
	C213.370834,265.760132 213.639130,265.938629 214.203079,266.129700 
	C214.602936,266.535553 214.780136,266.819061 214.957321,267.102600 
z"/>
<path fill="#FDF7D8" opacity="1.000000" stroke="none" 
	d="
M207.977753,266.257935 
	C208.207458,266.190491 208.492538,266.391235 208.848328,266.850342 
	C208.623749,266.914490 208.328445,266.720276 207.977753,266.257935 
z"/>
<path fill="#C89031" opacity="1.000000" stroke="none" 
	d="
M263.970856,173.649078 
	C246.416122,173.245529 229.119064,173.178818 213.122025,182.439621 
	C181.573059,200.703552 170.710938,243.688644 190.638962,274.053192 
	C190.873352,274.836639 190.893982,275.051117 190.675415,275.487854 
	C189.576950,275.823181 188.717667,275.936279 187.858398,276.049377 
	C175.770828,257.610809 173.957794,237.836273 180.397583,217.087448 
	C181.569443,213.311783 183.464050,209.760437 185.314514,205.593079 
	C185.745529,204.725861 185.887054,204.371094 186.028564,204.016327 
	C186.028564,204.016327 185.998688,204.001999 186.252335,203.840088 
	C186.712784,203.136383 186.919586,202.594589 187.126404,202.052780 
	C187.126404,202.052780 187.046402,202.024597 187.279022,201.954834 
	C187.750320,201.631989 187.873703,201.331558 187.881790,200.983795 
	C187.881790,200.983795 187.976242,200.976852 188.225174,200.799530 
	C188.690384,200.096634 188.906677,199.571075 189.122955,199.045517 
	C189.122955,199.045517 189.043686,199.031555 189.287323,198.968597 
	C189.677704,198.604080 189.824432,198.302505 189.971161,198.000946 
	C189.971176,198.000946 190.007843,198.002502 190.211594,197.906616 
	C190.684540,197.623535 190.807297,197.366440 190.783585,197.039413 
	C191.151505,196.622086 191.519440,196.204758 192.231903,195.395798 
	C192.728653,194.678894 192.880890,194.353622 193.033112,194.028351 
	C193.033112,194.028351 193.000610,193.999390 193.228638,193.903748 
	C193.715790,193.596893 193.884064,193.328690 193.961517,193.003494 
	C193.961517,193.003494 194.003433,193.011826 194.247498,192.930008 
	C194.649414,192.567062 194.807251,192.285919 194.965088,192.004791 
	C195.225922,191.756531 195.486755,191.508270 196.099304,190.979950 
	C196.711929,190.527313 196.841507,190.284561 196.839737,189.971634 
	C196.839737,189.971634 196.977463,189.977295 197.198914,189.851990 
	C197.711273,189.564575 197.881592,189.318878 197.931320,188.989548 
	C197.931320,188.989548 197.993607,189.003937 198.232361,188.915649 
	C198.780319,188.641602 198.940460,188.368439 198.951538,188.007874 
	C199.207565,187.792282 199.463577,187.576675 200.108597,187.093109 
	C200.682434,186.556549 200.867279,186.287933 201.052124,186.019318 
	C201.633408,185.642838 202.214691,185.266373 203.234665,184.560623 
	C204.082779,183.805878 204.492203,183.380402 204.901627,182.954926 
	C204.901627,182.954926 204.973892,182.978348 205.216217,182.883255 
	C205.774246,182.626617 205.979416,182.374954 206.074051,182.033173 
	C206.074051,182.033173 206.011475,182.004196 206.304047,181.972275 
	C206.866501,181.666428 207.136383,181.392517 207.406265,181.118591 
	C207.406265,181.118591 207.773148,181.060928 208.132309,180.896576 
	C208.785217,180.577087 208.960449,180.335831 209.017136,180.008438 
	C209.210724,179.960068 209.404297,179.911713 210.041656,179.721497 
	C210.753937,179.472168 210.908630,179.275177 210.949478,178.988647 
	C211.292542,178.978149 211.577728,178.844345 212.219574,178.332657 
	C213.103409,177.729919 213.572723,177.381729 214.042038,177.033554 
	C214.042038,177.033554 214.006332,176.996490 214.326416,177.007996 
	C215.174591,176.709503 215.702713,176.399506 216.230835,176.089508 
	C216.682007,175.909439 217.133194,175.729370 218.100433,175.361038 
	C219.177612,174.818817 219.738708,174.464859 220.299820,174.110901 
	C220.299820,174.110916 220.683990,174.129303 221.258865,174.036407 
	C224.897064,172.965485 227.960373,171.987457 231.023697,171.009445 
	C231.196426,170.984802 231.369156,170.960144 232.217728,170.922272 
	C243.175491,170.547791 253.457397,170.186539 263.739319,169.825287 
	C263.825043,170.988373 263.910767,172.151474 263.970856,173.649078 
z"/>
<path fill="#DCB73C" opacity="1.000000" stroke="none" 
	d="
M187.929459,276.391968 
	C188.717667,275.936279 189.576950,275.823181 190.919342,275.471863 
	C191.402451,275.233643 191.890045,275.206268 191.890045,275.206268 
	C191.890045,275.206268 191.951050,275.115082 191.973511,275.388550 
	C192.286835,275.934753 192.577682,276.207520 192.868530,276.480255 
	C193.512833,277.529266 194.157135,278.578278 195.068237,280.036987 
	C195.488297,280.709991 195.717148,280.834320 196.021637,280.819611 
	C196.797073,281.792725 197.572525,282.765839 198.708984,284.140991 
	C199.361496,284.725311 199.653000,284.907623 199.944489,285.089966 
	C199.944489,285.089966 199.987320,285.011566 200.028000,285.285889 
	C200.366150,285.732086 200.663620,285.903961 200.961090,286.075867 
	C200.961090,286.075867 200.969009,285.998627 200.964844,286.314209 
	C201.013977,287.009583 201.067307,287.389313 201.120621,287.769073 
	C200.731216,288.452240 200.341812,289.135406 199.643951,289.872681 
	C195.557190,285.529358 191.778854,281.131958 187.929459,276.391968 
z"/>
<path fill="#FEF9E0" opacity="1.000000" stroke="none" 
	d="
M263.814636,169.459610 
	C253.457397,170.186539 243.175491,170.547791 232.469147,170.849579 
	C236.524780,170.193008 240.989441,169.367004 245.489319,169.063675 
	C249.914978,168.765381 254.397766,169.242569 258.809998,168.857773 
	C260.265900,168.730789 261.580078,166.978928 262.956299,165.579956 
	C263.265656,166.491241 263.577789,167.792587 263.814636,169.459610 
z"/>
<path fill="#FEF9E0" opacity="1.000000" stroke="none" 
	d="
M230.608917,171.004715 
	C227.960373,171.987457 224.897064,172.965485 221.422729,173.935776 
	C224.072540,172.952026 227.133347,171.975998 230.608917,171.004715 
z"/>
<path fill="#FEF9E0" opacity="1.000000" stroke="none" 
	d="
M215.903442,176.077667 
	C215.702713,176.399506 215.174591,176.709503 214.332642,177.032837 
	C214.537903,176.719406 215.056976,176.392624 215.903442,176.077667 
z"/>
<path fill="#FEF9E0" opacity="1.000000" stroke="none" 
	d="
M213.727463,177.045090 
	C213.572723,177.381729 213.103409,177.729919 212.338043,178.121918 
	C212.498947,177.796005 212.955917,177.426315 213.727463,177.045090 
z"/>
<path fill="#FEF9E0" opacity="1.000000" stroke="none" 
	d="
M204.580994,183.010742 
	C204.492203,183.380402 204.082779,183.805878 203.393066,184.351227 
	C203.495316,184.002930 203.877838,183.534744 204.580994,183.010742 
z"/>
<path fill="#FEF9E0" opacity="1.000000" stroke="none" 
	d="
M219.945129,174.097107 
	C219.738708,174.464859 219.177612,174.818817 218.277161,175.189789 
	C218.488678,174.832291 219.039566,174.457794 219.945129,174.097107 
z"/>
<path fill="#FEF9E0" opacity="1.000000" stroke="none" 
	d="
M188.857971,199.171967 
	C188.906677,199.571075 188.690384,200.096634 188.216583,200.752960 
	C188.170380,200.355286 188.381683,199.826843 188.857971,199.171967 
z"/>
<path fill="#FEF9E0" opacity="1.000000" stroke="none" 
	d="
M186.852585,202.191986 
	C186.919586,202.594589 186.712784,203.136383 186.264893,203.851898 
	C186.208786,203.460800 186.393768,202.895981 186.852585,202.191986 
z"/>
<path fill="#FEF9E0" opacity="1.000000" stroke="none" 
	d="
M193.745956,193.113007 
	C193.884064,193.328690 193.715790,193.596893 193.242981,193.919357 
	C193.095810,193.698883 193.262848,193.429520 193.745956,193.113007 
z"/>
<path fill="#FEF9E0" opacity="1.000000" stroke="none" 
	d="
M210.785217,179.043182 
	C210.908630,179.275177 210.753937,179.472168 210.292358,179.558014 
	C210.232681,179.342041 210.406570,179.195816 210.785217,179.043182 
z"/>
<path fill="#FEF9E0" opacity="1.000000" stroke="none" 
	d="
M198.743073,188.107529 
	C198.940460,188.368439 198.780319,188.641602 198.231567,188.883698 
	C198.093536,188.636993 198.274399,188.392715 198.743073,188.107529 
z"/>
<path fill="#FEF9E0" opacity="1.000000" stroke="none" 
	d="
M205.843689,182.111099 
	C205.979416,182.374954 205.774246,182.626617 205.216080,182.845276 
	C205.107895,182.593796 205.321136,182.356003 205.843689,182.111099 
z"/>
<path fill="#FEF9E0" opacity="1.000000" stroke="none" 
	d="
M200.825775,186.108032 
	C200.867279,186.287933 200.682434,186.556549 200.254013,186.887283 
	C200.116653,186.628006 200.312988,186.377121 200.825775,186.108032 
z"/>
<path fill="#FEF9E0" opacity="1.000000" stroke="none" 
	d="
M207.110992,181.168549 
	C207.136383,181.392517 206.866501,181.666428 206.311188,182.005997 
	C206.289093,181.787277 206.552399,181.502899 207.110992,181.168549 
z"/>
<path fill="#FEF9E0" opacity="1.000000" stroke="none" 
	d="
M208.812225,180.083572 
	C208.960449,180.335831 208.785217,180.577087 208.264328,180.765961 
	C208.154053,180.520966 208.344086,180.307297 208.812225,180.083572 
z"/>
<path fill="#FEF9E0" opacity="1.000000" stroke="none" 
	d="
M185.778183,204.174011 
	C185.887054,204.371094 185.745529,204.725861 185.363831,205.251511 
	C185.258377,205.058823 185.393082,204.695251 185.778183,204.174011 
z"/>
<path fill="#FEF9E0" opacity="1.000000" stroke="none" 
	d="
M187.680511,201.117050 
	C187.873703,201.331558 187.750320,201.631989 187.289169,201.996017 
	C187.098007,201.770248 187.235519,201.484695 187.680511,201.117050 
z"/>
<path fill="#FEF9E0" opacity="1.000000" stroke="none" 
	d="
M189.750671,198.121948 
	C189.824432,198.302505 189.677704,198.604080 189.293640,199.008347 
	C189.214249,198.821671 189.372223,198.532303 189.750671,198.121948 
z"/>
<path fill="#FEF9E0" opacity="1.000000" stroke="none" 
	d="
M190.604904,197.148376 
	C190.807297,197.366440 190.684540,197.623535 190.207031,197.888855 
	C190.067902,197.686234 190.210419,197.449692 190.604904,197.148376 
z"/>
<path fill="#FEF9E0" opacity="1.000000" stroke="none" 
	d="
M192.789841,194.157990 
	C192.880890,194.353622 192.728653,194.678894 192.329865,195.133926 
	C192.237747,194.938324 192.392166,194.612976 192.789841,194.157990 
z"/>
<path fill="#FEF9E0" opacity="1.000000" stroke="none" 
	d="
M194.752991,192.107742 
	C194.807251,192.285919 194.649414,192.567062 194.247971,192.908646 
	C194.097183,192.655411 194.276031,192.402618 194.752991,192.107742 
z"/>
<path fill="#FEF9E0" opacity="1.000000" stroke="none" 
	d="
M196.668732,190.071655 
	C196.841507,190.284561 196.711929,190.527313 196.244446,190.751160 
	C196.127930,190.546051 196.281219,190.335800 196.668732,190.071655 
z"/>
<path fill="#FEF9E0" opacity="1.000000" stroke="none" 
	d="
M197.733017,189.083405 
	C197.881592,189.318878 197.711273,189.564575 197.196716,189.783112 
	C197.098694,189.566559 197.285919,189.345810 197.733017,189.083405 
z"/>
<path fill="#FEF9E0" opacity="1.000000" stroke="none" 
	d="
M224.142029,300.330627 
	C226.559509,300.712555 229.203659,301.371887 231.947845,302.366455 
	C229.488159,302.003815 226.928436,301.305908 224.142029,300.330627 
z"/>
<path fill="#FEF9E0" opacity="1.000000" stroke="none" 
	d="
M214.544708,293.014313 
	C214.962540,293.783844 214.971542,294.550568 214.950073,295.700836 
	C213.122604,295.244141 211.325592,294.403961 209.397583,293.271240 
	C210.889694,292.989655 212.512772,293.000610 214.544708,293.014313 
z"/>
<path fill="#FEF9E0" opacity="1.000000" stroke="none" 
	d="
M201.431091,287.824890 
	C201.067307,287.389313 201.013977,287.009583 200.938828,286.343048 
	C201.583069,286.488403 202.249130,286.920532 202.955948,287.680298 
	C202.578308,287.965546 202.159943,287.923126 201.431091,287.824890 
z"/>
<path fill="#FEF9E0" opacity="1.000000" stroke="none" 
	d="
M221.136627,299.265564 
	C221.520035,299.142181 222.073517,299.261749 222.806030,299.606201 
	C222.425613,299.723572 221.866165,299.616028 221.136627,299.265564 
z"/>
<path fill="#FEF9E0" opacity="1.000000" stroke="none" 
	d="
M215.014709,296.291199 
	C215.243088,296.062592 215.513367,296.170593 215.866165,296.571136 
	C215.993103,296.752655 215.511108,296.770691 215.511108,296.770691 
	C215.511108,296.770691 215.101013,296.516785 215.014709,296.291199 
z"/>
<path fill="#FEF9E0" opacity="1.000000" stroke="none" 
	d="
M217.026031,297.250671 
	C217.237640,297.044739 217.508850,297.158600 217.863495,297.559174 
	C217.990601,297.741974 217.513672,297.749054 217.513672,297.749054 
	C217.513672,297.749054 217.129333,297.466522 217.026031,297.250671 
z"/>
<path fill="#FDF7D8" opacity="1.000000" stroke="none" 
	d="
M214.804199,229.965012 
	C214.574814,235.161621 214.345444,240.358231 214.458740,246.259705 
	C216.972656,252.848953 218.197098,259.353546 225.004608,262.591064 
	C227.476685,264.571533 229.792709,266.264221 232.183075,268.141907 
	C232.257416,268.326965 232.588379,268.544495 232.588379,268.544495 
	C232.588379,268.544495 232.975174,268.459564 233.299255,268.619568 
	C234.749496,269.168671 235.875656,269.557739 237.291046,270.211578 
	C239.993347,270.699799 242.406387,270.923279 244.819427,271.146729 
	C244.819427,271.146729 244.867157,271.168488 245.000565,271.319397 
	C245.457443,271.419983 245.780914,271.369720 246.104385,271.319458 
	C249.139725,270.864258 252.175049,270.409058 255.558990,270.328369 
	C254.763748,271.441010 253.619904,272.179169 252.340042,273.005127 
	C252.952255,273.782043 253.535980,274.522766 254.119705,275.263489 
	C251.369995,275.847443 248.620285,276.431366 245.075043,276.985474 
	C236.485718,276.135468 229.510849,273.190765 222.911346,268.531555 
	C220.845764,266.465942 218.965820,264.659424 216.993439,262.600891 
	C216.639160,262.110718 216.377365,261.872467 216.068588,261.465515 
	C215.909149,261.081329 215.739746,260.929291 215.305328,260.543457 
	C213.027130,255.490738 210.957016,250.735336 208.886887,245.979950 
	C208.962738,242.731339 208.799591,239.454208 209.182816,236.242279 
	C209.552963,233.139877 210.486313,230.104645 211.505219,227.122971 
	C212.826859,228.125687 213.815521,229.045349 214.804199,229.965012 
z"/>
<path fill="#EBC34A" opacity="1.000000" stroke="none" 
	d="
M223.097000,268.790619 
	C229.510849,273.190765 236.485718,276.135468 244.681976,277.045532 
	C245.060211,278.080231 245.035965,279.025085 245.041611,280.323669 
	C244.000534,280.728333 242.929535,280.779266 241.177216,280.603699 
	C238.309311,280.013153 236.122742,279.649170 233.936172,279.285156 
	C233.556259,279.173187 233.176346,279.061188 232.358673,278.713684 
	C231.610153,278.392395 231.299377,278.306641 230.988617,278.220856 
	C229.326752,277.436340 227.664886,276.651825 225.671951,275.799133 
	C224.640976,275.047699 223.941086,274.364441 223.121765,272.987640 
	C223.033890,271.126282 223.065445,269.958435 223.097000,268.790619 
z"/>
<path fill="#FFFFFF" opacity="1.000000" stroke="none" 
	d="
M254.438049,275.251312 
	C253.535980,274.522766 252.952255,273.782043 252.340042,273.005127 
	C253.619904,272.179169 254.763748,271.441010 255.970337,270.364136 
	C256.033081,270.025482 256.309235,269.835022 256.758911,269.833588 
	C257.474701,269.584564 257.740814,269.337006 258.006958,269.089417 
	C258.006958,269.089417 258.305939,268.876343 258.773529,268.852234 
	C259.498840,268.568329 259.756561,268.308472 260.014282,268.048615 
	C260.014282,268.048615 260.235168,267.823334 260.702454,267.800476 
	C261.447601,267.492920 261.725464,267.208252 262.003357,266.923584 
	C262.003326,266.923553 262.192780,266.723602 262.702576,266.782379 
	C263.798737,266.212769 264.385071,265.584412 264.971375,264.956055 
	C264.971375,264.956024 264.999451,265.000458 265.219574,264.911560 
	C265.624451,264.548431 265.809204,264.274170 265.993958,263.999908 
	C265.993958,263.999908 266.000000,264.000000 266.233154,263.937317 
	C266.763763,263.710571 266.958069,263.464905 267.049286,263.137695 
	C267.049286,263.137695 267.041229,263.055023 267.459381,263.047241 
	C268.869110,261.595703 269.860687,260.151978 270.852295,258.708252 
	C270.852264,258.708252 270.930176,258.312103 271.265137,258.059448 
	C271.845306,257.604095 271.982391,257.344360 272.011322,257.027527 
	C272.011322,257.027527 271.997314,257.000854 272.327515,256.992554 
	C273.427368,256.647827 274.197021,256.311401 274.966675,255.974960 
	C274.966675,255.974960 275.001740,256.004761 275.017395,256.438751 
	C275.033417,258.276062 275.033783,259.679352 275.034149,261.082672 
	C275.008057,261.396790 274.879822,261.661438 274.156586,262.069489 
	C273.487610,262.615631 273.311462,262.968872 273.135315,263.322144 
	C273.135315,263.322144 273.048096,263.692230 272.730682,263.939484 
	C272.173004,264.379181 272.024323,264.626648 271.967194,264.929108 
	C271.967194,264.929108 271.987732,264.996613 271.738037,265.070038 
	C271.213837,265.329102 271.042694,265.584137 270.974915,265.908569 
	C270.761017,266.187225 270.547150,266.465881 269.895386,266.927185 
	C269.215057,267.266479 269.055786,267.484070 268.979706,267.762543 
	C268.979706,267.762543 268.744843,268.047546 268.269592,268.133484 
	C267.549988,268.516937 267.305573,268.814484 267.061157,269.112061 
	C267.061188,269.112061 267.036255,269.007416 266.688385,269.015900 
	C263.605347,270.741547 260.870178,272.458679 258.135010,274.175842 
	C258.135010,274.175842 258.062408,274.179657 257.785583,274.113678 
	C257.003662,274.345520 256.498566,274.643341 255.993484,274.941162 
	C255.581116,275.040466 255.168747,275.139801 254.438049,275.251312 
z"/>
<path fill="#FDF7D8" opacity="1.000000" stroke="none" 
	d="
M279.454559,253.814514 
	C279.471222,251.635742 279.819977,249.198868 279.996307,246.749557 
	C280.529449,239.343643 280.995697,231.932892 281.816223,224.251343 
	C282.249268,233.911591 283.576416,243.945221 279.454559,253.814514 
z"/>
<path fill="#FDF7D8" opacity="1.000000" stroke="none" 
	d="
M262.563538,276.949768 
	C261.685791,277.926941 260.578552,279.489960 259.159393,279.861542 
	C255.977875,280.694580 252.641251,280.935181 249.143341,281.113281 
	C253.343414,279.537994 257.767944,278.266449 262.563538,276.949768 
z"/>
<path fill="#FDF7D8" opacity="1.000000" stroke="none" 
	d="
M258.498688,274.173523 
	C260.870178,272.458679 263.605347,270.741547 266.721161,269.058533 
	C264.355347,270.785553 261.608856,272.478394 258.498688,274.173523 
z"/>
<path fill="#FDF7D8" opacity="1.000000" stroke="none" 
	d="
M276.618958,266.054138 
	C276.997070,269.538696 275.063171,271.401794 271.266388,271.934387 
	C272.698212,269.994446 274.508392,268.090637 276.618958,266.054138 
z"/>
<path fill="#FDF7D8" opacity="1.000000" stroke="none" 
	d="
M275.334534,260.933380 
	C275.033783,259.679352 275.033417,258.276062 275.034424,256.454224 
	C275.837677,256.043182 276.639557,256.050659 277.842682,256.092072 
	C277.374268,257.678711 276.504608,259.231415 275.334534,260.933380 
z"/>
<path fill="#FDF7D8" opacity="1.000000" stroke="none" 
	d="
M269.974518,275.580627 
	C268.574951,275.984863 267.173065,275.992889 265.349915,275.970245 
	C266.347321,274.943298 267.765991,273.947052 269.504089,272.878418 
	C269.873077,273.598816 269.922638,274.391602 269.974518,275.580627 
z"/>
<path fill="#FDF7D8" opacity="1.000000" stroke="none" 
	d="
M279.641724,262.066101 
	C279.475220,262.900330 279.033051,263.888092 278.227600,264.883728 
	C278.364929,264.000946 278.865479,263.110260 279.641724,262.066101 
z"/>
<path fill="#FDF7D8" opacity="1.000000" stroke="none" 
	d="
M282.584106,256.900604 
	C282.497375,257.780670 282.126770,258.832642 281.398987,259.954590 
	C281.461273,259.040527 281.880737,258.056519 282.584106,256.900604 
z"/>
<path fill="#FDF7D8" opacity="1.000000" stroke="none" 
	d="
M264.660370,275.919128 
	C264.492310,276.264618 264.018280,276.612427 263.230194,276.942596 
	C263.395538,276.590454 263.874969,276.255920 264.660370,275.919128 
z"/>
<path fill="#FDF7D8" opacity="1.000000" stroke="none" 
	d="
M256.298279,274.986633 
	C256.498566,274.643341 257.003662,274.345520 257.816589,274.095245 
	C257.617310,274.439209 257.110199,274.735657 256.298279,274.986633 
z"/>
<path fill="#FDF7D8" opacity="1.000000" stroke="none" 
	d="
M280.780945,260.202454 
	C280.835327,260.594177 280.638733,261.143463 280.168701,261.822449 
	C280.106812,261.421448 280.318420,260.890747 280.780945,260.202454 
z"/>
<path fill="#FDF7D8" opacity="1.000000" stroke="none" 
	d="
M270.661499,271.932526 
	C270.734558,272.168457 270.541901,272.461090 270.082245,272.808472 
	C270.008728,272.571899 270.202240,272.280579 270.661499,271.932526 
z"/>
<path fill="#FDF7D8" opacity="1.000000" stroke="none" 
	d="
M272.204956,264.879547 
	C272.024323,264.626648 272.173004,264.379181 272.608154,264.084808 
	C272.872833,264.346069 272.752747,264.628418 272.204956,264.879547 
z"/>
<path fill="#FDF7D8" opacity="1.000000" stroke="none" 
	d="
M273.397003,263.194244 
	C273.311462,262.968872 273.487610,262.615631 273.960510,262.179138 
	C274.057739,262.419373 273.858215,262.742859 273.397003,263.194244 
z"/>
<path fill="#FDF7D8" opacity="1.000000" stroke="none" 
	d="
M267.343933,269.054840 
	C267.305573,268.814484 267.549988,268.516937 268.094849,268.193115 
	C268.139069,268.443726 267.882874,268.720673 267.343933,269.054840 
z"/>
<path fill="#FDF7D8" opacity="1.000000" stroke="none" 
	d="
M269.201111,267.733337 
	C269.055786,267.484070 269.215057,267.266479 269.648590,267.032959 
	C269.848724,267.288116 269.709686,267.537445 269.201111,267.733337 
z"/>
<path fill="#FDF7D8" opacity="1.000000" stroke="none" 
	d="
M277.650085,264.947144 
	C277.746521,265.175049 277.580261,265.474457 277.162537,265.858459 
	C277.069824,265.634888 277.228638,265.326782 277.650085,264.947144 
z"/>
<path fill="#FDF7D8" opacity="1.000000" stroke="none" 
	d="
M271.214050,265.852112 
	C271.042694,265.584137 271.213837,265.329102 271.706116,265.054993 
	C271.905548,265.321594 271.748627,265.597992 271.214050,265.852112 
z"/>
<path fill="#FDF7D8" opacity="1.000000" stroke="none" 
	d="
M274.970093,255.564362 
	C274.197021,256.311401 273.427368,256.647827 272.340942,256.998779 
	C272.786194,255.421387 273.548187,253.829468 274.581055,252.049561 
	C274.892456,252.958984 274.932983,254.056366 274.970093,255.564362 
z"/>
<path fill="#EBC452" opacity="1.000000" stroke="none" 
	d="
M244.846252,270.771271 
	C242.406387,270.923279 239.993347,270.699799 237.297791,269.802979 
	C237.068314,268.309540 237.121338,267.489441 237.174377,266.669312 
	C240.810944,266.882904 244.447495,267.096497 248.526947,267.416046 
	C247.604248,268.479919 246.238647,269.437866 244.846252,270.771271 
z"/>
<path fill="#FDF7D8" opacity="1.000000" stroke="none" 
	d="
M272.369446,245.863617 
	C272.202667,234.666473 272.301483,223.129059 272.693146,211.294281 
	C273.083221,212.722427 273.180481,214.447937 273.383270,216.593018 
	C273.204224,226.516190 272.919647,236.019760 272.369446,245.863617 
z"/>
<path fill="#FDF7D8" opacity="1.000000" stroke="none" 
	d="
M270.569916,258.873840 
	C269.860687,260.151978 268.869110,261.595703 267.500366,263.053925 
	C268.177979,261.725433 269.232758,260.382446 270.569916,258.873840 
z"/>
<path fill="#FDF7D8" opacity="1.000000" stroke="none" 
	d="
M270.410278,251.078064 
	C270.489594,249.919464 270.833099,248.535294 271.539856,247.065109 
	C271.493561,248.270233 271.084015,249.561356 270.410278,251.078064 
z"/>
<path fill="#FDF7D8" opacity="1.000000" stroke="none" 
	d="
M267.428467,256.143097 
	C267.569061,255.265045 267.988098,254.206924 268.789490,253.137863 
	C268.683502,254.072296 268.195221,255.017654 267.428467,256.143097 
z"/>
<path fill="#FDF7D8" opacity="1.000000" stroke="none" 
	d="
M264.649902,264.994812 
	C264.385071,265.584412 263.798737,266.212769 262.840302,266.783447 
	C263.088257,266.161713 263.708313,265.597656 264.649902,264.994812 
z"/>
<path fill="#EBC452" opacity="1.000000" stroke="none" 
	d="
M245.814972,271.226746 
	C245.780914,271.369720 245.457443,271.419983 245.026581,271.322723 
	C245.121323,271.161438 245.323441,271.147736 245.814972,271.226746 
z"/>
<path fill="#FDF7D8" opacity="1.000000" stroke="none" 
	d="
M265.732880,264.040283 
	C265.809204,264.274170 265.624451,264.548431 265.196655,264.898560 
	C265.126343,264.676514 265.299042,264.378601 265.732880,264.040283 
z"/>
<path fill="#FDF7D8" opacity="1.000000" stroke="none" 
	d="
M266.806152,263.182556 
	C266.958069,263.464905 266.763763,263.710571 266.233826,263.934662 
	C266.043243,263.632477 266.230469,263.376740 266.806152,263.182556 
z"/>
<path fill="#FDF7D8" opacity="1.000000" stroke="none" 
	d="
M271.780823,257.097778 
	C271.982391,257.344360 271.845306,257.604095 271.400452,257.909637 
	C271.130188,257.653564 271.246704,257.372101 271.780823,257.097778 
z"/>
<path fill="#FDF7D8" opacity="1.000000" stroke="none" 
	d="
M269.355042,253.044830 
	C269.071838,252.792389 269.178253,252.488052 269.654785,252.112549 
	C269.776337,252.304276 269.682953,252.621475 269.355042,253.044830 
z"/>
<path fill="#FDF7D8" opacity="1.000000" stroke="none" 
	d="
M257.724121,269.093842 
	C257.740814,269.337006 257.474701,269.584564 256.926392,269.842102 
	C256.909943,269.600769 257.175629,269.349518 257.724121,269.093842 
z"/>
<path fill="#FDF7D8" opacity="1.000000" stroke="none" 
	d="
M259.734222,268.066833 
	C259.756561,268.308472 259.498840,268.568329 258.956818,268.842041 
	C258.933044,268.598938 259.193604,268.342010 259.734222,268.066833 
z"/>
<path fill="#FDF7D8" opacity="1.000000" stroke="none" 
	d="
M261.702606,266.954773 
	C261.725464,267.208252 261.447601,267.492920 260.859924,267.791748 
	C260.834045,267.532593 261.117950,267.259308 261.702606,266.954773 
z"/>
<path fill="#FDF7D8" opacity="1.000000" stroke="none" 
	d="
M273.065735,208.673828 
	C272.825745,208.433197 272.674622,207.861359 272.746338,207.142761 
	C273.031006,207.444870 273.092804,207.893768 273.065735,208.673828 
z"/>
<path fill="#D7A026" opacity="1.000000" stroke="none" 
	d="
M311.044434,185.018860 
	C311.044434,177.225647 311.044434,169.432419 311.044434,161.639206 
	C311.670776,161.641510 312.297119,161.643829 312.923462,161.646149 
	C312.923462,169.130630 312.923462,176.615128 312.587036,184.561554 
	C311.848541,185.021973 311.446472,185.020416 311.044434,185.018860 
z"/>
<path fill="#C78E2A" opacity="1.000000" stroke="none" 
	d="
M311.028748,185.483368 
	C311.446472,185.020416 311.848541,185.021973 312.549988,185.031158 
	C312.849365,189.820969 312.849365,194.603149 312.849365,199.385315 
	C312.237274,199.382812 311.625153,199.380310 311.013062,199.377808 
	C311.013062,194.901169 311.013062,190.424530 311.028748,185.483368 
z"/>
<path fill="#FDF7D8" opacity="1.000000" stroke="none" 
	d="
M366.664490,374.998627 
	C363.050903,377.943268 361.096161,377.434662 357.249817,371.993713 
	C357.114014,371.293060 357.151367,371.122894 357.188690,370.952728 
	C360.213715,372.289917 363.238708,373.627106 366.664490,374.998627 
z"/>
<path fill="#DDB657" opacity="1.000000" stroke="none" 
	d="
M357.004364,370.757019 
	C357.151367,371.122894 357.114014,371.293060 357.039001,371.721680 
	C355.582031,371.974792 354.162720,371.969513 352.315369,371.949219 
	C351.767639,371.744934 351.647949,371.555634 351.780579,370.882996 
	C353.352386,370.226715 354.671875,370.053772 355.991394,369.880859 
	C356.267609,370.107666 356.543823,370.334473 357.004364,370.757019 
z"/>
<path fill="#FDF7D8" opacity="1.000000" stroke="none" 
	d="
M355.762482,369.636780 
	C354.671875,370.053772 353.352386,370.226715 351.661804,370.568726 
	C352.705017,370.289459 354.119293,369.841095 355.762482,369.636780 
z"/>
<path fill="#FDF7D8" opacity="1.000000" stroke="none" 
	d="
M352.173889,361.914551 
	C352.392029,361.912109 352.616638,362.184143 352.857483,362.725281 
	C352.642609,362.725983 352.411469,362.457520 352.173889,361.914551 
z"/>
<path fill="#FDF7D8" opacity="1.000000" stroke="none" 
	d="
M352.069336,374.129608 
	C352.366272,373.995300 352.584259,374.200348 352.694336,374.755493 
	C352.412994,374.855743 352.214325,374.643555 352.069336,374.129608 
z"/>
<path fill="#FDF7D8" opacity="1.000000" stroke="none" 
	d="
M351.384308,372.147827 
	C351.572083,372.155945 351.681671,372.304138 351.785553,372.728760 
	C351.423737,372.885773 351.265808,372.646729 351.384308,372.147827 
z"/>
<path fill="#FDFFFD" opacity="1.000000" stroke="none" 
	d="
M282.388123,289.008606 
	C282.333038,288.258392 282.669006,287.514801 283.093201,286.480804 
	C284.106781,286.855865 285.032166,287.521301 285.922943,288.537354 
	C284.851959,288.930389 283.815552,288.972809 282.388123,289.008606 
z"/>
<path fill="#FDF7D8" opacity="1.000000" stroke="none" 
	d="
M234.041077,279.613342 
	C236.122742,279.649170 238.309311,280.013153 240.772461,280.614197 
	C238.748032,280.548004 236.447006,280.244751 234.041077,279.613342 
z"/>
<path fill="#FDF7D8" opacity="1.000000" stroke="none" 
	d="
M231.133194,278.420197 
	C231.299377,278.306641 231.610153,278.392395 232.062683,278.685425 
	C231.854111,278.941956 231.545212,278.850922 231.133194,278.420197 
z"/>
<path fill="#FDF7D8" opacity="1.000000" stroke="none" 
	d="
M261.352173,256.953918 
	C260.844543,257.007874 260.675629,257.005005 260.253296,257.001068 
	C262.133057,253.783508 264.266205,250.567017 266.656769,247.133224 
	C267.563293,251.493820 264.565857,254.163437 261.352173,256.953918 
z"/>
<path fill="#EBC452" opacity="1.000000" stroke="none" 
	d="
M244.804886,261.988403 
	C243.346786,262.096191 241.919800,261.848511 240.323608,261.100952 
	C239.751617,259.493408 239.348831,258.385742 238.946045,257.278076 
	C241.025803,257.568787 243.105545,257.859497 245.563477,258.281799 
	C245.573090,259.486542 245.204544,260.559723 244.804886,261.988403 
z"/>
<path fill="#FDF7D8" opacity="1.000000" stroke="none" 
	d="
M267.433533,244.137787 
	C267.620270,244.885086 267.603119,245.882507 267.235962,246.901703 
	C267.000549,246.078278 267.115112,245.233093 267.433533,244.137787 
z"/>
<path fill="#DDB657" opacity="1.000000" stroke="none" 
	d="
M355.483704,377.905396 
	C354.979492,377.544525 354.755188,377.319977 354.238525,377.075745 
	C354.014191,376.988098 353.959595,376.513550 353.947815,376.275116 
	C355.179413,376.587158 356.422821,377.137634 357.795959,378.016785 
	C357.227661,378.221527 356.529663,378.097626 355.483704,377.905396 
z"/>
<path fill="#FEF9E0" opacity="1.000000" stroke="none" 
	d="
M191.821625,274.932495 
	C191.890045,275.206268 191.402451,275.233643 191.158539,275.249634 
	C190.893982,275.051117 190.873352,274.836639 190.869324,274.299072 
	C191.175018,274.203583 191.464111,274.431183 191.821625,274.932495 
z"/>
<path fill="#FEF9E0" opacity="1.000000" stroke="none" 
	d="
M199.852173,284.844604 
	C199.653000,284.907623 199.361496,284.725311 198.986359,284.290161 
	C199.188431,284.224640 199.474152,284.411926 199.852173,284.844604 
z"/>
<path fill="#FEF9E0" opacity="1.000000" stroke="none" 
	d="
M195.931427,280.657959 
	C195.717148,280.834320 195.488297,280.709991 195.301285,280.246277 
	C195.502350,280.140503 195.693588,280.290649 195.931427,280.657959 
z"/>
<path fill="#FEF9E0" opacity="1.000000" stroke="none" 
	d="
M200.864258,285.835632 
	C200.663620,285.903961 200.366150,285.732086 199.989319,285.308228 
	C200.195770,285.235962 200.481583,285.415680 200.864258,285.835632 
z"/>
<path fill="#FEF9E0" opacity="1.000000" stroke="none" 
	d="
M192.820435,276.169342 
	C192.577682,276.207520 192.286835,275.934753 191.922058,275.369507 
	C192.156204,275.337524 192.464264,275.597961 192.820435,276.169342 
z"/>
<path fill="#FDF7D8" opacity="1.000000" stroke="none" 
	d="
M243.853668,198.904922 
	C234.736938,198.354279 226.980927,201.841125 219.442688,207.066650 
	C219.983978,201.908371 224.848679,201.628693 228.373123,200.312271 
	C233.237411,198.495438 238.512970,197.779755 243.868927,197.193832 
	C244.034866,198.165146 243.944260,198.535034 243.853668,198.904922 
z"/>
<path fill="#FDF7D8" opacity="1.000000" stroke="none" 
	d="
M216.623932,208.994400 
	C215.295654,211.063690 213.592285,213.128540 211.607147,215.390320 
	C210.586319,211.611801 212.819351,209.854431 216.623932,208.994400 
z"/>
<path fill="#D9A745" opacity="1.000000" stroke="none" 
	d="
M244.188110,198.965851 
	C243.944260,198.535034 244.034866,198.165146 244.106934,197.508514 
	C244.861877,197.246201 245.635345,197.270660 246.761749,197.458557 
	C246.250641,198.090256 245.386612,198.558517 244.188110,198.965851 
z"/>
<path fill="#FDF7D8" opacity="1.000000" stroke="none" 
	d="
M207.867432,221.231277 
	C207.996338,221.685394 207.816757,222.287231 207.392120,223.096771 
	C207.284363,222.662628 207.421661,222.020798 207.867432,221.231277 
z"/>
<path fill="#FDF7D8" opacity="1.000000" stroke="none" 
	d="
M209.642059,218.118469 
	C209.749222,218.300003 209.638763,218.609756 209.299805,219.043243 
	C209.189026,218.860214 209.306732,218.553436 209.642059,218.118469 
z"/>
<path fill="#FDF7D8" opacity="1.000000" stroke="none" 
	d="
M210.917328,216.132309 
	C211.032715,216.363052 210.865723,216.717804 210.425690,217.209244 
	C210.313400,216.982727 210.474167,216.619522 210.917328,216.132309 
z"/>
<path fill="#FDF7D8" opacity="1.000000" stroke="none" 
	d="
M217.830017,208.117157 
	C217.890442,208.327988 217.701904,208.603928 217.256165,208.939392 
	C217.192978,208.726685 217.387024,208.454483 217.830017,208.117157 
z"/>
<path fill="#FDF7D8" opacity="1.000000" stroke="none" 
	d="
M218.857040,207.140228 
	C218.935089,207.371109 218.753601,207.665512 218.311234,208.020126 
	C218.232742,207.788132 218.415115,207.495941 218.857040,207.140228 
z"/>
<path fill="#FDF7D8" opacity="1.000000" stroke="none" 
	d="
M208.594391,220.122375 
	C208.725281,220.306213 208.657532,220.625076 208.358063,221.047150 
	C208.066223,220.807465 208.156021,220.509811 208.594391,220.122375 
z"/>
<path fill="#D6A736" opacity="1.000000" stroke="none" 
	d="
M208.545532,246.070862 
	C210.957016,250.735336 213.027130,255.490738 214.900421,260.608093 
	C213.706299,261.352753 212.709015,261.735443 211.482849,262.000702 
	C210.880188,261.546936 210.506424,261.210632 210.132675,260.874329 
	C210.132675,260.874329 210.132111,260.844421 210.147827,260.834747 
	C209.466217,259.104431 208.768906,257.383820 208.300812,255.119873 
	C208.160431,254.069183 207.790833,253.561813 207.421234,253.054443 
	C207.183014,252.609116 206.944809,252.163803 206.997849,251.298004 
	C207.112259,250.281906 206.935425,249.686279 206.758591,249.090668 
	C206.758591,249.090668 206.720886,249.155975 206.685516,249.166656 
	C207.168167,248.172150 207.686188,247.166962 208.545532,246.070862 
z"/>
<path fill="#C78920" opacity="1.000000" stroke="none" 
	d="
M207.482330,253.387268 
	C207.790833,253.561813 208.160431,254.069183 208.302826,254.767456 
	C207.898209,254.545609 207.720825,254.132858 207.482330,253.387268 
z"/>
<path fill="#C78920" opacity="1.000000" stroke="none" 
	d="
M206.562897,249.415253 
	C206.935425,249.686279 207.112259,250.281906 207.009018,251.039490 
	C206.608368,250.714249 206.487778,250.227051 206.562897,249.415253 
z"/>
<path fill="#E1B530" opacity="1.000000" stroke="none" 
	d="
M238.606201,257.139862 
	C239.348831,258.385742 239.751617,259.493408 240.048874,260.868103 
	C239.500366,261.068634 239.057373,261.002136 238.139374,260.629059 
	C237.373505,260.130981 237.059006,260.077209 236.720871,260.161102 
	C233.765717,257.765625 230.810577,255.370178 227.848999,252.633194 
	C227.951324,251.621719 228.060089,250.951752 228.328918,249.791077 
	C228.488983,249.300369 228.851608,249.500137 229.034637,249.902466 
	C229.618988,250.506149 230.020325,250.707520 230.421646,250.908905 
	C233.036545,252.939819 235.651443,254.970734 238.606201,257.139862 
z"/>
<path fill="#CB9632" opacity="1.000000" stroke="none" 
	d="
M230.317017,250.711945 
	C230.020325,250.707520 229.618988,250.506149 229.086227,250.102936 
	C229.373978,250.105713 229.793182,250.310349 230.317017,250.711945 
z"/>
<path fill="#D9A745" opacity="1.000000" stroke="none" 
	d="
M242.258759,219.848175 
	C242.178574,219.424927 242.434052,219.317978 242.877945,219.601257 
	C242.818130,219.789963 242.660812,219.847626 242.258759,219.848175 
z"/>
<path fill="#D6A736" opacity="1.000000" stroke="none" 
	d="
M224.848572,262.303314 
	C218.197098,259.353546 216.972656,252.848953 214.723572,246.599548 
	C215.446152,245.532578 216.246552,244.830643 217.358215,244.050171 
	C220.065109,249.751663 220.469162,256.613281 226.392944,260.726440 
	C225.754242,261.485565 225.301407,261.894440 224.848572,262.303314 
z"/>
<path fill="#E1B530" opacity="1.000000" stroke="none" 
	d="
M225.004608,262.591064 
	C225.301407,261.894440 225.754242,261.485565 226.520630,261.026306 
	C230.082428,262.745026 233.330673,264.514130 236.876648,266.476257 
	C237.121338,267.489441 237.068314,268.309540 237.008545,269.538269 
	C235.875656,269.557739 234.749496,269.168671 233.181808,268.498138 
	C232.553604,268.072235 232.343094,267.985626 232.108734,267.956879 
	C229.792709,266.264221 227.476685,264.571533 225.004608,262.591064 
z"/>
<path fill="#D9A745" opacity="1.000000" stroke="none" 
	d="
M249.538391,210.552155 
	C251.494461,210.297394 253.859329,210.187546 256.538391,210.234863 
	C254.550781,210.493698 252.248978,210.595383 249.538391,210.552155 
z"/>
<path fill="#D6A736" opacity="1.000000" stroke="none" 
	d="
M217.409592,240.478897 
	C217.192032,240.062943 217.126678,239.344528 217.157730,238.428665 
	C217.356689,238.879623 217.459244,239.528030 217.409592,240.478897 
z"/>
<path fill="#EBC452" opacity="1.000000" stroke="none" 
	d="
M236.896423,260.330933 
	C237.059006,260.077209 237.373505,260.130981 237.827209,260.501587 
	C237.990036,260.680725 237.505722,260.720032 237.505722,260.720032 
	C237.505722,260.720032 237.071976,260.500793 236.896423,260.330933 
z"/>
<path fill="#FEFDF6" opacity="1.000000" stroke="none" 
	d="
M214.915939,229.758011 
	C213.815521,229.045349 212.826859,228.125687 211.723083,226.800598 
	C211.943024,225.432037 212.278061,224.468918 213.042145,223.133026 
	C213.595383,222.440155 213.719559,222.120056 213.843719,221.799973 
	C213.843719,221.799973 213.868134,221.901550 214.180954,221.787262 
	C214.900818,220.586548 215.307861,219.500107 215.714905,218.413666 
	C215.714905,218.413666 215.683060,218.168945 216.035461,217.983734 
	C216.561859,217.483932 216.735855,217.169312 216.909851,216.854691 
	C216.909836,216.854691 216.938126,216.959854 217.252045,216.914856 
	C218.046387,215.919128 218.526810,214.968399 219.007233,214.017670 
	C219.007248,214.017670 218.997986,213.999786 219.240707,213.898270 
	C219.647034,213.508011 219.810623,213.219269 219.974213,212.930527 
	C220.034058,212.586044 220.214462,212.324875 220.747772,212.071609 
	C223.057907,212.867538 224.779526,207.566498 227.130371,212.015549 
	C227.218262,212.384705 227.052917,212.737289 226.614746,212.874039 
	C225.801010,213.377167 225.425461,213.743546 225.049896,214.109924 
	C225.049896,214.109924 225.022202,214.032516 224.808609,214.128540 
	C224.408035,214.516693 224.221039,214.808838 224.034058,215.100983 
	C223.933517,215.412704 223.744324,215.655289 223.013092,216.004974 
	C222.371811,216.451126 222.183914,216.721054 221.996017,216.990982 
	C221.996017,216.990982 222.000000,217.000610 221.753784,217.111084 
	C221.357452,217.533234 221.207336,217.844940 221.057205,218.156631 
	C220.509811,218.977859 219.962433,219.799088 218.955490,220.925201 
	C218.366638,221.536713 218.237350,221.843353 218.108063,222.149994 
	C218.108063,222.149994 218.063263,222.064972 217.828918,222.197754 
	C217.459335,222.704041 217.324081,223.077545 217.188828,223.451035 
	C217.188828,223.451035 217.195679,223.759552 216.857758,224.034485 
	C216.396545,224.661041 216.273285,225.012650 216.150024,225.364273 
	C216.150024,225.364273 216.148529,225.706848 215.788376,226.064240 
	C215.297989,227.307968 215.167755,228.194321 215.037521,229.080658 
	C215.037521,229.080658 215.027679,229.551010 214.915939,229.758011 
z"/>
<path fill="#FDF7D8" opacity="1.000000" stroke="none" 
	d="
M215.423813,218.601715 
	C215.307861,219.500107 214.900818,220.586548 214.130432,221.773956 
	C214.222305,220.846527 214.677521,219.818146 215.423813,218.601715 
z"/>
<path fill="#FDF7D8" opacity="1.000000" stroke="none" 
	d="
M218.723755,214.152069 
	C218.526810,214.968399 218.046387,215.919128 217.208893,216.881683 
	C217.381302,216.024475 217.910797,215.155472 218.723755,214.152069 
z"/>
<path fill="#FDF7D8" opacity="1.000000" stroke="none" 
	d="
M213.596924,221.909546 
	C213.719559,222.120056 213.595383,222.440155 213.234558,222.882080 
	C213.115326,222.675659 213.232742,222.347382 213.596924,221.909546 
z"/>
<path fill="#FDF7D8" opacity="1.000000" stroke="none" 
	d="
M219.720673,213.008774 
	C219.810623,213.219269 219.647034,213.508011 219.250458,213.899933 
	C219.167358,213.697754 219.317245,213.392395 219.720673,213.008774 
z"/>
<path fill="#FDF7D8" opacity="1.000000" stroke="none" 
	d="
M216.641327,216.950195 
	C216.735855,217.169312 216.561859,217.483932 216.131332,217.906067 
	C216.040802,217.690964 216.206802,217.368332 216.641327,216.950195 
z"/>
<path fill="#FDF7D8" opacity="1.000000" stroke="none" 
	d="
M215.273499,228.840942 
	C215.167755,228.194321 215.297989,227.307968 215.692688,226.206329 
	C215.807938,226.861084 215.658707,227.731171 215.273499,228.840942 
z"/>
<path fill="#FDF7D8" opacity="1.000000" stroke="none" 
	d="
M225.324188,214.070129 
	C225.425461,213.743546 225.801010,213.377167 226.452835,212.982208 
	C226.352219,213.312500 225.975327,213.671402 225.324188,214.070129 
z"/>
<path fill="#FDF7D8" opacity="1.000000" stroke="none" 
	d="
M217.442657,223.295746 
	C217.324081,223.077545 217.459335,222.704041 217.876343,222.205444 
	C218.004257,222.433731 217.850372,222.787079 217.442657,223.295746 
z"/>
<path fill="#FDF7D8" opacity="1.000000" stroke="none" 
	d="
M218.345581,222.041458 
	C218.237350,221.843353 218.366638,221.536713 218.721191,221.107208 
	C218.825333,221.300537 218.704193,221.616730 218.345581,222.041458 
z"/>
<path fill="#FDF7D8" opacity="1.000000" stroke="none" 
	d="
M216.399414,225.216171 
	C216.273285,225.012650 216.396545,224.661041 216.760712,224.154434 
	C216.883987,224.355652 216.766403,224.711868 216.399414,225.216171 
z"/>
<path fill="#FDF7D8" opacity="1.000000" stroke="none" 
	d="
M221.293701,218.040909 
	C221.207336,217.844940 221.357452,217.533234 221.748993,217.108795 
	C221.837006,217.305756 221.683594,217.615463 221.293701,218.040909 
z"/>
<path fill="#FDF7D8" opacity="1.000000" stroke="none" 
	d="
M222.250763,216.928955 
	C222.183914,216.721054 222.371811,216.451126 222.796234,216.094391 
	C222.857025,216.294022 222.681274,216.580475 222.250763,216.928955 
z"/>
<path fill="#FDF7D8" opacity="1.000000" stroke="none" 
	d="
M224.296173,215.030518 
	C224.221039,214.808838 224.408035,214.516693 224.848328,214.139160 
	C224.920517,214.355850 224.739410,214.657944 224.296173,215.030518 
z"/>
<path fill="#E1B530" opacity="1.000000" stroke="none" 
	d="
M211.711746,262.118164 
	C212.709015,261.735443 213.706299,261.352753 215.108490,260.905396 
	C215.739746,260.929291 215.909149,261.081329 216.043396,261.739624 
	C216.405411,262.405914 216.745651,262.629395 217.085892,262.852875 
	C218.965820,264.659424 220.845764,266.465942 222.911346,268.531555 
	C223.065445,269.958435 223.033890,271.126282 222.977158,272.731354 
	C222.557556,273.076965 222.163147,272.985352 221.466263,272.875214 
	C221.163803,272.856720 221.137558,272.876190 221.049255,272.632599 
	C220.654099,272.228119 220.347244,272.067261 220.040390,271.906372 
	C219.331268,271.257385 218.622147,270.608429 217.641922,269.861450 
	C216.761734,268.944366 216.152634,268.125336 215.250427,267.204437 
	C214.780136,266.819061 214.602936,266.535553 214.142456,265.880188 
	C213.570587,265.343292 213.282028,265.178284 212.993454,265.013245 
	C212.993454,265.013245 213.000000,265.000000 212.953461,264.686737 
	C212.508530,263.621704 212.110138,262.869934 211.711746,262.118164 
z"/>
<path fill="#FDF7D8" opacity="1.000000" stroke="none" 
	d="
M220.126648,272.159515 
	C220.347244,272.067261 220.654099,272.228119 221.043564,272.645721 
	C220.821747,272.739166 220.517334,272.575928 220.126648,272.159515 
z"/>
<path fill="#FDF7D8" opacity="1.000000" stroke="none" 
	d="
M211.482849,262.000702 
	C212.110138,262.869934 212.508530,263.621704 212.947006,264.690735 
	C212.003662,263.877716 211.020248,262.747467 210.084747,261.245789 
	C210.506424,261.210632 210.880188,261.546936 211.482849,262.000702 
z"/>
<path fill="#FDF7D8" opacity="1.000000" stroke="none" 
	d="
M213.084488,265.242615 
	C213.282028,265.178284 213.570587,265.343292 213.919785,265.757874 
	C213.639130,265.938629 213.370834,265.760132 213.084488,265.242615 
z"/>
<path fill="#D6A736" opacity="1.000000" stroke="none" 
	d="
M216.993439,262.600891 
	C216.745651,262.629395 216.405411,262.405914 216.090363,261.908325 
	C216.377365,261.872467 216.639160,262.110718 216.993439,262.600891 
z"/>
<path fill="#EBC452" opacity="1.000000" stroke="none" 
	d="
M232.183075,268.141907 
	C232.343094,267.985626 232.553604,268.072235 232.857727,268.338135 
	C232.975174,268.459564 232.588379,268.544495 232.588379,268.544495 
	C232.588379,268.544495 232.257416,268.326965 232.183075,268.141907 
z"/>
</g>
</svg>



        
    </Box>
  );

  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="">{logo}</RouterLink>;
}